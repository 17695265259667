import React, { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { SettingsContext } from "core/context/SettingsContext";

type SliderInputProps = {
  onSetProgress: (progress: number) => void;
};

// OP2-78 introduced stepSize and set value on input slider, this allows 50% to be initially clicked

export const SliderInput: React.FC<SliderInputProps> = (props) => {
  // Based on the current project, read the settings and get the configured allocation chunk size
  const { projectId } = useParams();
  const settingsCtx = useContext(SettingsContext);

  const settings = useMemo(() => {
    return settingsCtx?.getSettingsById?.(+projectId!);
  }, [settingsCtx, projectId]);

  const initializeData = useCallback(() => {
    // Assume 10% chunk size...
    let startProgress = 10;
    let configIntervals = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    let stepSize = 10;

    // Modify based on Allocation Percentage Chunk Size
    switch (settings?.AllocationPercentageChunkSize?.toFixed()) {
      // case "5":
      //   startProgress = 5;
      //   configIntervals = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
      //   break;
      case "20":
        startProgress = 20;
        configIntervals = [20, 40, 60, 80, 100];
        stepSize = 10;
        break;
      case "25":
        startProgress = 25;
        configIntervals = [25, 50, 75, 100];
        stepSize = 25;
        break;
      case "50":
        startProgress = 50;
        configIntervals = [50, 100];
        stepSize = 50;
        break;
      case "100":
        startProgress = 100;
        configIntervals = [100];
        stepSize = 100;
        break;
      default:
        startProgress = 10;
        configIntervals = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        stepSize = 10;
        break;
    }

    return {
      startProgress,
      configIntervals,
      stepSize,
    };
  }, [settings?.AllocationPercentageChunkSize]);

  const { startProgress, stepSize, configIntervals } = useMemo(
    () => initializeData(),
    [initializeData]
  );

  const [progress, setProgress] = useState(startProgress);

  const { onSetProgress } = props;

  return (
    <div className="slider-input flex-fill">
      <div className="range-slider" id="percentage-slider-container">
        <div className="intervals-container">
          {configIntervals?.map((interval) => (
            <span
              key={interval}
              className="interval"
              onClick={() => onSetProgress(interval)}
              data-interval-percent={interval}
            ></span>
          ))}
        </div>
        {/* <input id="percentage-slider" type="range" min="0" max="100" step="10" data-percentage={percentageAssigned} /> */}
        <input
          id="percentage-slider"
          type="range"
          min="0"
          max="100"
          step={settings?.EnableManualAllocationPercentages ? 5 : stepSize}
          value={progress}
          // data-percentage={useablePercentageAssigned}
          // data-percentage={props.percentageAssigned}
          data-percentage={progress}
          onChange={(event) => {
            setProgress(parseInt(event.target.value));
            onSetProgress(parseInt(event.target.value));
          }}
        />
      </div>
    </div>
  );
};
