import cx from "classnames";

export interface LoaderProps {
  className?: string;
}

// TODO: check if we need to implement `withFade` and `positionLoader`. leaving `data-bind` for reference.
export const Loader: React.FC<LoaderProps> = (props) => {
  const { className } = props;

  return (
    <div
      className={cx("loader", className)}
      data-bind="fadeVisible: visible, css: cssClass, withFade: withFade, positionLoader: positionLoader"
    >
      <div className="spinner"></div>
    </div>
  );
};
