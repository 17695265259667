import { ProjectModel, ProjectSettingsModel } from 'api/model';
import classNames from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { CheckboxBlock } from './CheckboxBlock';
import CheckboxComponent from './CheckboxComponent';
import { CheckboxDoubleBlock } from './CheckboxDoubleBlock';
import { SelectBlock } from './SelectBlock';
import ProjectSettingsSection from './ProjectSettingsSection';

interface ProjectSettingsContentProps {
  settings: ProjectModel;
  onSubmitForm: (values: ProjectSettingsModel) => void;
}

export const ProjectSettingsContent: React.FC<ProjectSettingsContentProps> = (
  props
) => {
  const { settings, onSubmitForm } = props;
  const [tooltip, setTooltip] = useState('');
  const [visibilityTaskActive, setVisibilityTaskActive] = useState(false);
  const [primaryDetailActive, setPrimaryDetailActive] = useState(false);

  const hasTooltip = tooltip.length > 0;

  const initialValues = useMemo(() => {
    if (settings.TaskPrimaryDetail === 1) {
      return { ...settings, DisplayAssignedHours2: true };
    }
    if (settings.TaskPrimaryDetail === 2) {
      return { ...settings, DisplayPlannedHours2: true };
    }
    if (settings.TaskPrimaryDetail === 3) {
      return { ...settings, DisplayPercentComplete2: true };
    }
    if (settings.TaskPrimaryDetail === 4) {
      return { ...settings, DisplayTaskType2: true };
    }
    return settings;
  }, [settings]);

  const transformedFormValues = useCallback((values: any): any => {
    const {
      DisplayAssignedHours2,
      DisplayPlannedHours2,
      DisplayPercentComplete2,
      DisplayTaskType2,
      EventsEnabled
    } = values || {};
    const result = values;

    if (!EventsEnabled) {
      result.EventsInternalEnabled = false;
    }

    if (DisplayAssignedHours2) {
      result.TaskPrimaryDetail = 1;
      return result;
    }
    if (DisplayPlannedHours2) {
      result.TaskPrimaryDetail = 2;
      return result;
    }
    if (DisplayPercentComplete2) {
      result.TaskPrimaryDetail = 3;
      return result;
    }
    if (DisplayTaskType2) {
      result.TaskPrimaryDetail = 4;
      return result;
    }
    result.TaskPrimaryDetail = 0;
    return result;
  }, []);

  return (
    <div className='project-settings-page'>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }: FormikHelpers<ProjectModel>) => {
          onSubmitForm(transformedFormValues(values));
          setSubmitting(false);
        }}
      >
        {({ values }) => (
          <Form id='settings-form'>
            <table className='margin-bottom-2x'>
              <caption className='visually-hidden'>
                Project ownership settings
              </caption>
              <colgroup>
                <col className='checkbox-column' />
                <col />
              </colgroup>
              <thead className='nowrap'>
                <tr>
                  <th className='assignment-tooltip-ctnr' colSpan={2}>
                    <div className='tooltip visible'>Ownership</div>
                  </th>
                </tr>
              </thead>
              <tbody className='no-wrap'>
                <CheckboxBlock
                  id='can-borrow-operatives'
                  name='CanBorrowOperatives'
                  label='Enable borrowing of operatives?'
                />
                <CheckboxBlock
                  id='can-take-ownership-of-operatives'
                  name='CanTakeOwnershipOfOperatives'
                  label='Enable ownership of operatives?'
                />
              </tbody>
            </table>

            <table className='margin-bottom-2x'>
              <caption className='visually-hidden'>Week commencing day</caption>
              <colgroup>
                <col className='dropdown-column' />
                <col />
              </colgroup>
              <thead className='nowrap'>
                <tr>
                  <th className='assignment-tooltip-ctnr' colSpan={2}>
                    <div className='tooltip visible'>Week commencing day</div>
                  </th>
                </tr>
              </thead>
              <tbody className='no-wrap'>
                <SelectBlock
                  id='week-commencing-day'
                  name='WeekCommencesOn'
                  label='First column in task assignment grid'
                  options={[
                    { value: '1', text: 'Monday' },
                    { value: '2', text: 'Tuesday' },
                    { value: '3', text: 'Wednesday' },
                    { value: '4', text: 'Thursday' },
                    { value: '5', text: 'Friday' },
                    { value: '6', text: 'Saturday' },
                    { value: '0', text: 'Sunday' }
                  ]}
                />
              </tbody>
            </table>

            <table className='margin-bottom-2x'>
              <caption className='visually-hidden'>
                Project task search settings
              </caption>
              <colgroup>
                <col className='checkbox-column' />
                <col />
              </colgroup>
              <thead className='nowrap'>
                <tr>
                  <th className='assignment-tooltip-ctnr' colSpan={2}>
                    <div className='tooltip visible'>Task search</div>
                  </th>
                </tr>
              </thead>
              <tbody className='no-wrap'>
                <CheckboxBlock
                  id='can-search-tasks'
                  name='CanSearchTasks'
                  label='Can search tasks? (not implemented/supported)'
                  isDisabled={true}
                />
              </tbody>
            </table>

            <table className='margin-bottom-2x'>
              <caption className='visually-hidden'>
                Task allocation settings
              </caption>
              <colgroup>
                <col className='checkbox-column' />
                <col />
              </colgroup>
              <thead className='nowrap'>
                <tr>
                  <th className='assignment-tooltip-ctnr' colSpan={2}>
                    <div className='tooltip visible'>Task allocation</div>
                  </th>
                </tr>
              </thead>
              <tbody className='no-wrap'>
                <CheckboxBlock
                  id='enable-manual-allocation-percentages'
                  name='EnableManualAllocationPercentages'
                  label='Enable manual allocation percentages? (enables 5% allocations)'
                />
                <CheckboxBlock
                  id='force-comments-against-completed-tasks'
                  name='ForceCommentsAgainstCompletedTasks'
                  label='Force comments against completed tasks? (not implemented/supported)'
                  isDisabled={true}
                />
              </tbody>
            </table>

            <table className='margin-bottom-2x'>
              <caption className='visually-hidden'>
                Task allocation chunks
              </caption>
              <colgroup>
                <col className='dropdown-column' />
                <col />
              </colgroup>
              <thead className='nowrap'>
                <tr>
                  <th className='assignment-tooltip-ctnr' colSpan={2}>
                    <div className='tooltip visible'>
                      Task allocation chunks
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className='no-wrap'>
                <SelectBlock
                  id='allocation-percentage-chunk-size'
                  name='AllocationPercentageChunkSize'
                  label='Allocation chunk size'
                  options={[
                    { value: '10', text: '10%' },
                    { value: '20', text: '20%' },
                    { value: '25', text: '25%' },
                    { value: '50', text: '50%' },
                    { value: '100', text: '100%' }
                  ]}
                />
              </tbody>
            </table>

            <table className='margin-bottom-2x'>
              <caption className='visually-hidden'>
                Project task detail visiblity settings
              </caption>
              <colgroup>
                <col className='display-detail' />
                <col className='primary-detail' />
                <col />
              </colgroup>
              <thead className='nowrap'>
                <tr>
                  <th className='assignment-tooltip-ctnr' colSpan={3}>
                    <div className='tooltip visible'>
                      Task detail visibility
                    </div>
                  </th>
                </tr>
                <tr>
                  <th
                    className={classNames('display-detail', {
                      focus: visibilityTaskActive
                    })}
                    onMouseEnter={() => {
                      setTooltip(
                        'Toggle visibility of this detail for each task'
                      );
                      setPrimaryDetailActive(false);
                      setVisibilityTaskActive(true);
                    }}
                    onMouseLeave={() => {
                      setTooltip('');
                      setVisibilityTaskActive(false);
                    }}
                  ></th>
                  <th
                    className={classNames('primary-detail', {
                      focus: primaryDetailActive
                    })}
                    onMouseEnter={() => {
                      setTooltip('Set the primary detail for task listing');
                      setVisibilityTaskActive(false);
                      setPrimaryDetailActive(true);
                    }}
                    onMouseLeave={() => {
                      setTooltip('');
                      setPrimaryDetailActive(false);
                    }}
                  ></th>
                  <th className='assignment-tooltip-ctnr'>
                    <div
                      className={classNames('tooltip', {
                        visible: hasTooltip
                      })}
                    >
                      {tooltip}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className='no-wrap'>
                <CheckboxDoubleBlock
                  first={{
                    id: 'display-assigned-hours',
                    name: 'DisplayAssignedHours',
                    value: values.DisplayAssignedHours || false
                  }}
                  second={{
                    id: 'primary-detail-assigned-hours',
                    name: 'DisplayAssignedHours2',
                    value: 1
                  }}
                  label='Display assigned hours (not implemented/supported)'
                  isDisabled={true}
                />
                <CheckboxDoubleBlock
                  first={{
                    id: 'display-planned-hours',
                    name: 'DisplayPlannedHours',
                    value: values.DisplayPlannedHours || false
                  }}
                  second={{
                    id: 'primary-detail-planned-hours',
                    name: 'DisplayPlannedHours2',
                    value: 2
                  }}
                  label='Display planned hours (not implemented/supported)'
                  isDisabled={true}
                />
                <CheckboxDoubleBlock
                  first={{
                    id: 'display-percent-complete',
                    name: 'DisplayPercentComplete',
                    value: values.DisplayPercentComplete || false
                  }}
                  second={{
                    id: 'primary-detail-percent-complete',
                    name: 'DisplayPercentComplete2',
                    value: 3
                  }}
                  label='Display percent complete (not implemented/supported)'
                  isDisabled={true}
                />
                <CheckboxDoubleBlock
                  first={{
                    id: 'display-task-type',
                    name: 'DisplayTaskType',
                    value: values.DisplayTaskType || false
                  }}
                  second={{
                    id: 'primary-detail-task-type',
                    name: 'DisplayTaskType2',
                    value: 4
                  }}
                  label='Display task type (not implemented/supported)'
                  isDisabled={true}
                />
                <CheckboxBlock
                  id='display-assignment-to-gangs'
                  name='DisplayAssignmentToGangs'
                  label='Display assignment to gangs (not implemented/supported)'
                  threeColumns={true}
                  isDisabled={true}
                />
              </tbody>
            </table>

            <table className='margin-bottom-2x'>
              <caption className='visually-hidden'>
                Project task tab visibility settings
              </caption>
              <colgroup>
                <col className='checkbox-column' />
                <col />
              </colgroup>
              <thead className='nowrap'>
                <tr>
                  <th className='assignment-tooltip-ctnr' colSpan={2}>
                    <div className='tooltip visible'>Task tab visibility</div>
                  </th>
                </tr>
              </thead>
              <tbody className='no-wrap'>
                <CheckboxBlock
                  id='hide-global-task-tab'
                  name='HideGlobalTaskTab'
                  label='Hide Global task tab?'
                />
                <CheckboxBlock
                  id='hide-exception-task-tab'
                  name='HideExceptionTaskTab'
                  label='Hide Exception task tab?'
                />
              </tbody>
            </table>

            <table className='margin-bottom-2x'>
              <caption className='visually-hidden'>
                Task hierarchy settings
              </caption>
              <colgroup>
                <col className='checkbox-column' />
                <col />
              </colgroup>
              <thead className='nowrap'>
                <tr>
                  <th className='assignment-tooltip-ctnr' colSpan={2}>
                    <div className='tooltip visible'>
                      Collapse Task Hierarchies By Default
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className='no-wrap'>
                <CheckboxBlock
                  id='task-hierarchy-collapsed-by-default-on-task-search-page'
                  name='TaskHierarchyCollapsedByDefaultOnTaskSearchPage'
                  label='Task search page'
                />
              </tbody>
            </table>

            <ProjectSettingsSection title='Events settings'>
              <CheckboxComponent
                id='events-enabled'
                name='EventsEnabled'
                label='Enable events'
              />

              <CheckboxComponent
                id='events-internal-enabled'
                name='EventsInternalEnabled'
                label='Enable internal events'
                indent
                isDisabled={!values.EventsEnabled}
              />
            </ProjectSettingsSection>
          </Form>
        )}
      </Formik>
    </div>
  );
};
