import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Home() {
  // TODO: check user type and pick appropriate page to redirect to
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/tasks");
  }, [navigate]);
  return null;
}
