import { ProjectSettingsModel } from 'api/model';
import {
  useGetProjectAssignmentProjectSettingsGetProjects,
  usePostProjectAssignmentProjectSettingsPostProject
} from 'api/opcs';
import { Loader } from 'core/components';
import {
  NotificationContext,
  NotificationContextType,
  useSignalRHub
} from 'core/context';
import { NotificationType } from 'core/context/NotificationContext.types';
import { SettingsContext } from 'core/context/SettingsContext';
import { useUpdateBreadcrumbs } from 'core/hooks';
import React, { useCallback, useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectSettingsContent } from './components';

interface ProjectSettingsCompanyProps {}

export const ProjectSettingsCompany: React.FC<ProjectSettingsCompanyProps> = (
  props
) => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const settingsCtx = useContext(SettingsContext);
  useGetProjectAssignmentProjectSettingsGetProjects({
    query: {
      onSuccess: (data) =>
        data.Data && settingsCtx?.updateSettingsAll?.(data.Data)
    }
  });
  const mutation = usePostProjectAssignmentProjectSettingsPostProject();
  const { addNotification } = useContext(
    NotificationContext
  ) as NotificationContextType;
  const { invoke } = useSignalRHub('projectsettings');

  const handleCancel = () => {
    navigate('/project-settings');
  };

  const handleSubmit = useCallback(
    (data: ProjectSettingsModel) => {
      mutation.mutate(
        { data },
        {
          onSuccess: () => {
            invoke('setUpdateRequired');
            addNotification({
              Type: NotificationType.Success,
              Text: 'Your changes have been saved successfully.'
            });
            settingsCtx?.updateSettingsOne?.(data);
          },
          onError: () => {
            invoke('setUpdateRequired');
            addNotification({
              Type: NotificationType.Error,
              Text: 'You are not authorised to save these changes.'
            });
          }
        }
      );
    },
    [mutation, addNotification, invoke, settingsCtx]
  );

  const settings = useMemo(() => {
    return (
      settingsCtx?.settings.filter(
        (project) => project.Id === +companyId!
      )[0] || {}
    );
  }, [settingsCtx?.settings, companyId]);

  const projectName = useMemo(() => {
    return settings.Name;
  }, [settings]);

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments'
            },
            {
              name: 'Project settings',
              path: '/project-settings',
              selected: true
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership'
            },
            {
              name: 'Tasks',
              path: '/tasks'
            }
          ]
        },
        {
          withSearch: false,
          items: [
            {
              name: projectName!,
              path: '#',
              selected: true
            }
          ]
        }
      ],
      [projectName]
    )
  );

  return (
    <>
      <div className='project-settings'>
        <div className='project-settings-content'>
          <header className='assignment-header'>
            <button
              className='action-btn white-with-border'
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className='action-btn yellow large'
              type='submit'
              form='settings-form'
            >
              Save
            </button>
          </header>
          <h1 className='visually-hidden'>Project settings</h1>
          {projectName && (
            <ProjectSettingsContent
              settings={settings}
              onSubmitForm={async (data) => handleSubmit(data)}
            />
          )}
          {!projectName && <Loader />}
        </div>
      </div>
    </>
  );
};
