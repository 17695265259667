import { FC } from "react";

type OwnershipTableHeaderProps = {
  sortColumnName: string | undefined;
  sortColumnDirection: number | undefined;
  onColumnSort: (colName: string, sortDir: number) => void;
};

export const OwnershipTableHeader: FC<OwnershipTableHeaderProps> = (props) => {
  function colSort(colName: string) {
    let dir: number = 1;
    if (props.sortColumnName !== colName) {
      // setSortColumn(colName);
      dir = 1;
    } else {
      if (props.sortColumnDirection && props.sortColumnDirection > 0) {
        dir = -1;
      } else {
        dir = 1;
      }
    }
    // setSortDir(dir);
    props.onColumnSort(colName, dir);
  }

  const sortAsc = <span className="sort-icon">▼</span>;
  const sortDsc = <span className="sort-icon">▲</span>;
  return (
    <table>
      <colgroup>
        <col style={{ width: "200px" }} />
        <col style={{ width: "200px" }} />
        <col />
        <col style={{ width: "200px" }} />
        <col style={{ width: "200px" }} />
      </colgroup>
      <thead data-bind="sort: listing">
        <tr>
          <th
            scope="col"
            className="sortable"
            data-sort-by="PayrollId"
            title="Payroll ID"
            onClick={() => colSort("PayrollID")}
          >
            Payroll ID
            {props.sortColumnName === "PayrollID"
              ? props.sortColumnDirection === 1
                ? sortAsc
                : sortDsc
              : ""}
          </th>
          <th
            scope="col"
            className="sortable"
            data-sort-by="FullName"
            title="Full name"
            onClick={() => colSort("FullName")}
          >
            Full name
            {props.sortColumnName === "FullName"
              ? props.sortColumnDirection === 1
                ? sortAsc
                : sortDsc
              : ""}
          </th>
          <th
            scope="col"
            className="sortable"
            data-sort-by="TradeDescription"
            title="Trade description"
            onClick={() => colSort("TradeDescription")}
          >
            Trade description
            {props.sortColumnName === "TradeDescription"
              ? props.sortColumnDirection === 1
                ? sortAsc
                : sortDsc
              : ""}
          </th>
          <th
            scope="col"
            className="sortable"
            data-sort-by="CurrentGang"
            title="Current gang"
            onClick={() => colSort("CurrentGang")}
          >
            Current gang
            {props.sortColumnName === "CurrentGang"
              ? props.sortColumnDirection === 1
                ? sortAsc
                : sortDsc
              : ""}
          </th>
          <th
            scope="col"
            className="sortable"
            data-sort-by="BorrowedBy"
            title="Borrowed by"
            onClick={() => colSort("BorrowedBy")}
          >
            Borrowed by
            {props.sortColumnName === "BorrowedBy"
              ? props.sortColumnDirection === 1
                ? sortAsc
                : sortDsc
              : ""}
          </th>
        </tr>
      </thead>
    </table>
  );
};
