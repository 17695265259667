import React, { useContext, useState } from "react";
import { useDateUTC } from "core/hooks";
import type { CalendarSearchModel } from "types";
import { useParams } from "react-router-dom";
import { getUTCDateISOString } from "utils/date";

interface CalendarContextType {
  searchParams: CalendarSearchModel;
  setSearchParams: React.Dispatch<React.SetStateAction<CalendarSearchModel>>;
  projectId: string | undefined;
  userId: string | undefined;
  dateNowUtc: string;
}

export const CalendarContext = React.createContext<CalendarContextType>({
  searchParams: {
    projectId: 0,
    gangId: 0,
  },
  setSearchParams: () => {
    throw new Error("No setSearchParams available");
  },
  projectId: "0",
  userId: "0",
  dateNowUtc: getUTCDateISOString(),
});

export const useCalendarContext = () => {
  const context = useContext(CalendarContext);

  if (context === undefined) {
    throw new Error("useCalendarContext was used outside of its Provider");
  }

  return context;
};

export const CalendarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { projectId, userId } = useParams();
  const dateNowUtc = useDateUTC();
  const [searchParams, setSearchParams] = useState<CalendarSearchModel>({
    projectId: +(projectId || "0"),
    gangId: +(userId || "0"),
    operativeDaysRequestModel: {
      StartOfWeek: dateNowUtc,
    },
    projectTaskRequestModel: {
      DateInWeek: dateNowUtc,
      SearchTerm: "",
    },
  });

  return (
    <CalendarContext.Provider
      value={{
        searchParams,
        setSearchParams,
        projectId,
        userId,
        dateNowUtc,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};
