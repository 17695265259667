import type { Configuration } from "@azure/msal-browser";

if (!process.env.REACT_APP_AZURE_AD_CLIENT_ID) {
  console.warn(`REACT_APP_AZURE_AD_CLIENT_ID is not set!`);
}
if (!process.env.REACT_APP_AZURE_AD_TENANT_ID) {
  console.warn(`REACT_APP_AZURE_AD_TENANT_ID is not set!`);
}
if (!process.env.REACT_APP_AZURE_AD_INSTANCE) {
  console.warn(`REACT_APP_AZURE_AD_INSTANCE is not set!`);
}

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID!,
    authority: `${process.env.REACT_APP_AZURE_AD_INSTANCE}${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    redirectUri: "/",
  },
};
