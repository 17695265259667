import {
  useGetTaskAssignmentOperativesGetAllGangOperativesProjectId,
  useGetTaskAssignmentProjectsGetProjectsAndGangs
} from 'api/opcs';
import { useUpdateBreadcrumbs } from 'core/hooks';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { OperativesTable } from './components';

type TasksOperativesAllProps = {};

const useGetOperativesForAllGangs = (projectId: string) =>
  useGetTaskAssignmentOperativesGetAllGangOperativesProjectId(+projectId, {
    query: {
      select: (data) => data?.Data ?? []
    }
  });

const useGetProjectsAndGangs = () =>
  useGetTaskAssignmentProjectsGetProjectsAndGangs({
    query: {
      select: (data) => data?.Data ?? []
    }
  });

export const TasksOperativesAll: React.FC<TasksOperativesAllProps> = (
  props
) => {
  const { projectId } = useParams();
  const query = useGetOperativesForAllGangs(projectId!);
  const queryProjectsAndGangs = useGetProjectsAndGangs();

  const getProjectsBreadcrumb = useCallback(() => {
    return queryProjectsAndGangs.status === 'success'
      ? queryProjectsAndGangs.data.map((project: any) => {
          return {
            name: project.Name,
            path: `/tasks/${project.Id}/all/operatives`,
            selected: project.Id === +projectId!
          };
        })
      : [
          {
            name: 'NO PROJECTS ASSIGNED',
            path: '#',
            selected: true
          }
        ];
  }, [queryProjectsAndGangs.status, queryProjectsAndGangs.data, projectId]);

  const getProjectUsersBreadcrumb = useCallback(() => {
    if (queryProjectsAndGangs.status === 'success') {
      const selectedProject = queryProjectsAndGangs.data.find(
        (project: any) => project.Selected
      );
      return [
        {
          name: 'All Gangs',
          path: `/tasks/${projectId}/all/operatives`,
          selected: true
        },
        ...selectedProject.Gangs.map((gang: any) => ({
          name: gang.Name,
          path: `/tasks/${projectId}/${gang.Id}/operatives`,
          selected: false,
          deactivated: gang.Deactivated
        }))
      ];
    }
    return [
      {
        name: 'NO AVAILABLE PROJECTS',
        path: '#',
        selected: true
      }
    ];
  }, [queryProjectsAndGangs.status, queryProjectsAndGangs.data, projectId]);

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments'
            },
            {
              name: 'Project settings',
              path: '/project-settings'
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership'
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: true
            }
          ]
        },
        {
          withSearch: false,
          items: getProjectsBreadcrumb()
        },
        {
          withSearch: true,
          items: getProjectUsersBreadcrumb()
        },
        {
          withSearch: false,
          items: [
            {
              name: 'Operatives',
              path: '#',
              selected: true
            }
          ]
        }
      ],
      [getProjectsBreadcrumb, getProjectUsersBreadcrumb]
    )
  );

  return (
    <section className='operatives-list-content'>
      <OperativesTable
        operatives={query.status === 'success' ? query.data : []}
        isLoading={query.status === 'loading'}
      />
    </section>
  );
};
