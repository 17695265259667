import { useCallback } from "react";
import cx from "classnames";
import { format, parseJSON } from "date-fns";
import type { OperativeModel } from "api/model";
import { EmptyList, Loader, Pager } from "core/components";

export interface OperativePromotionProps {
  isLoading?: boolean;
  data: OperativeModel[];
  selectedOperative: OperativeModel | null;
  currentPage: number;
  totalPages: number;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isSaving?: boolean;

  onQueryChanged?: (query: string) => void;
  onOperativeSelected?: (operative: OperativeModel | null) => void;
  onSave?: () => void;
  onPageChanged?: (newPage: number) => void;
}

const formatDate = (date?: string | null): string => {
  return date ? format(parseJSON(date), "EEE, dd/MM/yyyy") : "";
};

export const OperativePromotion: React.FC<OperativePromotionProps> = (
  props
) => {
  const {
    isLoading,
    data,
    selectedOperative,
    currentPage,
    totalPages,
    hasNextPage,
    hasPreviousPage,
    isSaving,
    onQueryChanged,
    onOperativeSelected,
    onSave,
    onPageChanged,
  } = props;

  const hasItems = data.length > 0;
  const showSearchBar = selectedOperative === null;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onQueryChanged?.(event.target.value);
    },
    [onQueryChanged]
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback((event) => {
      if (event.key === "Enter") {
        event.currentTarget.blur();
      }
    }, []);

  const handleRowClick = useCallback(
    (operative: OperativeModel | null) => () => {
      if (operative?.IsSupervisor) {
        return;
      }
      onOperativeSelected?.(operative);
    },
    [onOperativeSelected]
  );

  return (
    <>
      <section className="header-container">
        <section className="actions-container">
          {showSearchBar ? (
            <section className="search">
              <form id="search-operatives-form">
                <input
                  className="search-textbox large"
                  type="search"
                  placeholder="Search operatives"
                  maxLength={100}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </form>
            </section>
          ) : (
            <>
              <span className="spacer" />
              <span className="button-help-text visible">
                Promote "{selectedOperative?.FullName}, payroll id:{" "}
                {selectedOperative?.PayrollId}" to supervisor
              </span>
              <button
                className="large fixed-width yellow margin-right-1x"
                onClick={onSave}
              >
                {isSaving ? "Saving" : "Save"}
              </button>
              <button
                className="large fixed-width white-with-border"
                onClick={handleRowClick(null)}
              >
                Cancel
              </button>
            </>
          )}
        </section>
        {!isLoading && (
          <div className="relative-container operatives-list-container no-margin">
            <table>
              <colgroup>
                <col className="fullname" style={{ width: "40%" }} />
                <col className="payroll-code" style={{ width: "30%" }} />
                <col
                  className="latest-date-hours-worked"
                  style={{ width: "30%" }}
                />
              </colgroup>
              <thead>
                <tr>
                  <th className="fullname" title="Full name">
                    Full name
                  </th>
                  <th className="payroll-code" title="Payroll ID">
                    Payroll ID
                  </th>
                  <th
                    className="latest-date-worked"
                    title="Latest date that hours worked data exists"
                  >
                    Latest date worked
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        )}
      </section>

      <div className="relative-container">
        {isLoading && <Loader className="pinned translucent" />}
      </div>

      {!isLoading && (
        <div className="operatives-listing-container relative-container operatives-list-container no-margin">
          <table className="listing">
            <caption className="visually-hidden">List of operatives</caption>
            <colgroup>
              <col className="fullname" style={{ width: "40%" }} />
              <col className="payroll-code" style={{ width: "30%" }} />
              <col
                className="latest-date-hours-worked"
                style={{ width: "30%" }}
              />
            </colgroup>
            <tbody className="no-wrap">
              {data.map((operative) => (
                <tr
                  key={operative.Id}
                  className={cx({
                    selectable: !operative.IsSupervisor,
                    disabled: operative.IsSupervisor,
                    selected: operative.Id === selectedOperative?.Id,
                  })}
                  onClick={handleRowClick(operative)}
                >
                  <td
                    className={cx("fullname", {
                      "supervisor-icon": operative.IsSupervisor,
                    })}
                    title={
                      operative.IsSupervisor
                        ? `${operative.FullName} (Supervisor)`
                        : operative.FullName
                    }
                  >
                    {operative.FullName}
                  </td>
                  <td className="payroll-code" title={operative.PayrollId}>
                    {operative.PayrollId}
                  </td>
                  <td
                    className="latest-date-worked"
                    title={formatDate(operative.LatestDateWorked)}
                  >
                    {formatDate(operative.LatestDateWorked)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!hasItems && !isLoading && (
            <EmptyList message="There are no operatives to display." />
          )}
        </div>
      )}
      {hasItems && totalPages > 1 && (
        <section className="listing-pager padding-top-2x padding-bottom-2x">
          <div className="button-array">
            <Pager
              className="inline right"
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChanged={onPageChanged}
              isNextEnabled={hasNextPage}
              isPreviousEnabled={hasPreviousPage}
            />
          </div>
        </section>
      )}
    </>
  );
};
