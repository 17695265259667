import { postTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangId } from "api/opcs";
import { useQuery } from "react-query";
import { CalendarSearchModel } from "types";

const useGetAssignedProjectTasks = (searchModel: CalendarSearchModel) => {
  return useQuery(
    ["tasks", "getAssignedProjectTasks", searchModel],
    ({ signal }) =>
      postTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangId(
        searchModel.projectId,
        searchModel.gangId,
        searchModel.projectTaskRequestModel!,
        {
          signal,
        }
      ),
    {
      select: (data) => data?.Data ?? [],
    }
  );
};

export default useGetAssignedProjectTasks;
