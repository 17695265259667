import React, { useCallback, useEffect, useState } from "react";

export interface FilterBarProps {
  onQueryChanged?: (query: string) => void;
}

export const FilterBar: React.FC<FilterBarProps> = (props) => {
  const [query, setQuery] = useState("");

  const { onQueryChanged } = props;

  useEffect(() => {
    onQueryChanged?.(query);
  }, [onQueryChanged, query]);

  const resetQuery = useCallback(() => {
    setQuery("");
  }, []);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setQuery(event.target.value);
    },
    []
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback((event) => {
      if (event.key === "Enter") {
        event.currentTarget.blur();
      }
    }, []);

  return (
    <div className="filter-controls-ctnr">
      <input
        className="supervisor-filter-textbox large"
        type="search"
        placeholder="Filter supervisors"
        maxLength={100}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={query}
      />
      {query && (
        <span
          role="button"
          tabIndex={-1}
          className="reset-filter"
          onClick={resetQuery}
        />
      )}
    </div>
  );
};
