import type { OperativeDaysModel, ProjectTaskHierarchyModel } from "api/model";
import { EmptyList, Loader } from "core/components";
import { useCallback, useEffect, useState } from "react";
import { ProjectHierarchy } from "./ProjectHierarchy";

export interface ProjectTasksProps {
  projectId: number;
  gangId: number;
  data?: ProjectTaskHierarchyModel[];
  isLoading?: boolean;
  calendarData?: OperativeDaysModel;

  forceShowProjectTasks?: boolean;
  forceCollapsProjectTasks?: boolean;
  hasSelectedOperatives?: boolean;
  isManageMode?: boolean;

  isSavingProjectTaskId?: number;
  handleIncludeProjectTaskInTaskList?: (
    projectTaskId: number,
    include: boolean
  ) => void;

  handleExpandTask?: (id: number | null) => void;
  onRemoveProjectTaskAssignment?: (projectTaskAssignmentId: number) => void;
}

export const ProjectTasks: React.FC<ProjectTasksProps> = (props) => {
  const {
    projectId,
    gangId,
    isLoading,
    forceShowProjectTasks,
    forceCollapsProjectTasks,
    data,
    isSavingProjectTaskId,
    hasSelectedOperatives,
    isManageMode,
    handleIncludeProjectTaskInTaskList,
    handleExpandTask,
    calendarData,
    onRemoveProjectTaskAssignment,
  } = props;

  const [expandedTask, setExpandedTasks] = useState(new Set<number>());
  const handleTaskToggle = useCallback((id: number) => {
    setExpandedTasks((oldSet) => {
      const newSet = new Set(oldSet);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  }, []);

  useEffect(() => {
    if (!forceCollapsProjectTasks) {
      const mapper = (model: ProjectTaskHierarchyModel): number[] => {
        return model.ChildHierarchies?.length
          ? [model.Id!, ...model.ChildHierarchies.flatMap(mapper)]
          : [];
      };
      const IDs = data?.flatMap(mapper);
      setExpandedTasks(new Set(IDs));
    }
  }, [data, forceCollapsProjectTasks]);

  return (
    <>
      <h2 className="visually-hidden">Project tasks</h2>
      {data?.map((hierarchy) => (
        <ProjectHierarchy
          key={hierarchy.Id}
          projectId={projectId}
          gangId={gangId}
          data={hierarchy}
          depth={1}
          isOverviewPanelOpen={false}
          expandedTasks={expandedTask}
          onToggleTask={handleTaskToggle}
          forceShowProjectTasks={forceShowProjectTasks}
          isSavingProjectTaskId={isSavingProjectTaskId}
          hasSelectedOperatives={hasSelectedOperatives}
          isManageMode={isManageMode}
          handleIncludeProjectTaskInTaskList={
            handleIncludeProjectTaskInTaskList
          }
          handleExpandTask={handleExpandTask}
          calendarData={calendarData}
          onRemoveProjectTaskAssignment={onRemoveProjectTaskAssignment}
        />
      ))}
      {isLoading && <Loader className="pinned translucent" />}
      {!isLoading && data?.length === 0 && (
        <EmptyList message="There are no tasks to display." />
      )}
    </>
  );
};
