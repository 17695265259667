import {
  OperativeOwnershipListingModelPageResultEnvelope,
  OperativeOwnershipRequestModel,
  SearchModel,
  GangAssignedRequestModel
} from 'api/model';
import customInstance from 'api/mutator/custom-instance';
import {
  postTaskAssignmentProjectsGetGangsAssignedTimeProjectId,
  useGetTaskAssignmentProjectsGetProjectsAndGangs,
  usePostTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangId,
  usePostTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangId,
  usePostTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangId
} from 'api/opcs';
import { useDateUTC, useUpdateBreadcrumbs } from 'core/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { convertOldGangIdToNewGangId } from 'utils/convertOldGangIdToNewGangId';
import {
  OwnershipActionsBar,
  OwnershipTable,
  OwnershipValidationBox,
  OwnershipValidationMode,
  OwnershipTableHeader
} from './components';

type TasksOwnershipProps = {};

/**
 * Orval doesn't seem to pick up header params (like `x-pageNumber`), so we have
 * a little copying and pasting to do
 */

type PaginatedSearchModel = SearchModel & {
  Page: number;
  ProjectId: number;
  GangId: number;
  Data: OperativeOwnershipRequestModel;
};

type ValidationUser = {
  Id: number;
  Name: string;
};

type TimeAssignmentModel = {
  projectId: number;
  gangAssignedRequestModel: GangAssignedRequestModel;
};

enum ActionType {
  'CanBorrowOperative',
  'CanRemoveBorrowedOperative',
  'CanTakeOwnershipOfOperative'
}

const pageSize = 200;

export const postTaskassignmentOperativesownershipGetoperativesProjectIdGangId =
  (searchModel: PaginatedSearchModel) => {
    return customInstance<OperativeOwnershipListingModelPageResultEnvelope>({
      url: `/task-assignment/operatives-ownership/get-operatives/${searchModel.ProjectId}/${searchModel.GangId}`,
      method: 'post',
      data: searchModel.Data,
      headers: {
        'x-pageNumber': searchModel.Page,
        'x-pageSize': pageSize
      }
    });
  };

const useGetProjectsAndGangs = () =>
  useGetTaskAssignmentProjectsGetProjectsAndGangs({
    query: {
      select: (data) => data?.Data ?? []
    }
  });

const useGetGangsAssignedTime = (timeAssignmentModel: TimeAssignmentModel) => {
  return useQuery(
    ['tasks', 'getGangsAssignedTime', timeAssignmentModel],
    () =>
      postTaskAssignmentProjectsGetGangsAssignedTimeProjectId(
        timeAssignmentModel.projectId,
        timeAssignmentModel.gangAssignedRequestModel
      ),
    {
      select: (data) => data?.Data ?? []
    }
  );
};

export const TasksOwnership: React.FC<TasksOwnershipProps> = (props) => {
  const { projectId, userId } = useParams();
  const [startDate] = useState(new Date());

  const useGetTasksOwnership = (searchModel: PaginatedSearchModel) => {
    return useQuery(
      ['tasks', 'ownership', searchModel],
      () =>
        postTaskassignmentOperativesownershipGetoperativesProjectIdGangId(
          searchModel
        ),
      {
        select: (data) => {
          const result = data.Data;

          return result
            ? {
                items: result.Items,
                pages: Math.ceil(result.Count! / pageSize)
              }
            : { items: [], pages: 0 };
        }
      }
    );
  };

  const [searchParams, setSearchParams] = useState<PaginatedSearchModel>({
    Page: 1,
    ProjectId: +projectId!,
    GangId: +userId!,
    Data: {
      SearchTerm: '',
      Week: startDate.toISOString(),
      SortColumn: 'FullName',
      SortDirection: 1
    }
  });

  //Op2-84 Reinitilize search param when gang is changed
  if (searchParams.GangId !== +userId!) {
    setSearchParams({
      Page: searchParams.Page,
      ProjectId: +projectId!,
      GangId: +userId!,
      Data: {
        SearchTerm: searchParams.Data.SearchTerm,
        Week: searchParams.Data.Week,
        SortColumn: searchParams.Data.SortColumn,
        SortDirection: searchParams.Data.SortDirection
      }
    });
  }

  const dateNowUtc = useDateUTC();
  const [gangsAssignedTimeQueryParams, setGangsAssignedTimeQueryParams] =
    useState({
      projectId: +projectId!,
      gangAssignedRequestModel: {
        StartOfWeek: dateNowUtc
      }
    });
  // OP2-70: Ensure that the projectID is updated (so that the Supervisor dropdown includes percentages)
  useEffect(() => {
    if (+projectId! !== gangsAssignedTimeQueryParams.projectId) {
      setGangsAssignedTimeQueryParams({
        projectId: +projectId!,
        gangAssignedRequestModel: {
          StartOfWeek: dateNowUtc
        }
      });
    }
    // eslint-disable-next-line
  }, [projectId]);
  const [selectedOwnership, setSelectedOwnership] = useState<Set<number>>(
    new Set()
  );
  const [validUsers, setValidUsers] = useState<ValidationUser[]>([]);
  const [invalidUsers, setInvalidUsers] = useState<ValidationUser[]>([]);
  const [mode, setMode] = useState<OwnershipValidationMode>(
    OwnershipValidationMode.BORROW
  );
  const query = useGetTasksOwnership(searchParams);

  const queryProjectsAndGangs = useGetProjectsAndGangs();
  const borrowMutation =
    usePostTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangId();
  const removeBorrowMutation =
    usePostTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangId();
  const takeOwnershipMutation =
    usePostTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangId();
  const queryGangsAssignedTime = useGetGangsAssignedTime(
    gangsAssignedTimeQueryParams
  );

  const getPercentageAssignedTimeById = useCallback(
    (id: number) => {
      const result = queryGangsAssignedTime.data?.find(
        (gang: any) => gang.GangId === id
      );
      if (result === undefined) return null;

      return result?.PercentageAssigned?.toFixed(2) ?? '0.00';
    },
    [queryGangsAssignedTime.data]
  );

  const getProjectsBreadcrumb = useCallback(() => {
    return queryProjectsAndGangs.status === 'success'
      ? queryProjectsAndGangs.data.map((project: any) => {
          // OP2-58: Map the current gangID into the project's gangID
          let newGangId: string | undefined = convertOldGangIdToNewGangId(
            queryProjectsAndGangs.data,
            projectId,
            userId,
            project.Gangs
          );
          return {
            name: project.Name,
            path: `/tasks/${project.Id}/${newGangId}/operatives`,
            selected: project.Id === +projectId!
          };
        })
      : [
          {
            name: 'NO PROJECTS ASSIGNED',
            path: '#',
            selected: true
          }
        ];
  }, [
    queryProjectsAndGangs.status,
    queryProjectsAndGangs.data,
    projectId,
    userId
  ]);

  const getProjectUsersBreadcrumb = useCallback(() => {
    if (queryProjectsAndGangs.status === 'success') {
      // OP2-58: Don't use Selected attribute, use the projectID
      const selectedProject = queryProjectsAndGangs.data.find(
        (project: any) => project.Id === +projectId!
      );
      // const selectedProject = queryProjectsAndGangs.data.find((project: any) => project.Selected);
      return [
        {
          name: 'All Gangs',
          path: `/tasks/${projectId}/all/operatives`,
          selected: false
        },
        ...selectedProject.Gangs.map((gang: any) => ({
          // name: gang.Name,
          name:
            getPercentageAssignedTimeById(gang.Id) === null
              ? `${gang.Name}`
              : `${gang.Name} (${getPercentageAssignedTimeById(gang.Id)}%)`,
          path: `/tasks/${projectId}/${gang.Id}/ownership`,
          selected: gang.Id === +userId!,
          deactivated: gang.Deactivated
        }))
      ];
    }
    return [
      {
        name: 'NO AVAILABLE PROJECTS',
        path: '#',
        selected: true
      }
    ];
  }, [
    queryProjectsAndGangs.status,
    queryProjectsAndGangs.data,
    projectId,
    userId,
    getPercentageAssignedTimeById
  ]);

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments'
            },
            {
              name: 'Project settings',
              path: '/project-settings'
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership'
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: true
            }
          ]
        },
        {
          withSearch: false,
          items: getProjectsBreadcrumb()
        },
        {
          withSearch: true,
          items: getProjectUsersBreadcrumb()
        },
        {
          withSearch: false,
          items: [
            {
              name: 'Tasks',
              path: `/tasks/${projectId}/${userId}/tasks`
            },
            {
              name: 'Operatives',
              path: `/tasks/${projectId}/${userId}/operatives`,
              selected: false
            },
            {
              name: 'Ownership',
              path: '#',
              selected: true
            }
          ]
        }
      ],
      [getProjectsBreadcrumb, getProjectUsersBreadcrumb, projectId, userId]
    )
  );

  const onChangeWeek = useCallback((week: string) => {
    setSearchParams((current) => ({
      ...current,
      Data: {
        ...current.Data,
        Week: week
      }
    }));
    setGangsAssignedTimeQueryParams((curr) => ({
      ...curr,
      gangAssignedRequestModel: {
        StartOfWeek: week
      }
    }));
  }, []);

  const onChangeSearchTerm = useCallback((searchTerm: string) => {
    setSearchParams((current) => ({
      ...current,
      Data: {
        ...current.Data,
        SearchTerm: searchTerm
      }
    }));
  }, []);

  const handlePageChanged = useCallback((page: number) => {
    setSearchParams((current) => ({
      ...current,
      Page: page
    }));
  }, []);

  const handleOwnershipSelected = useCallback(
    (ownershipId: number) => {
      const newSet = new Set(selectedOwnership);
      if (selectedOwnership.has(ownershipId)) {
        newSet.delete(ownershipId);
      } else {
        newSet.add(ownershipId);
      }
      setSelectedOwnership(newSet);
    },
    [selectedOwnership]
  );

  const clearUserValidation = useCallback(() => {
    setValidUsers([]);
    setInvalidUsers([]);
  }, []);

  const validateUsers = useCallback(
    (type: ActionType) => {
      clearUserValidation();
      const validUsersLocal: ValidationUser[] = [];
      const invalidUsersLocal: ValidationUser[] = [];
      Array.from(selectedOwnership).forEach((selectedUser) => {
        const user = query.data?.items?.find(
          (item) => item.Id === selectedUser
        );
        const localUser = { Id: user?.Id!, Name: user?.FullName! };

        if (type === ActionType.CanBorrowOperative) {
          if (user && user.CanBorrowOperative) {
            setValidUsers((prev) => [...prev, localUser]);
            validUsersLocal.push(localUser);
          }
          if (user && !user.CanBorrowOperative) {
            setInvalidUsers((prev) => [...prev, localUser]);
            invalidUsersLocal.push(localUser);
          }
        }
        if (type === ActionType.CanRemoveBorrowedOperative) {
          if (user && user.CanRemoveBorrowedOperative) {
            setValidUsers((prev) => [...prev, localUser]);
            validUsersLocal.push(localUser);
          }
          if (user && !user.CanRemoveBorrowedOperative) {
            setInvalidUsers((prev) => [...prev, localUser]);
            invalidUsersLocal.push(localUser);
          }
        }
        if (type === ActionType.CanTakeOwnershipOfOperative) {
          if (user && user.CanTakeOwnershipOfOperative) {
            setValidUsers((prev) => [...prev, localUser]);
            validUsersLocal.push(localUser);
          }
          if (user && !user.CanTakeOwnershipOfOperative) {
            setInvalidUsers((prev) => [...prev, localUser]);
            invalidUsersLocal.push(localUser);
          }
        }
      });
      return { validUsersLocal, invalidUsersLocal };
    },
    [selectedOwnership, query.data?.items, clearUserValidation]
  );

  const handleBorrow = useCallback(
    (validate: boolean) => {
      setMode(OwnershipValidationMode.BORROW);
      const validation = validateUsers(ActionType.CanBorrowOperative);
      if (
        !validate ||
        (validation.validUsersLocal.length &&
          !validation.invalidUsersLocal.length)
      ) {
        borrowMutation.mutate(
          {
            projectId: searchParams.ProjectId,
            gangId: searchParams.GangId,
            data: {
              Week: searchParams.Data.Week,
              Individuals: validation.validUsersLocal.map((user) => user.Id)
            }
          },
          {
            onSuccess: () => {
              query.refetch();
              setSelectedOwnership(new Set());
              clearUserValidation();
            }
          }
        );
      }
    },
    [searchParams, borrowMutation, query, validateUsers, clearUserValidation]
  );

  const handleRemoveBorrow = useCallback(
    (validate: boolean) => {
      setMode(OwnershipValidationMode.RETURN);
      const validation = validateUsers(ActionType.CanRemoveBorrowedOperative);
      if (
        !validate ||
        (validation.validUsersLocal.length &&
          !validation.invalidUsersLocal.length)
      ) {
        removeBorrowMutation.mutate(
          {
            projectId: searchParams.ProjectId,
            gangId: searchParams.GangId,
            data: {
              Week: searchParams.Data.Week,
              Individuals: validation.validUsersLocal.map((user) => user.Id)
            }
          },
          {
            onSuccess: () => {
              query.refetch();
              setSelectedOwnership(new Set());
              clearUserValidation();
            }
          }
        );
      }
    },
    [
      searchParams,
      removeBorrowMutation,
      query,
      validateUsers,
      clearUserValidation
    ]
  );

  const handleTakeOwnership = useCallback(
    (validate: boolean) => {
      setMode(OwnershipValidationMode.TAKE_OWNERSHIP);
      const validation = validateUsers(ActionType.CanTakeOwnershipOfOperative);

      if (
        !validate ||
        (validation.validUsersLocal.length &&
          !validation.invalidUsersLocal.length)
      ) {
        takeOwnershipMutation.mutate(
          {
            projectId: searchParams.ProjectId,
            gangId: searchParams.GangId,
            data: {
              Week: searchParams.Data.Week,
              Individuals: validation.validUsersLocal.map((user) => user.Id)
            }
          },
          {
            onSuccess: () => {
              query.refetch();
              setSelectedOwnership(new Set());
              clearUserValidation();
            }
          }
        );
      }
    },
    [
      searchParams,
      takeOwnershipMutation,
      query,
      validateUsers,
      clearUserValidation
    ]
  );

  const handleConfirmValidation = useCallback(() => {
    if (mode === OwnershipValidationMode.BORROW) {
      handleBorrow(false);
      return;
    }
    if (mode === OwnershipValidationMode.RETURN) {
      handleRemoveBorrow(false);
      return;
    }
    if (mode === OwnershipValidationMode.TAKE_OWNERSHIP) {
      handleTakeOwnership(false);
      return;
    }
  }, [mode, handleBorrow, handleRemoveBorrow, handleTakeOwnership]);

  const getNamesOfUsers = useCallback((users: ValidationUser[]) => {
    return users.map((user) => user.Name);
  }, []);

  function sortColumn(colName: string, sortDir: number) {
    setSearchParams((current) => ({
      ...current,
      Data: {
        ...current.Data,
        SortColumn: colName,
        SortDirection: sortDir
      }
    }));
  }

  const totalPages = query.data?.pages || 0;

  return (
    <section className='operatives-ownership-content'>
      <section className='header-container'>
        <OwnershipActionsBar
          startDate={startDate}
          onChangeWeek={onChangeWeek}
          onChangeSearchTerm={onChangeSearchTerm}
          onBorrow={() => handleBorrow(true)}
          onRemoveBorrow={() => handleRemoveBorrow(true)}
          onTakeOwnership={() => handleTakeOwnership(true)}
        />
        <OwnershipTableHeader
          onColumnSort={sortColumn}
          sortColumnName={searchParams.Data.SortColumn}
          sortColumnDirection={searchParams.Data.SortDirection}
        />
        {/* <OwnershipTableHeader /> */}
      </section>
      <OwnershipTable
        isLoading={
          query.isLoading ||
          borrowMutation.isLoading ||
          removeBorrowMutation.isLoading ||
          takeOwnershipMutation.isLoading
        }
        data={query.data?.items || []}
        selectedOwnership={selectedOwnership}
        currentPage={searchParams.Page}
        totalPages={totalPages}
        hasPreviousPage={searchParams.Page > 1}
        hasNextPage={searchParams.Page < totalPages}
        onOwnershipSelected={handleOwnershipSelected}
        onPageChanged={handlePageChanged}
        // sortParams={colSortData}
      />
      {invalidUsers.length > 0 && (
        <OwnershipValidationBox
          mode={mode}
          invalidUsers={getNamesOfUsers(invalidUsers)}
          validUsers={getNamesOfUsers(validUsers)}
          onCancel={clearUserValidation}
          onConfirm={handleConfirmValidation}
        />
      )}
    </section>
  );
};
