import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { formatISO } from 'date-fns';
import { useSignalRHub } from './SignalRContext';
import { Notification } from './NotificationContext.types';

export type NotificationContextType = {
  notifications: Notification[];
  addNotification: (
    notification: Omit<Notification, 'Created' | 'Key' | 'ClientKey'>
  ) => void;
  clearNotifications: () => void;
};

export const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType
);

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { invoke } = useSignalRHub('notifications');

  useEffect(() => {
    invoke('getRecent', 5)?.then(
      (notifications: LowercaseProps<Notification>[]) => {
        setNotifications(
          notifications.map((notification) => ({
            Type: notification.type,
            Text: notification.text,
            Key: notification.key,
            Created: notification.created,
            ClientKey: notification.clientkey
          }))
        );
      }
    );
  }, [invoke]);

  const addNotification = useCallback(
    (notification: Omit<Notification, 'Created' | 'Key' | 'ClientKey'>) => {
      const n: Notification = {
        ...notification,
        Created: formatISO(Date.now()),
        Key: uuidv4(),
        ClientKey: null
      };

      setNotifications((notifications) => [n, ...notifications]);

      const preventAlertAllClients = false;
      invoke(
        'create',
        n.Type,
        n.Text,
        n.Created,
        n.Key,
        preventAlertAllClients
      );
    },
    [invoke]
  );

  const clearNotifications = useCallback(() => {
    setNotifications([]);
    invoke('dismissAll');
  }, [invoke]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        clearNotifications
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

type LowercaseProps<T extends object> = {
  [P in keyof T as Lowercase<P & string>]: T[P];
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      `useNotificationsContext needs to wrapped in NotificationContext`
    );
  }

  return context;
};
