import { last } from "rambda";
import React, { useCallback } from "react";

export enum OwnershipValidationMode {
  "BORROW",
  "RETURN",
  "TAKE_OWNERSHIP",
}

type OwnershipValidationBoxProps = {
  mode: OwnershipValidationMode;
  validUsers: string[];
  invalidUsers: string[];
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const OwnershipValidationBox: React.FC<OwnershipValidationBoxProps> = (
  props
) => {
  const { mode, validUsers, invalidUsers, onConfirm, onCancel } = props;

  const getFormattedNames = useCallback((users: string[]) => {
    const usersLength = users.length;
    const lastUser = last(users);
    return usersLength > 2
      ? `${users.slice(0, usersLength - 1).join(", ")} and ${lastUser}`
      : usersLength > 1
      ? `${users[0]} and ${lastUser}`
      : lastUser || "";
  }, []);

  const canConfirm = validUsers.length > 0;

  return (
    <section className="full-page-panel docked-panel ownership-panel">
      <div className="flex-container justify-space-between">
        <h1 className="task-name align-self-start">
          {mode === OwnershipValidationMode.BORROW && "Borrow operatives"}
          {mode === OwnershipValidationMode.RETURN && "Return operatives"}
          {mode === OwnershipValidationMode.TAKE_OWNERSHIP &&
            "Take ownership of operatives"}
        </h1>
        <div className="action-buttons">
          {canConfirm && (
            <button className="yellow large" onClick={onConfirm}>
              Confirm
            </button>
          )}
          <button
            className="white-with-border"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
      <fieldset>
        {validUsers.length > 0 && (
          <div className="field">
            <div className="field-holder full-width copy-text">
              <span>
                {mode === OwnershipValidationMode.BORROW &&
                  `${getFormattedNames(validUsers)} will be borrowed.`}
                {mode === OwnershipValidationMode.RETURN &&
                  `${getFormattedNames(validUsers)} will be returned.`}
                {mode === OwnershipValidationMode.TAKE_OWNERSHIP &&
                  `Ownership of ${getFormattedNames(
                    validUsers
                  )} will be taken.`}
              </span>
            </div>
          </div>
        )}
        {invalidUsers.length > 0 && (
          <div className="field">
            <div className="field-holder full-width copy-text">
              <span>
                {mode === OwnershipValidationMode.BORROW &&
                  `${getFormattedNames(
                    invalidUsers
                  )} will not be borrowed as this is not a valid action.`}
                {mode === OwnershipValidationMode.RETURN &&
                  `${getFormattedNames(
                    invalidUsers
                  )} will not be returned as this is not a valid action.`}
                {mode === OwnershipValidationMode.TAKE_OWNERSHIP &&
                  `Ownership of ${getFormattedNames(
                    invalidUsers
                  )} will not be taken as this is not a valid action.`}
              </span>
            </div>
          </div>
        )}
      </fieldset>
    </section>
  );
};
