import { WeekSelector } from "core/components";
import { TasksContext, NotificationContext } from "core/context";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OwnershipSearcher } from "./OwnershipSearcher";
import { NotificationType } from "core/context/NotificationContext.types";
import { SettingsContext } from "core/context/SettingsContext";

type OwnershipActionsBarProps = {
  startDate: Date;
  onChangeSearchTerm: (searchTerm: string) => void;
  onChangeWeek?: (week: string) => void;
  onBorrow?: () => void;
  onRemoveBorrow?: () => void;
  onTakeOwnership?: () => void;
};

export const OwnershipActionsBar: React.FC<OwnershipActionsBarProps> = (
  props
) => {
  const {
    startDate,
    onChangeSearchTerm,
    onChangeWeek,
    onBorrow,
    onRemoveBorrow,
    onTakeOwnership,
  } = props;

  const [startDay, SetStartDay] = useState<any | undefined>(0);

  const [showSearcher, setShowSearcher] = useState(false);
  const taskCtx = useContext(TasksContext);
  const settingsCtx = useContext(SettingsContext);
  const notificationCtx = useContext(NotificationContext);
  const { projectId, userId } = useParams();

  const settings = useMemo(() => {
    return settingsCtx?.getSettingsById?.(+projectId!);
    // eslint-disable-next-line
  }, [settingsCtx?.getSettingsById, projectId]);

  useEffect(() => {
    SetStartDay(settings?.WeekCommencesOn);
  }, [settings?.WeekCommencesOn]);

  const handleBorrow = useCallback(() => {
    const setting = settingsCtx?.getSettingsById?.(+projectId!);
    if (!setting?.CanBorrowOperatives) {
      notificationCtx?.addNotification({
        Type: NotificationType.Information,
        Text: "Operative borrowing is disabled in project settings.",
      });
    } else {
      onBorrow?.();
    }
  }, [settingsCtx, notificationCtx, onBorrow, projectId]);

  const handleOwnership = useCallback(() => {
    const setting = settingsCtx?.getSettingsById?.(+projectId!);
    if (!setting?.CanTakeOwnershipOfOperatives) {
      notificationCtx?.addNotification({
        Type: NotificationType.Information,
        Text: "Operative Ownership is disabled in project settings.",
      });
    } else {
      onTakeOwnership?.();
    }
  }, [settingsCtx, notificationCtx, onTakeOwnership, projectId]);

  useEffect(() => {
    return () => {
      taskCtx?.setOwnershipBackButtonEnabled(false);
    };
  }, [taskCtx]);

  //OP2-83 Backbutton to navigate to task screen
  const navigate = useNavigate();
  const handleGoBack = useCallback(() => {
    navigate(`/tasks/${+projectId!}/${+userId!}/tasks`);
  }, [navigate, projectId, userId]);

  return (
    <section className="actions-container">
      {taskCtx?.ownershipBackButtonEnabled && (
        <button
          className="large white-with-border margin-right-1x"
          title="Back"
          onClick={handleGoBack}
        >
          Back
        </button>
      )}
      {/* {!showSearcher && <WeekSelector startDate={startDate} weekStartsOn={startDay} onChangeWeek={onChangeWeek} />} */}
      {!showSearcher && (
        <WeekSelector
          startDate={startDate}
          weekStartsOn={startDay}
          onChangeStartDayOfWeek={onChangeWeek}
        />
      )}
      {showSearcher && (
        <OwnershipSearcher
          onClickCancel={() => setShowSearcher(false)}
          onChangeSearchTerm={onChangeSearchTerm}
        />
      )}
      {!showSearcher && <span className="spacer"></span>}
      <span className="button-help-text"></span>
      {!showSearcher && (
        <button
          className="search-icon icon-button yellow large margin-right-1x"
          title="Search"
          onClick={() => setShowSearcher(true)}
        ></button>
      )}
      <button
        className="borrow-icon icon-button yellow large margin-right-1x"
        title="Borrow operative"
        onClick={handleBorrow}
      ></button>
      <button
        className="remove-borrowed-operative-icon yellow icon-button large margin-right-1x"
        title="Return operative"
        onClick={onRemoveBorrow}
      ></button>
      <button
        className="take-ownership-icon yellow icon-button large"
        title="Take ownership"
        onClick={handleOwnership}
      ></button>
    </section>
  );
};
