import { UserModel } from "api/model";
import { EmptyList, Loader } from "core/components";

export interface UserListingProps {
  isLoading?: boolean;
  users: UserModel[];

  onRowClicked?: (userId: number) => void;
}

export const UserListing: React.FC<UserListingProps> = (props) => {
  const { isLoading, users, onRowClicked } = props;

  const hasItems = users.length > 0;

  return (
    <>
      {hasItems && (
        <table className="sticky-header-table table-with-headers">
          <colgroup>
            <col className="fullname" />
            <col className="ad-username" />
            <col className="payroll-code" />
          </colgroup>
          <thead>
            <tr>
              <th className="fullname" title="Full name">
                Full name
              </th>
              <th className="ad-username" title="Active Directory username">
                Active Directory username
              </th>
              <th className="payroll-code" title="Payroll ID">
                Payroll ID
              </th>
            </tr>
          </thead>
        </table>
      )}
      <div className="listing-container">
        {hasItems && (
          <table className="listing user-listing table-with-headers">
            <caption className="visually-hidden">List of users</caption>
            <colgroup>
              <col className="fullname" />
              <col className="ad-username" />
              <col className="payroll-code" />
            </colgroup>
            <tbody className="no-wrap">
              {users.map((user) => (
                <tr
                  className="selectable"
                  key={user.Id}
                  onClick={() => onRowClicked?.(user.Id!)}
                  /*
                   * TODO: is it even possible to select a row?
                   */
                  data-bind="visible: visible, css: { selected: $parent.isSelected($data) }"
                >
                  <td className="fullname" title={user.FullName}>
                    {user.FullName}
                  </td>
                  <td
                    className="ad-username"
                    title={user.ActiveDirectoryUsername}
                  >
                    {user.ActiveDirectoryUsername}
                  </td>
                  <td className="payroll-code" title={user.PayrollId}>
                    {user.PayrollId}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {isLoading && <Loader className="pinned translucent" />}
      </div>
      {!isLoading && !hasItems && (
        <EmptyList message="There are no users to display." />
      )}
    </>
  );
};
