import { ListedNotificationModel } from "api/model";
import { EmptyList, Loader } from "core/components";
import { format, parseJSON } from "date-fns";
import cx from "classnames";
import React from "react";
import { getNotificationClassname } from "../notificationIcon";

interface NotificationsTableProps {
  notifications: ListedNotificationModel[];
  isLoading?: boolean;
}

const formatDate = (date?: string | null): string => {
  return date ? format(parseJSON(date), "dd/MM/yy HH:mm:ss") : "";
};

export const NotificationsTable: React.FC<NotificationsTableProps> = (
  props
) => {
  const { notifications, isLoading } = props;

  const hasItems = notifications.length > 0;

  return (
    <>
      <section className="margin-left-2x margin-right-2x margin-bottom-2x">
        <div className="relative-parent margin-top-2x margin-bottom-4x">
          {hasItems && (
            <table className="listing">
              <caption className="visually-hidden">
                List of previous notifications
              </caption>
              <colgroup>
                <col style={{ width: "48px" }} />
                <col style={{ width: "190px" }} />
                <col />
              </colgroup>
              <thead className="no-wrap">
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {notifications.map((notification, idx) => (
                  <tr key={idx}>
                    <td
                      className={cx(
                        "notification-icon",
                        getNotificationClassname(notification)
                      )}
                    >
                      &nbsp;
                    </td>
                    <td>{formatDate(notification.Created)}</td>
                    <td>{notification.Text}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {isLoading && <Loader className="pinned translucent" />}
          {!isLoading && !hasItems && (
            <EmptyList message="There are no notifications to display." />
          )}
        </div>
      </section>
    </>
  );
};
