import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { Notification } from "core/context/NotificationContext.types";
import { getNotificationClassname } from "features/notifications";
import { TopBarModal } from "./TopBarModal";
import HelpCardModal from "./HelpCardModal";
import { useLocalStorage } from "./useLocalStorage";

export interface TopBarProps {
  username: string;

  showMiniPanel?: boolean;
  notifications: Notification[];
  clearNotifications: () => void;
  onCloseNotificationsModal?: () => void;
}

export const TopBarMenu: React.FC<TopBarProps> = (props) => {
  const {
    username,
    showMiniPanel,
    notifications,
    clearNotifications,
    onCloseNotificationsModal,
  } = props;
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [openUserInfoModal, setOpenUserInfoModal] = useState(false);
  const [openHelpCardModal, setOpenHelpCardModal] = useState(false);

  const hasNotifications = notifications.length > 0;
  const [colorBlind, setColorBlind] = useLocalStorage("colorBlind", "");

  const Toggler = useCallback(() => {
    colorBlind === "on" ? setColorBlind("off") : setColorBlind("on");
    colorBlind === "on"
      ? document.body.classList.remove("color-blind-option")
      : document.body.classList.add("color-blind-option");
  }, [colorBlind, setColorBlind]);

  useEffect(() => {
    colorBlind === "on" && document.body.classList.add("color-blind-option");
  }, [colorBlind]);

  useEffect(() => {
    if (showMiniPanel) {
      setOpenNotificationModal(true);
    }
  }, [showMiniPanel]);

  return (
    <div className="header-items-menu">
      <div
        className="notifications-center"
        onClick={() => setOpenNotificationModal(true)}
      >
        <TopBarModal
          openModal={openNotificationModal}
          onCloseModal={() => {
            setOpenNotificationModal(false);
            onCloseNotificationsModal?.();
          }}
        >
          <div
            className={cx(
              "notifications-panel hover-panel fixed main-menu-panel main-menu-flyout square-top help-button-visible",
              {
                "mini-panel": showMiniPanel,
              }
            )}
          >
            <div className="panel-arrow up"></div>
            <div className="notification-options main-menu-flyout-options">
              <a
                href="/#"
                className="link trash bg-secondary-grey-3"
                style={{ display: hasNotifications ? "block" : "none" }}
                onClick={() => {
                  clearNotifications();
                  setTimeout(() => setOpenNotificationModal(false), 0);
                }}
              >
                Clear
              </a>
              <Link
                to={"/notifications"}
                className="view"
                onClick={() => {
                  setTimeout(() => setOpenNotificationModal(false), 0);
                }}
              >
                View all
              </Link>
            </div>
            <hr />
            <ul className="notifications">
              {hasNotifications ? (
                notifications.slice(0, 5).map((notification, idx) => (
                  <li
                    key={idx}
                    className={cx(getNotificationClassname(notification), {
                      "is-read": showMiniPanel ? idx > 0 : true,
                    })}
                  >
                    {notification.Text}
                  </li>
                ))
              ) : (
                <li className="no-notifications">
                  You have no new notifications
                </li>
              )}
            </ul>
          </div>
        </TopBarModal>
      </div>
      <div className="help-icon" onClick={() => setOpenHelpCardModal(true)}>
        <TopBarModal
          openModal={openHelpCardModal}
          onCloseModal={() => setOpenHelpCardModal(false)}
        >
          <HelpCardModal
            onCloseModal={(e: any) => {
              e.stopPropagation();
              setOpenHelpCardModal(false);
            }}
          />
        </TopBarModal>
      </div>
      <div className="user-info" onClick={() => setOpenUserInfoModal(true)}>
        <TopBarModal
          openModal={openUserInfoModal}
          onCloseModal={() => setOpenUserInfoModal(false)}
        >
          <div className="hover-panel fixed main-menu-panel main-menu-flyout square-top">
            <div className="panel-arrow up"></div>
            <div className="menu-item user">{username}</div>
            <hr />
            <div className="main-menu-flyout-options">
              <a href="/#" className="view" onClick={Toggler}>
                Color-Blind {colorBlind === "on" ? "(off)" : "(on)"}
              </a>

              <a href="/sign-out" className="exit">
                Sign out
              </a>
            </div>
          </div>
        </TopBarModal>
      </div>
    </div>
  );
};
