import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import type { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import {
  PrivateRoute,
  Home,
  ProjectAssignments,
  ProjectSettings,
  SupervisorOwnership,
  SignOut,
  Notifications,
  Tasks,
} from "pages";
import { CustomNavigationClient } from "utils/NavigationClient";
import { TopBar } from "features/top-bar";
import {
  NotificationsProvider,
  BreadcrumbsProvider,
  SignalRContextProvider,
  TasksProvider,
} from "core/context";
import "./index.scss";
import {
  SettingsContext,
  SettingsProvider,
} from "core/context/SettingsContext";
import { useGetProjectAssignmentProjectSettingsGetProjects } from "api/opcs";
import { SEARCH_QUERY_KEY } from "features/project-assignments";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <SignalRContextProvider>
          <NotificationsProvider>
            <BreadcrumbsProvider>
              <TasksProvider>
                <SettingsProvider>
                  <div className="durandal-wrapper">
                    <TopBar />

                    <div className="page-host">
                      <Pages />
                    </div>
                  </div>
                </SettingsProvider>
              </TasksProvider>
            </BreadcrumbsProvider>
          </NotificationsProvider>
        </SignalRContextProvider>
      </MsalProvider>
    </ClientSideNavigation>
  );
}

function Pages() {
  const settingsCtx = useContext(SettingsContext);
  useGetProjectAssignmentProjectSettingsGetProjects({
    query: {
      onSuccess: (data) => {
        settingsCtx?.updateSettingsAll?.(data.Data!);
      },
    },
  });

  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.includes("project-assignments")) {
      localStorage.removeItem(SEARCH_QUERY_KEY);
    }
  }, [pathname]);

  return (
    <Routes>
      <Route
        path="/project-assignments/*"
        element={
          <PrivateRoute>
            <ProjectAssignments />
          </PrivateRoute>
        }
      />
      <Route
        path="/project-settings/*"
        element={
          <PrivateRoute>
            <ProjectSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/notifications/*"
        element={
          <PrivateRoute>
            <Notifications />
          </PrivateRoute>
        }
      />
      <Route
        path="/tasks/*"
        element={
          <PrivateRoute>
            <Tasks />
          </PrivateRoute>
        }
      />
      <Route
        path="/supervisor-ownership/*"
        element={
          <PrivateRoute>
            <SupervisorOwnership />
          </PrivateRoute>
        }
      />
      <Route path="/sign-out/*" element={<SignOut />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

type ClientSideNavigationProps = {
  pca: IPublicClientApplication;
};

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
const ClientSideNavigation: React.FC<ClientSideNavigationProps> = ({
  pca,
  children,
}) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return <>{children}</>;
};

export default App;
