import { useCallback, useContext, useState } from "react";
import cx from "classnames";
import type { OperativeDaysModel, ProjectTaskHierarchyModel } from "api/model";
import { Loader } from "core/components";
import { ProjectHierarchyProjectTasks } from "./ProjectHierarchyProjectTasks";
import { TasksContext } from "core/context";

export interface ProjectHierarchyProps {
  projectId: number;
  gangId: number;

  className?: string;
  depth: number;
  isOverviewPanelOpen?: boolean;

  data: ProjectTaskHierarchyModel;
  isLoading?: boolean;

  expandedTasks: Set<number>;
  onToggleTask: (id: number) => void;

  forceShowProjectTasks?: boolean;

  isSavingProjectTaskId?: number;
  handleIncludeProjectTaskInTaskList?: (
    projectTaskId: number,
    include: boolean
  ) => void;

  hasSelectedOperatives?: boolean;
  isManageMode?: boolean;

  handleExpandTask?: (id: number | null) => void;
  calendarData?: OperativeDaysModel;
  onRemoveProjectTaskAssignment?: (projectTaskAssignmentId: number) => void;
}

export const ProjectHierarchy: React.FC<ProjectHierarchyProps> = (props) => {
  const {
    projectId,
    gangId,
    className,
    depth,
    isOverviewPanelOpen,
    isLoading,
    data,
    expandedTasks,
    hasSelectedOperatives,
    isManageMode,
    onToggleTask,
    forceShowProjectTasks,
    isSavingProjectTaskId,
    handleIncludeProjectTaskInTaskList,
    handleExpandTask,
    calendarData,
    onRemoveProjectTaskAssignment,
  } = props;

  const isSafari = false;

  const [showProjectTasks, setShowProjectTasks] = useState(false);
  const [selectedProjectTask, setSelectedProjectTask] = useState(
    new Set<number>()
  );
  const taskCtx = useContext(TasksContext);

  const handleProjectTaskToggle = useCallback(
    (id: number) => {
      if (!isManageMode && !hasSelectedOperatives) {
        const taskName = data.ProjectTasks?.find(
          (projectTask) => projectTask.Id === id
        )?.Name;

        if (taskName) {
          taskCtx?.updateTaskName(taskName);
        }

        if (!selectedProjectTask.has(id)) {
          handleExpandTask?.(id);
          taskCtx?.updateInspectionMode({
            general: true,
            byTask: true,
          });
        } else {
          handleExpandTask?.(null);
        }
      }
      setSelectedProjectTask((oldSet) => {
        const newSet = new Set(oldSet);
        if (!hasSelectedOperatives) {
          if (newSet.has(id)) {
            newSet.delete(id);
          } else {
            newSet.add(id);
          }
        }
        return newSet;
      });
    },
    [
      selectedProjectTask,
      hasSelectedOperatives,
      isManageMode,
      data.ProjectTasks,
      handleExpandTask,
      taskCtx,
    ]
  );

  const isExpanded = expandedTasks.has(data.Id!) || forceShowProjectTasks;

  const handleVisibilityClick = useCallback(() => {
    if (data.ProjectTasks?.length) {
      setShowProjectTasks((show) => !show);
      // OP2-71 Display Child Hierarchies if they exist
      if (data.ChildHierarchies?.length) {
        onToggleTask?.(data.Id!);
      }
    } else {
      onToggleTask?.(data.Id!);
    }
    // OP2-71 Added data.ChildHierarchies?.length
  }, [
    data.Id,
    data.ProjectTasks?.length,
    data.ChildHierarchies?.length,
    onToggleTask,
  ]);

  return (
    <div
      className={cx("project-hierarchy hierarchy-with-children", className, {
        "with-fixed-header": false, //!isSearchMode(),
        "padding-bottom-1x":
          isExpanded || forceShowProjectTasks || showProjectTasks, // includePaddingBottom(),
        "hierarchy-with-children--depth-even": depth % 2 === 0,
        "hierarchy-with-children--depth-odd": depth % 2 !== 0,
      })}
    >
      <div
        className={cx("project-task-hierarchy", {
          "full-width": false, // !taskVisibilityButtonVisible(),
          ["depth-" + depth]: true,
          "has-even-depth-parent": depth % 2 === 0,
          "reset-sticky": isSafari,
        })}
        title={data.Name}
      >
        {data.Name}
      </div>
      <button
        className={cx("task-visibility", {
          "unassigned-visible": isExpanded,
          ["depth-" + depth]: true,
          "reset-sticky": isSafari,
        })}
        title={
          isExpanded ? "Collapse hierarchy level" : "Expand hierarchy level"
        }
        onClick={handleVisibilityClick}
      />
      {(forceShowProjectTasks || showProjectTasks) &&
        data.ProjectTasks?.map((projectTask, idx, arr) => (
          <ProjectHierarchyProjectTasks
            key={projectTask.Id}
            projectId={projectId}
            gangId={gangId}
            isFirst={idx === 0}
            isLast={idx === arr.length - 1}
            depth={depth}
            task={projectTask}
            selectedProjectTasks={selectedProjectTask}
            handleProjectTaskClick={() =>
              handleProjectTaskToggle(projectTask.Id!)
            }
            isSavingProjectTaskId={isSavingProjectTaskId}
            handleIncludeProjectTaskInTaskList={
              handleIncludeProjectTaskInTaskList
            }
            hasSelectedOperatives={hasSelectedOperatives}
            isManageMode={isManageMode}
            calendarData={calendarData}
            onRemoveProjectTaskAssignment={onRemoveProjectTaskAssignment}
          />
        ))}
      {/* <!-- ko if: $component.isOverviewPanelOpen --> */}
      {/* <project-task-assignments params="assignments: projectTaskAssignments"></project-task-assignments> */}
      {/* <!-- /ko --> */}
      {isLoading && (
        <div className="task-details full-width">
          <Loader className="pinned" />
        </div>
      )}
      {isExpanded &&
        data.ChildHierarchies?.map((hierarchy, idx) => (
          <ProjectHierarchy
            key={hierarchy.Id}
            projectId={projectId}
            gangId={gangId}
            className={cx({
              "no-margin-top": idx === 0 && true,
            })}
            data={hierarchy}
            depth={depth + 1}
            isOverviewPanelOpen={isOverviewPanelOpen}
            expandedTasks={expandedTasks}
            onToggleTask={onToggleTask}
            forceShowProjectTasks={forceShowProjectTasks}
            isSavingProjectTaskId={isSavingProjectTaskId}
            hasSelectedOperatives={hasSelectedOperatives}
            isManageMode={isManageMode}
            handleIncludeProjectTaskInTaskList={
              handleIncludeProjectTaskInTaskList
            }
            handleExpandTask={handleExpandTask}
            calendarData={calendarData}
            onRemoveProjectTaskAssignment={onRemoveProjectTaskAssignment}
          />
        ))}
    </div>
  );
};
