import { useMsal } from '@azure/msal-react';
import { AssignmentModel, OperativeAssignmentModel } from 'api/model';
import { useGetTaskAssignmentDelayreasonsProjectId } from 'api/opcs';
import classNames from 'classnames';
import { DelayReasonSelect, SliderInput, ToggleButton } from 'core/components';
import { BreadcrumbsContext, BreadcrumbsContextType } from 'core/context';
import { format } from 'date-fns';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export type AppAssignmentSaveData = {};

type AppAssignmentProps = {
  operativeAssignments: OperativeAssignmentModel[];
  percentageSpare?: number;
  onExpand?: () => void;
  onClose?: () => void;
  onSave?: (data: AssignmentModel) => void;
  taskName?: string;
};

const useGetDelayReasons = (projectId: number) =>
  useGetTaskAssignmentDelayreasonsProjectId(projectId, {
    query: {
      select: (data) => data?.Data ?? []
    }
  });

export const AppAssignment: React.FC<AppAssignmentProps> = (props) => {
  const {
    operativeAssignments,
    percentageSpare,
    onExpand,
    onClose,
    onSave,
    taskName
  } = props;

  const { projectId } = useParams();
  const [checked, setChecked] = useState(false);
  const [dateOfComment] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [percentageAssigned, setPercentageAssigned] = useState(10);
  const [hoursDelayed, setHoursDelayed] = useState(0);
  const [comment, setComment] = useState<string>();
  const [delayReasonId, setDelayReasonId] = useState<number>(0);

  const delayReasonQuery = useGetDelayReasons(+projectId!);

  const hasPercentageSpare = percentageSpare! >= 0;

  // New
  // OP2-79 Gang name and current user were hardcoded...
  // Get the gang name from the breadcrumbs (find the selected one)
  const breadcrumbs: BreadcrumbsContextType = useContext(
    BreadcrumbsContext
  ) as BreadcrumbsContextType;

  const currentSupervisor = breadcrumbs.breadcrumbs[2].items.filter(
    (o) => o.selected === true
  )[0].name;
  // Get the current user
  const pca = useMsal();
  const username = useMemo(() => {
    return pca?.accounts[0]?.name || '';
  }, [pca]);
  // End new...

  const handleToggle = useCallback(() => {
    setChecked((current) => !current);
    onExpand?.();
  }, [setChecked, onExpand]);

  const handleSetProgress = useCallback(
    (progress: number) => {
      setPercentageAssigned(progress);
    },
    [setPercentageAssigned]
  );

  const handleOnChangeHoursDelayed = useCallback(
    (e) => {
      setHoursDelayed(e.target.value);
    },
    [setHoursDelayed]
  );

  const handleSave = useCallback(
    (acceptAssignment: boolean) => {
      onSave?.({
        PercentageAssigned: percentageAssigned,
        AcceptAssignment: acceptAssignment,
        DelayOrComments: checked
          ? {
              DelayReasonId: delayReasonId === undefined ? 0 : +delayReasonId!,
              HoursDelayed: +hoursDelayed!,
              Comment: comment
            }
          : undefined,
        OperativeAssignments: operativeAssignments
      });
    },
    [
      operativeAssignments,
      percentageAssigned,
      checked,
      delayReasonId,
      hoursDelayed,
      comment,
      onSave
    ]
  );
  const [buttonSaveDisabled, setButtonSaveDisabled] = useState(false);

  const handleAcceptAssignment = useCallback(() => {
    handleSave(true);
  }, [handleSave]);

  const handleChangeDelayReason = useCallback(
    (id: number) => {
      setDelayReasonId(id);
    },
    [setDelayReasonId]
  );

  const hoursDelayedFixed = useMemo(() => {
    const val = +hoursDelayed * operativeAssignments.length;
    return val.toFixed(2);
  }, [hoursDelayed, operativeAssignments]);

  return (
    <div className='app-assignment'>
      <section
        className={classNames('full-page-panel assignment-panel', {
          'docked-panel': !checked,
          'as-relative': checked,
          'with-validation': hasPercentageSpare
        })}
        data-bind="fullPagePanelVisible: visible, css: { 'docked-panel': partialPanelVisible() }"
      >
        <form data-bind='submit: save'>
          <div className='flex-container justify-space-between'>
            <h1
              data-bind='text: taskName'
              className='task-name align-self-start'
            >
              {/* Concrete Pumping TeamAAAAAAA */}
              {taskName}
            </h1>
            <div className='action-buttons'>
              {hasPercentageSpare && (
                <button
                  className='yellow large'
                  onClick={handleAcceptAssignment}
                  type='button'
                >
                  Accept
                </button>
              )}
              {!hasPercentageSpare && (
                <button
                  className='yellow large'
                  type='button'
                  disabled={buttonSaveDisabled}
                  style={{
                    backgroundColor: buttonSaveDisabled
                      ? 'lightgray'
                      : undefined
                  }}
                  onClick={() => {
                    if (!buttonSaveDisabled) {
                      handleSave(false);
                      setButtonSaveDisabled(true);
                    }
                  }}
                >
                  Save
                </button>
              )}

              <button
                className='white-with-border'
                onClick={onClose}
                type='button'
              >
                Cancel
              </button>
            </div>
          </div>
          <fieldset className='margin-top-2x'>
            <div className='field field--delay-toggle-or-reasons'>
              <div className='field-label field-label--allocation-percentage'>
                <label>
                  Allocation percentage{' '}
                  <span
                    className='percentage-assigned-label'
                    data-bind="css: { 'visible': $parent.displayPercentageAssignedLabel }, text: '(' + percentageAssigned() + '%)'"
                  >
                    (10%)
                  </span>
                </label>
              </div>
              <div className='field-holder'>
                {/* <SliderInput percentageAssigned={percentageAssigned} onSetProgress={handleSetProgress} /> */}
                <SliderInput onSetProgress={handleSetProgress} />
              </div>
            </div>
          </fieldset>
          {hasPercentageSpare && (
            <p className='confirmation-box'>
              This assignment will take the total time assigned over 100%.
              <br /> Reducing the selected percentage value to {percentageSpare}
              % will bring the total assigned time to 100%.
              <br /> If you accept the current assignment as it is any existing
              assignments will be removed.
            </p>
          )}

          <fieldset>
            <div className='field'>
              <div className='field-label field-label--delay-toggle-or-reason'>
                <label>Add delay or comments?</label>
              </div>
              <div className='field-holder'>
                <ToggleButton
                  checked={checked}
                  onToggle={handleToggle}
                  toggleOnLabel='YES'
                  toggleOffLabel='NO'
                />
              </div>
            </div>
          </fieldset>
          {checked && (
            <fieldset>
              <legend className='visually-hidden'>Add delay or comments</legend>
              <div className='field'>
                <div className='field-label'>
                  <label>Date of comment</label>
                </div>
                <div className='field-holder'>
                  <label>{dateOfComment}</label>
                </div>
              </div>
              <div className='field'>
                <div className='field-label'>
                  <label>Gang name</label>
                </div>
                <div className='field-holder'>
                  <label data-bind='text: $parent.gangName()'>
                    {currentSupervisor} ({username})
                  </label>
                </div>
              </div>
              <div className='field'>
                <div className='field-label'>
                  <label htmlFor='HoursDelayed'>Hours delayed</label>
                </div>
                <div className='field-holder'>
                  <input
                    id='HoursDelayed'
                    name='HoursDelayed'
                    data-bind='numberValue: hoursDelayed, decimalPlaces: 2, updateImmediate: true'
                    placeholder='Write here'
                    type='number'
                    pattern='[0-9]*'
                    value={hoursDelayed}
                    onChange={handleOnChangeHoursDelayed}
                  />
                </div>
              </div>
              <div className='field'>
                <div className='field-label'>
                  <label>Total hours delayed</label>
                </div>
                <div className='field-holder'>
                  <label data-bind='text: $parent.totalHoursDelayed'>
                    {hoursDelayedFixed}
                  </label>
                </div>
              </div>
              <div className='field'>
                <div className='field-label'>
                  <label htmlFor='Comment'>Comments</label>
                </div>
                <div className='field-holder'>
                  <textarea
                    cols={20}
                    id='Comment'
                    name='Comment'
                    data-bind='textInput: comment'
                    placeholder='Write here'
                    rows={1}
                    data-autosize-on='true'
                    style={{ overflow: 'hidden', height: 32 }}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <DelayReasonSelect
                delayReasons={delayReasonQuery.data || []}
                isLoading={delayReasonQuery.isLoading}
                onChangeDelayReason={handleChangeDelayReason}
              />
            </fieldset>
          )}
        </form>
      </section>
    </div>
  );
};
