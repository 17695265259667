import { useGetEventsAll } from "api/opcs";

const useGetAllAvailableEvents = () =>
  useGetEventsAll({
    query: {
      select: (data) => data?.Data ?? [],
    },
  });

export default useGetAllAvailableEvents;
