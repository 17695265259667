import { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { useTimeout } from "@chakra-ui/hooks";
import { useHover } from "@mantine/hooks";
import type { SuperUserSupervisorOwnershipCollectionModel} from "api/model";
import { EmptyList, Loader } from "core/components";

export interface UserSupervisorListingProps {
  isLoading?: boolean;
  data: SuperUserSupervisorOwnershipCollectionModel;

  assignmentType: "superuser" | "supervisor";
  onCheckboxChange?: (assignmentIdx: number) => void;
}

export const UserSupervisorListing: React.FC<UserSupervisorListingProps> = (
  props
) => {
  const { isLoading, data, assignmentType, onCheckboxChange } = props;

  const hasItems = data.Ownerships.length > 0;

  const handleCheckboxChange = useCallback(
    (assignmentIdx: number) => () => {
      onCheckboxChange?.(assignmentIdx);
    },
    [onCheckboxChange]
  );

  const { hovered, ref } = useHover<HTMLTableHeaderCellElement>();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [timerCallback, setTimerCallback] = useState<() => void>(
    () => () => {}
  );
  useEffect(() => {
    if (!hovered || tooltipVisible) {
      return;
    }

    setTooltipVisible(true);
    setTimerCallback(() => () => setTooltipVisible(false));
  }, [hovered, tooltipVisible]);
  useTimeout(timerCallback, 5000);

  return (
    <>
      <div className="listing-container">
        <div className="assignment-tooltip--floating">
          <div className={cx("tooltip", { visible: tooltipVisible })}>
            {assignmentType === "supervisor"
              ? "Toggle supervisor ownership for this super user"
              : "Toggle supervisor ownership for this super user"}
          </div>
        </div>
        {!isLoading && hasItems && (
          <table className="super-user-supervisor-listing table-with-headers">
            <caption className="visually-hidden">List of </caption>
            <colgroup>
              <col
                className={cx({
                  "supervisor-assignment": assignmentType === "supervisor",
                  "superuser-assignment": assignmentType === "superuser",
                })}
              />
              <col className="fullname" />
              <col className="ad-username" />
              <col className="payroll-code" />
            </colgroup>
            <thead className="nowrap">
              <tr>
                <th
                  className={cx({
                    "supervisor-ownership": assignmentType === "supervisor",
                    "superuser-ownership": assignmentType === "superuser",
                  })}
                  ref={ref}
                />
                <th title="Full Name">Full name</th>
                <th title="Active Directory username">Active Directory username</th>
                <th title="Payroll ID">Payroll ID</th>
              </tr>
            </thead>
            <tbody className="no-wrap">
              {data.Ownerships.map((assignment, idx) => (
                <tr key={assignment.SupervisorId}>
                  {assignmentType === "supervisor" && (
                    <td className="checkbox-label">
                      <input
                        id={`supervisor-assignment-cbx-${assignment.SupervisorId}`}
                        type="checkbox"
                        defaultChecked={assignment.IsOwned}
                        onChange={handleCheckboxChange(idx)}
                      />
                      <label
                        htmlFor={`supervisor-assignment-cbx-${assignment.SupervisorId}`}
                      />
                    </td>
                  )}
                  {assignmentType === "superuser" && (
                    <td className="checkbox-label">
                      <input
                        id={`superuser-assignment-cbx-${assignment.SupervisorId}`}
                        type="checkbox"
                        defaultChecked={assignment.IsOwned}
                        onChange={handleCheckboxChange(idx)}
                      />
                      <label
                        htmlFor={`superuser-assignment-cbx-${assignment.SupervisorId}`}
                      />
                    </td>
                  )}
                  <td className="fullname" title={assignment.FullName}>
                    {assignment.FullName}
                  </td>
                  <td className="ActiveDirectoryUsername" title={assignment.ActiveDirectoryUsername}>
                    {assignment.ActiveDirectoryUsername}
                  </td>
                  <td className="PayrollId" title={assignment.PayrollId}>
                    {assignment.PayrollId}
                  </td>
               
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {isLoading && <Loader className="pinned translucent" />}
      </div>
      {!isLoading && !hasItems && (
        <EmptyList message="There are no Supervisors to display." />
      )}
    </>
  );
};
