import { Field } from 'formik';
import React from 'react';

type CheckboxBlockProps = {
  id: string;
  name: string;
  label: string;
  threeColumns?: boolean;
  isDisabled?: boolean;
};

export const CheckboxBlock: React.FC<CheckboxBlockProps> = (props) => {
  const { id, name, label, threeColumns, isDisabled } = props;

  // Based on the disabled argument, decide if the checkbox should is disabled
  var disableControl = isDisabled && isDisabled === true ? true : false;

  return (
    <tr>
      <td className='checkbox-label'>
        <Field id={id} name={name} type='checkbox' disabled={disableControl} />
        <label htmlFor={id}></label>
      </td>
      {threeColumns && <td className='checkbox-label'></td>}
      <td className='project-name'>
        <label htmlFor={id}>{label}</label>
      </td>
    </tr>
  );
};
