// src/utils/buildDateHelper.js

import preval from "preval.macro";

function formatToVersion(dIso) {
  // Format the date into a version (yyyy.mm.dd-hh.mm.ss)
  const version = dIso.substring(0, 19).replace(/-/g, ".").replace(/:/g, ".").replace("T", "-") + " (UTC)";

  return version;
}

// This is evaluated at Build time, yielding the date/time that the frontend was built
const buildDate = preval`module.exports = new Date().toISOString();`;

const frontendBuildDate = formatToVersion(buildDate);



export { frontendBuildDate };
