import { postTaskAssignmentProjectsGetGangsAssignedTimeProjectId } from "api/opcs";
import { useQuery } from "react-query";
import { TimeAssignmentModel } from "types";

const useGetGangsAssignedTime = (timeAssignmentModel: TimeAssignmentModel) => {
  return useQuery(
    ["tasks", "getGangsAssignedTime", timeAssignmentModel],
    ({ signal }) =>
      postTaskAssignmentProjectsGetGangsAssignedTimeProjectId(
        timeAssignmentModel.projectId,
        timeAssignmentModel.gangAssignedRequestModel,
        {
          signal,
        }
      ),
    {
      select: (data) => data?.Data ?? [],
    }
  );
};

export default useGetGangsAssignedTime;
