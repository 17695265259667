import { Route, Routes } from "react-router-dom";
import {
  TasksRedirect,
  TasksOperatives,
  TasksOperativesEmpty,
  TasksOperativesAll,
  TasksOwnership,
  TasksCalendar,
  TasksManage,
} from "features/tasks";
import { CalendarProvider } from "core/context/CalendarContext";

export const Tasks: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<TasksRedirect />} />
      <Route
        path="/empty/:userId/operatives"
        element={<TasksOperativesEmpty />}
      />
      <Route
        path="/:projectId/all/operatives"
        element={<TasksOperativesAll />}
      />
      <Route
        path="/:projectId/:userId/operatives"
        element={<TasksOperatives />}
      />
      <Route
        path="/:projectId/:userId/ownership"
        element={<TasksOwnership />}
      />
      <Route
        path="/:projectId/:userId/tasks"
        element={
          <CalendarProvider>
            <TasksCalendar />
          </CalendarProvider>
        }
      />
      <Route
        path="/:projectId/:userId/tasks/manage"
        element={<TasksManage />}
      />
    </Routes>
  );
};
