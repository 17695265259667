import React from 'react';

export const EventsTabMenu = () => {
  return (
    <div className='task-tab-menu collapsible'>
      <nav className='tab-menu'>
        <div className='section-title selected'>Events</div>
      </nav>
    </div>
  );
};
