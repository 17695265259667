import { Route, Routes } from "react-router-dom";
import {SupervisorAssignableUsers,UserSupervisorOwnershipAssignment} from "features/supervisor-ownership";


export const SupervisorOwnership: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SupervisorAssignableUsers />} />
     <Route path="/user/:userId" element={<UserSupervisorOwnershipAssignment/>} />
    
    </Routes>
  );
};
