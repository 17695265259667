// Get the Gang ID of a supervisor in another project
// If the supervisor cannot be found in the 'other' project the 1st Supervisor's gang ID will be returned
// Created to solve OP2-58
function convertOldGangIdToNewGangId(
  queryProjectsAndGangsData: any,
  curProjectId: any,
  curUserId: any,
  gangs: any
) {
  let newGangID: any = 0;

  // Get the Current projectID as an integer
  const iprojectID = parseInt(curProjectId === undefined ? "0" : curProjectId);
  // Find the Current Project
  const selProject = queryProjectsAndGangsData?.find(
    (project: any) => project.Id === iprojectID
  );
  if (selProject !== null) {
    // Get the name of the Supervisor (based on curUserId)
    const curSupervisorName = selProject?.Gangs.find(
      (gang: any) => gang.Id === +curUserId!
    )?.Name;

    // Now try to find the same Supervisor (based on Name) in the supplied Gangs
    newGangID = gangs?.find(
      (g: any) => g.Name?.toLowerCase() === curSupervisorName?.toLowerCase()
    )?.Id;

    // If we couldn't find them - use the 1st gang in the list
    if (newGangID === undefined) {
      newGangID = gangs[0].Id;
    }
  }

  return newGangID;
}

export { convertOldGangIdToNewGangId };
