import { useContext } from 'react';
import { Button, EventRow, Loader } from 'core/components';
import { isAfter, parseISO } from 'date-fns';
import { useState } from 'react';
import { useGetAllAvailableEvents } from './hooks';
import { TasksContext } from 'core/context';
import isValidHex from 'core/utilities/isValidHex';

interface ProjectEventProps {
  selectedCells: Set<string>;
  isInspectionMode: boolean;
  enableInternalEvents?: boolean;
  projectId?: string;
}

const cellsHavePastDates = (selectedCells: Set<string>) => {
  if (selectedCells.size === 0) {
    return false;
  }

  for (const date of selectedCells) {
    const parsedDate = parseISO(date.split('T')[0]);
    const parsedToday = parseISO(new Date().toISOString().split('T')[0]);
    const isAfterToday = isAfter(parsedDate, parsedToday);

    if (!isAfterToday) {
      return true;
    }
  }
  return false;
};

const ProjectEvents = ({
  selectedCells,
  isInspectionMode,
  enableInternalEvents,
  projectId
}: ProjectEventProps) => {
  const events = useGetAllAvailableEvents();
  const [pageSize, setPageSize] = useState(3);
  const parsedProjectId = parseInt(projectId || '0');
  const taskCtx = useContext(TasksContext);
  const { selectedCell } = taskCtx;

  const handlePageSizeChange = () => {
    setPageSize((prevState) => prevState + 3);
  };

  const hasPastDates = cellsHavePastDates(selectedCells);

  const allActiveEvents = events.data
    ? events.data.filter(
        (event) => event.IsActive && event.ProjectId === parsedProjectId
      )
    : [];

  const selectedEvents = allActiveEvents.filter(
    (event) =>
      selectedCell &&
      selectedCell.Events?.find(
        (selectedEvent) => selectedEvent.EventId === event.Id
      )
  );

  const availableEvents = allActiveEvents.filter(
    (event) => enableInternalEvents && event.Type === 'Internal'
  );

  const sortedEvents = availableEvents.length
    ? availableEvents.sort((a, b) => (a.Rank || 0) - (b.Rank || 0))
    : [];
  const paginatedEvents = sortedEvents.filter(
    (_event, index) => index < pageSize
  );

  return (
    <>
      {!events.isFetching && !events.isError && (
        <div className='events-wrapper'>
          <div className='events'>
            {isInspectionMode && selectedCell ? (
              <>
                {selectedEvents.map((event) => (
                  <EventRow
                    event={event}
                    key={event.Id}
                    isInspectionMode={isInspectionMode}
                    color={
                      isValidHex(event.Colour?.trim() || '')
                        ? event.Colour
                        : '#333'
                    }
                  />
                ))}
                {!selectedCell.Events?.length ? (
                  <p className='empty empty-list'>
                    This operative has no events assigned.
                  </p>
                ) : null}
              </>
            ) : (
              <>
                {paginatedEvents.map((event) => (
                  <EventRow
                    event={event}
                    key={event.Id}
                    isInspectionMode={isInspectionMode}
                    hasPastDates={hasPastDates}
                    color={
                      isValidHex(event.Colour?.trim() || '')
                        ? event.Colour
                        : '#333'
                    }
                  />
                ))}
              </>
            )}
          </div>
          <div className='show-more-btn-wrapper'>
            {availableEvents.length ? (
              availableEvents.length >= 5 &&
              !(pageSize >= availableEvents.length) ? (
                <Button className='show-more' onClick={handlePageSizeChange}>
                  show more
                </Button>
              ) : null
            ) : (
              <p className='empty empty-list'>
                There are no Events to display.
              </p>
            )}
          </div>
        </div>
      )}
      {events.isFetching && <Loader />}
      {events.isError && (
        <p className='empty empty-list'>
          There was an error while loading events.
        </p>
      )}
    </>
  );
};

export default ProjectEvents;
