import React from "react";

type TasksTabMenuProps = {
  globalTaskTabVisible?: boolean;
  exceptionTaskTabVisible?: boolean;
};

export const TasksTabMenu = ({
  globalTaskTabVisible = true,
  exceptionTaskTabVisible = true,
}: TasksTabMenuProps) => {
  return (
    <div className="task-tab-menu collapsible">
      <nav className="tab-menu">
        <button
          data-bind="click: $.proxy(selectTaskType, null, App.Enums.ProjectTaskType.Project), css: { selected: selectedTaskType() === App.Enums.ProjectTaskType.Project }, text: projectTaskTabLabel()"
          className="selected"
        >
          Tasks
        </button>
        {globalTaskTabVisible && (
          <>
            <span>/</span>
            <button data-bind="click: $.proxy(selectTaskType, null, App.Enums.ProjectTaskType.Global), css: { selected: selectedTaskType() === App.Enums.ProjectTaskType.Global }">
              Global
            </button>
          </>
        )}
        {exceptionTaskTabVisible && (
          <>
            <span>/</span>
            <button data-bind="click: $.proxy(selectTaskType, null, App.Enums.ProjectTaskType.Exception), css: { selected: selectedTaskType() === App.Enums.ProjectTaskType.Exception }">
              Exception
            </button>
          </>
        )}
      </nav>
    </div>
  );
};
