import { postTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangId } from "api/opcs";
import { useQuery } from "react-query";
import { CalendarSearchModel } from "types";

const useGetCalendar = (searchModel: CalendarSearchModel, enabled = true) => {
  return useQuery(
    ["tasks", "calendar", searchModel],
    ({ signal }) =>
      postTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangId(
        searchModel.projectId,
        searchModel.gangId,
        searchModel.operativeDaysRequestModel!,
        {
          signal,
        }
      ),
    {
      select: (data) => data?.Data ?? {},
      enabled,
    }
  );
};

export default useGetCalendar;
