import {
  OperativeDaysModel,
  OperativeTaskAssignmentModel,
  ProjectTaskModel
} from 'api/model';
import classNames from 'classnames';
import { TasksContext } from 'core/context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

type ProjectTaskAssignmentsProps = {
  task: ProjectTaskModel;
  calendarData?: OperativeDaysModel;
  onRemoveProjectTaskAssignment?: (projectTaskAssignmentId: number) => void;
};

export const ProjectTaskAssignments: React.FC<ProjectTaskAssignmentsProps> = (
  props
) => {
  const { task, calendarData, onRemoveProjectTaskAssignment } = props;
  const taskCtx = useContext(TasksContext);

  const projectTaskAssignmentsByTaskId = useMemo(() => {
    const tasks = calendarData?.TaskAssignments?.filter(
      (taskAssignment) =>
        taskAssignment.TaskId === task.Id &&
        // OP2-60 Listing of tasks in Inspection Mode wasn't operative specific (or date specific)
        taskAssignment.OperativeAssignments?.find(
          (opAssignment) =>
            opAssignment.OperativeId === taskCtx?.selectedCell?.OperativeId &&
            opAssignment.AssignedOn === taskCtx?.selectedCell?.Date
        )
    );

    return tasks?.map((task) => ({
      ...task,
      OperativeAssignments: task.OperativeAssignments?.filter(
        ({ AssignedOn }) => AssignedOn === taskCtx?.selectedCell?.Date
      )
    }));
  }, [calendarData?.TaskAssignments, task, taskCtx?.selectedCell]);

  const handleRemoveTask = useCallback(
    (id: number) => {
      onRemoveProjectTaskAssignment?.(id);
    },
    [onRemoveProjectTaskAssignment]
  );

  const handleOtherOperativeAssignmentsLabel = useCallback(
    (operativeAssignments?: OperativeTaskAssignmentModel[]) => {
      if (!operativeAssignments) return null;
      const numberOfOperativeAssignments = operativeAssignments.length;

      const names = operativeAssignments.map(
        ({ OperativeName }) => OperativeName
      );

      if (numberOfOperativeAssignments < 3) {
        return names.join(', ');
      }

      if (numberOfOperativeAssignments >= 3) {
        const [first, second, ...rest] = names;

        return `${first}, ${second} and ${rest.length} other operatives`;
      }
    },
    []
  );

  const [
    recordsOfProjectTaskAssignmentsByTaskId,
    setRecordsOfProjectTaskAssignmentsByTaskId
  ] = useState<Record<number, boolean>>();

  useEffect(() => {
    if (projectTaskAssignmentsByTaskId) {
      const initialRecords = projectTaskAssignmentsByTaskId?.reduce<
        Record<number, boolean>
      >((acc, val) => {
        const taskId = val.TaskId as number;
        return {
          ...acc,
          [taskId]: false
        };
      }, {});
      setRecordsOfProjectTaskAssignmentsByTaskId(initialRecords);
    }
  }, [projectTaskAssignmentsByTaskId]);

  if (projectTaskAssignmentsByTaskId?.length === 0) return null;

  return (
    <div className='project-task-assignments'>
      {projectTaskAssignmentsByTaskId?.map((projectTaskAssignment, index) => {
        const taskId = projectTaskAssignment.TaskId as number;

        const operativeAssignmentsWithNoDuplicates =
          [...new Set(projectTaskAssignment?.OperativeAssignments)] ?? [];

        const operativeAssignmentsToRender =
          operativeAssignmentsWithNoDuplicates.filter(
            ({ OperativeId }) =>
              OperativeId !== taskCtx?.selectedCell?.OperativeId
          );

        return (
          <div
            key={index}
            className={classNames('assignment hierarchy-ctnr', {
              borrowed: false, // borrowedGangAssignment
              'diff-gang': false // differentGangAssignment
            })}
          >
            <div
              className={classNames('task-toolbar hierarchy-lvl-0', {
                'viewing-on-grid': false // on click aye
              })}
            >
              <span className='task-name'>
                <span className='bold'>Task </span>·{' '}
                <span className='bold'>{projectTaskAssignment?.Task}</span>
              </span>
              <div className='toolbar-section-right'>
                <span className='assigned-percentage'>
                  <span>Assigned </span>·{' '}
                  <span>
                    {
                      projectTaskAssignment?.OperativeAssignments?.[0]
                        .Percentage
                    }
                    %
                  </span>
                </span>
                {/* <button
                  className='view-on-grid-btn yellow'
                  data-bind='click: viewOnGrid'
                  title='Show on grid'
                ></button> */}
                <button
                  className='remove-assignment-btn yellow'
                  title='Remove assignment'
                  onClick={() => handleRemoveTask(projectTaskAssignment.Id!)}
                ></button>
              </div>
            </div>
            <div className='assigned-by info-row hierarchy-lvl-1'>
              <span>Assigned by </span>·{' '}
              <span>{projectTaskAssignment?.AssignedBy}</span>
            </div>
            <div
              style={{ display: 'none' }}
              className='assigned-days info-row hierarchy-lvl-1'
              data-bind='visible: showAssignedDays'
            >
              <span className='assigned-date-summary'>
                <span>Also assigned on </span>·{' '}
                <span data-bind='text: assignedOnSummary'>No other days</span>
              </span>
              <button
                className='days-visibility-btn expand-anim-btn'
                data-bind="click: toggleOtherAssignedDays, visible: canToggleOtherAssignedDays, css: { 'open': showingOtherAssignedDays }"
                style={{ display: 'none' }}
              ></button>
            </div>
            <div
              style={{ display: 'none' }}
              className='hierarchy-lvl-2'
              data-bind='visible: showingOtherAssignedDays'
            >
              {/* <!-- ko foreach: otherAssignedDays --><!-- /ko --> */}
            </div>
            <div
              className={`info-row hierarchy-lvl-1 ${
                operativeAssignmentsToRender?.length > 0
                  ? 'assigned-to-visible'
                  : 'assigned-to'
              }`}
              data-bind='visible: showAssignedTo'
            >
              <span className='assigned-to-summary'>
                <span>Also assigned to </span>·{' '}
                {/* <span data-bind="text: assignedToSummary">
                  No other operatives
                </span> */}
                <span>
                  {handleOtherOperativeAssignmentsLabel(
                    operativeAssignmentsToRender
                  )}
                </span>
              </span>
              <button
                className={classNames(
                  'other-ops-visibility-btn',
                  'expand-anim-btn',
                  {
                    'expand-anim-btn-visible':
                      operativeAssignmentsToRender?.length > 2,

                    'expand-anim-btn-reverse':
                      (recordsOfProjectTaskAssignmentsByTaskId &&
                        recordsOfProjectTaskAssignmentsByTaskId[taskId]) ||
                      false
                  }
                )}
                onClick={() => {
                  setRecordsOfProjectTaskAssignmentsByTaskId((records) => ({
                    ...records,
                    [taskId]: !!records && !records[taskId]
                  }));
                }}
              />
            </div>
            <div
              className={classNames(
                'hierarchy-lvl-2',
                'other-operative-assignments-expand-wrapper',
                {
                  'other-operative-assignments-expand-wrapper-visible':
                    (!!recordsOfProjectTaskAssignmentsByTaskId &&
                      typeof projectTaskAssignment?.TaskId === 'number' &&
                      recordsOfProjectTaskAssignmentsByTaskId[
                        projectTaskAssignment?.TaskId
                      ]) ||
                    false
                }
              )}
            >
              {operativeAssignmentsToRender?.map(
                ({ OperativeId, OperativeName }, idx) => {
                  return (
                    <span key={idx + '_' + OperativeId}>{OperativeName}</span>
                  );
                }
              )}
            </div>
            <div className='assigned-gang info-row hierarchy-lvl-1'>
              <span>Gang </span>· <span>{projectTaskAssignment?.Gang}</span>
            </div>
            {projectTaskAssignment?.HasDelay && (
              <div className='delay info-row hierarchy-lvl-1 with-columns'>
                <span className='delay-hours col col-40'>
                  <span>Delay hours </span>·{' '}
                  <span>{projectTaskAssignment.DelayInHoursPerOperative}</span>
                </span>
                <span className='delay-reason col'>
                  <span>Delay reason </span>·{' '}
                  <span>{projectTaskAssignment.DelayReason}</span>
                </span>
              </div>
            )}
            {projectTaskAssignment?.OperativeAssignments?.[0].ShiftType &&
              projectTaskAssignment?.OperativeAssignments?.[0]
                .TotalWorkedMinutes && (
                <div className='info-row hierarchy-lvl-1 shift-details with-columns'>
                  <span className='col col-40'>
                    <span>Shift type </span>·{' '}
                    <span>
                      {
                        projectTaskAssignment?.OperativeAssignments?.[0]
                          .ShiftType
                      }
                    </span>
                  </span>
                  <span className='col'>
                    <span>Time worked </span>·{' '}
                    <span>
                      {
                        projectTaskAssignment?.OperativeAssignments?.[0]
                          .TotalWorkedMinutes
                      }
                    </span>
                  </span>
                </div>
              )}

            {projectTaskAssignment?.Comment && (
              <div className='multiline with-columns comment info-row hierarchy-lvl-1'>
                <span className='comment-label col col-fixed-size'>
                  Comments <span className=''>· </span>
                </span>
                <p className='comment-text col col-fill'>
                  {projectTaskAssignment?.Comment}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
