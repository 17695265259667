import { BreadcrumbsCategory } from "core/components/Breadcrumbs";
import React, {useCallback} from "react";

export type BreadcrumbsContextType = {
  breadcrumbs: BreadcrumbsCategory[];
  updateBreadcrumbs: (breadcrumps: BreadcrumbsCategory[]) => void;
};

export const BreadcrumbsContext = React.createContext<BreadcrumbsContextType | null>(
  null
);

export const BreadcrumbsProvider: React.FC = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbsCategory[]>([]);

  const updateBreadcrumbs = useCallback((breadcrumbs: BreadcrumbsCategory[]) => {
    setBreadcrumbs(breadcrumbs);
  }, []);

  return (
    <BreadcrumbsContext.Provider
      value={{ breadcrumbs, updateBreadcrumbs }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};