import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import type {
  AssignmentModel,
  OperativeAssignmentModel,
  OperativeDayPercentageModel,
  ProjectTaskHierarchyModel,
  RemoveAssignmentOption
} from 'api/model';
import {
  usePostTaskAssignmentAssignmentSaveProjectIdGangId,
  usePostTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangId,
  usePostTaskAssignmentAssignmentRemoveProjectTaskAssignmentId,
  getTaskAssignmentTasksGetTaskNameTaskId
} from 'api/opcs';
import classNames from 'classnames';
import { WeekSelector } from 'core/components';
import { NotificationContext, TasksContext } from 'core/context';
import { NotificationType } from 'core/context/NotificationContext.types';
import { SettingsContext } from 'core/context/SettingsContext';
import { useUpdateBreadcrumbs } from 'core/hooks';
import { isEmpty } from 'rambda';
import { convertOldGangIdToNewGangId } from 'utils/convertOldGangIdToNewGangId';
import {
  AppAssignment,
  AssignmentCalendar,
  RemoveAssignmentsOptionsPanel,
  RemovePanelOptions,
  TasksTabMenu,
  EventsTabMenu,
  ProjectEvents
} from './components';
import { ProjectTasks } from './components/ProjectTasks';
import type { ProjectTaskHierarchyModelWithShouldShown } from 'types';
import {
  useGetCalendar,
  useGetProjectsAndGangs,
  useGetGangsAssignedTime,
  useGetAssignedProjectTasks
} from './components/hooks';
import { useCalendarContext } from 'core/context/CalendarContext';

export const TasksCalendar = () => {
  const { searchParams, setSearchParams, projectId, userId, dateNowUtc } =
    useCalendarContext();
  const [showContent, setShowContent] = useState(true);
  const [operativeAssignments, setOperativeAssignments] = useState<
    OperativeAssignmentModel[]
  >([]);
  const [selectedCells, setSelectedCells] = useState<Set<string>>(new Set());

  const [gangsAssignedTimeQueryParams, setGangsAssignedTimeQueryParams] =
    useState({
      projectId: +projectId!,
      gangAssignedRequestModel: {
        StartOfWeek: dateNowUtc
      }
    });
  const [dayBreakdowns, setDayBreakdowns] = useState<
    OperativeDayPercentageModel[]
  >([]);
  let navigate = useNavigate();
  const queryProjectsAndGangs = useGetProjectsAndGangs();
  const queryAssignedProjectTasks = useGetAssignedProjectTasks(searchParams);
  const query = useGetCalendar(searchParams);
  const { mutate } = usePostTaskAssignmentAssignmentSaveProjectIdGangId();
  const breakdownMutation =
    usePostTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangId();
  const removeProjectTaskAssignmentMutation =
    usePostTaskAssignmentAssignmentRemoveProjectTaskAssignmentId();
  const queryGangsAssignedTime = useGetGangsAssignedTime(
    gangsAssignedTimeQueryParams
  );

  const taskCtx = useContext(TasksContext);
  const settingsCtx = useContext(SettingsContext);
  const notificationCtx = useContext(NotificationContext);
  const [percentageSpare, setPercentageSpare] = useState<number | undefined>();
  const [removeMode, setRemoveMode] = useState<RemovePanelOptions>();
  const [startDay, SetStartDay] = useState<any | undefined>(0);

  const isInspectionMode = useMemo(() => {
    return taskCtx?.inspectionMode?.general || taskCtx?.inspectionMode?.byTask;
  }, [taskCtx?.inspectionMode]);

  const isInspectionModeWithCell = useMemo(() => {
    return (
      (taskCtx?.inspectionMode?.general || taskCtx?.inspectionMode?.byTask) &&
      taskCtx?.inspectionMode?.withCell
    );
  }, [taskCtx?.inspectionMode]);

  const headerHelpTextLabel = useMemo(() => {
    if (
      !taskCtx?.inspectionMode?.byTask &&
      taskCtx?.inspectionMode?.general &&
      taskCtx?.inspectionMode?.withCell
    ) {
      return 'Scroll down to view assignments';
    }

    if (taskCtx?.inspectionMode?.byTask && taskCtx?.taskName) {
      return `Percentages for - ${taskCtx?.taskName}`;
    }

    if (taskCtx?.inspectionMode?.general) {
      return 'View tasks assigned to operatives';
    }

    if (taskCtx?.inspectionMode?.withCell) {
      return 'Scroll down to find a task to assign';
    }

    return 'Tap cells in the grid to choose operative days';
  }, [taskCtx]);

  const assignOperativesButtonLabel = useMemo(
    () =>
      taskCtx?.inspectionMode?.withCell && isInspectionMode
        ? 'Back'
        : (isInspectionMode || taskCtx?.inspectionMode?.withCell) && 'Cancel',
    [taskCtx, isInspectionMode]
  );

  const hasOperativesAssignments = operativeAssignments.length > 0;

  const settings = useMemo(() => {
    return settingsCtx?.getSettingsById?.(+projectId!);
    // eslint-disable-next-line
  }, [settingsCtx?.getSettingsById, projectId]);

  const mappedProjectTaskData = useMemo(() => {
    if (taskCtx?.selectedCell && isInspectionMode) {
      const assignmentsIds = taskCtx?.selectedCell?.Assignments?.map(
        (assignment) => assignment.ProjectTaskAssignmentId
      );

      const taskAssignments = query.data?.TaskAssignments?.filter(
        (taskAssignment) => assignmentsIds?.includes(taskAssignment.Id)
      ).map((taskAssignment) => taskAssignment.TaskId);

      const mappedWithShouldShownRecursive = (
        hierarchy: ProjectTaskHierarchyModel
      ): ProjectTaskHierarchyModelWithShouldShown => {
        const children =
          hierarchy.ChildHierarchies?.map<ProjectTaskHierarchyModelWithShouldShown>(
            (item) => {
              return mappedWithShouldShownRecursive(item);
            }
          );

        const shouldShown =
          hierarchy.ProjectTasks?.some(({ Id }) => {
            const isMatched = taskAssignments?.includes(Id);

            return isMatched;
          }) || false;

        return {
          ...hierarchy,
          ChildHierarchies: children as [],
          shouldShown
        };
      };

      const mappedWithShouldShownFlag = query.data?.TaskHierarchyTree?.map(
        (hierachy) => {
          return mappedWithShouldShownRecursive(hierachy);
        }
      );

      const markShouldShownFlag = (
        hierarchy: ProjectTaskHierarchyModelWithShouldShown
      ) => {
        if (hierarchy?.shouldShown) {
          return true;
        }
        if (hierarchy?.ChildHierarchies) {
          let show = false;
          hierarchy.ChildHierarchies.forEach((hierarchy) => {
            if (markShouldShownFlag(hierarchy as any)) {
              show = true;
            }
          });

          hierarchy.shouldShown = show;

          return show;
        }
      };

      function markShouldShowFlagRoot(
        hierarchies: ProjectTaskHierarchyModelWithShouldShown[]
      ) {
        return hierarchies?.forEach((hierarchy) => {
          markShouldShownFlag(hierarchy);
        });
      }

      mappedWithShouldShownFlag &&
        markShouldShowFlagRoot(mappedWithShouldShownFlag);

      const filterElementsToBeShown = (
        hierarchy: ProjectTaskHierarchyModelWithShouldShown
      ): ProjectTaskHierarchyModelWithShouldShown => {
        if (hierarchy?.ChildHierarchies?.length === 0) {
          return hierarchy.shouldShown
            ? {
                ...hierarchy,
                ChildHierarchies: []
              }
            : undefined;
        }

        const childrenToBeShown = hierarchy?.ChildHierarchies?.map(
          filterElementsToBeShown
        ).filter(Boolean);

        if (childrenToBeShown?.length === 0) {
          return undefined;
        }

        return {
          ...hierarchy,
          ChildHierarchies: childrenToBeShown as []
        };
      };

      const filterElementsToBeShownRoot = (
        hierarchies: ProjectTaskHierarchyModelWithShouldShown[]
      ): ProjectTaskHierarchyModelWithShouldShown[] => {
        const resultsToBeShown = hierarchies
          .map(filterElementsToBeShown)
          .filter(Boolean);

        return resultsToBeShown;
      };

      const resultsToBeShown =
        mappedWithShouldShownFlag &&
        filterElementsToBeShownRoot(mappedWithShouldShownFlag);

      return resultsToBeShown;
    }

    return queryAssignedProjectTasks.data;
  }, [
    queryAssignedProjectTasks.data,
    query.data?.TaskHierarchyTree,
    query?.data?.TaskAssignments,
    taskCtx,
    isInspectionMode
  ]);

  const getPercentageAssignedTimeById = useCallback(
    (id: number) => {
      const result = queryGangsAssignedTime.data?.find(
        (gang: any) => gang.GangId === id
      );
      if (result === undefined) return null;

      return result?.PercentageAssigned?.toFixed(2) ?? '0.00';
    },
    [queryGangsAssignedTime.data]
  );

  // Project Listing in Breadcrumb
  const getProjectsBreadcrumb = useCallback(() => {
    return queryProjectsAndGangs.status === 'success'
      ? queryProjectsAndGangs.data.map((project: any) => {
          // OP2-58: Map the current gangID into the project's gangID
          let newGangId: string | undefined = convertOldGangIdToNewGangId(
            queryProjectsAndGangs.data,
            projectId,
            userId,
            project.Gangs
          );
          return {
            name: project.Name,
            path: `/tasks/${project.Id}/${newGangId}/tasks`,
            selected: project.Id === +projectId!
          };
        })
      : [
          {
            name: 'NO PROJECTS ASSIGNED',
            path: '#',
            selected: true
          }
        ];
  }, [
    queryProjectsAndGangs.status,
    queryProjectsAndGangs.data,
    projectId,
    userId
  ]);

  // Supervisor list in Breadcrumb
  const getProjectUsersBreadcrumb = useCallback(() => {
    if (queryProjectsAndGangs.status === 'success') {
      // OP2-58: Don't use Selected attribute, use the projectID
      const selectedProject = queryProjectsAndGangs.data.find(
        (project: any) => project.Id === +projectId!
      );
      // OP2-68: Output Supervisor name only if getPercentageAssignedTimeById returns null
      return [
        {
          name: 'All Gangs',
          path: `/tasks/${projectId}/all/tasks`,
          selected: userId === 'all'
        },
        ...selectedProject?.Gangs.map((gang: any, idx: number) => ({
          // name: `${gang.Name} (${getPercentageAssignedTimeById(gang.Id)}%)`,
          name:
            getPercentageAssignedTimeById(gang.Id) === null
              ? `${gang.Name}`
              : `${gang.Name} (${getPercentageAssignedTimeById(gang.Id)}%)`,
          path: `/tasks/${projectId}/${gang.Id}/tasks`,
          selected: gang.Id === +userId!,
          deactivated: gang.Deactivated
        }))
      ];
    }
    return [
      {
        name: 'NO AVAILABLE PROJECTS',
        path: '#',
        selected: true
      }
    ];
  }, [
    queryProjectsAndGangs.status,
    queryProjectsAndGangs.data,
    projectId,
    userId,
    getPercentageAssignedTimeById
  ]);

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments'
            },
            {
              name: 'Project settings',
              path: '/project-settings'
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership'
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: true
            }
          ]
        },
        {
          withSearch: false,
          items: getProjectsBreadcrumb()
        },
        {
          withSearch: true,
          items: getProjectUsersBreadcrumb()
        },
        {
          withSearch: false,
          items: [
            {
              name: 'Tasks',
              path: '#',
              selected: true
            },
            {
              name: 'Operatives',
              path: `/tasks/${projectId}/${userId}/operatives`
            },
            {
              name: 'Ownership',
              path: `/tasks/${projectId}/${userId}/ownership`
            }
          ]
        }
      ],
      [getProjectsBreadcrumb, getProjectUsersBreadcrumb, projectId, userId]
    )
  );

  const handleChangeWeek = useCallback(
    (week: string) => {
      setSearchParams((current) => ({
        ...current,
        operativeDaysRequestModel: {
          ...current.operativeDaysRequestModel,
          StartOfWeek: week
        }
      }));
      setGangsAssignedTimeQueryParams((curr) => ({
        ...curr,
        gangAssignedRequestModel: {
          StartOfWeek: week
        }
      }));
    },
    [setSearchParams]
  );

  const handleAddOperative = useCallback(() => {
    taskCtx?.setOwnershipBackButtonEnabled(true);
    navigate(`/tasks/${projectId}/${userId}/ownership`);
  }, [projectId, userId, navigate, taskCtx]);

  const handleClickManageTasks = useCallback(() => {
    navigate(`/tasks/${projectId}/${userId}/tasks/manage`, {
      state: { operativeAssignments, selectedCells }
    });
  }, [projectId, userId, operativeAssignments, selectedCells, navigate]);

  const handleExpandAppAssignment = useCallback(() => {
    setShowContent((curr) => !curr);
  }, [setShowContent]);

  const handleCloseAppAssignment = useCallback(() => {
    setPercentageSpare(undefined);
    setShowContent(true);
    taskCtx?.clearTaskId();
    taskCtx?.clearTaskName();
  }, [taskCtx, setShowContent]);

  const handleIncludeProjectTaskInTaskList = useCallback(
    (id: number) => {
      if (
        (taskCtx?.inspectionMode?.general ||
          taskCtx?.inspectionMode?.general) &&
        taskCtx?.inspectionMode?.withCell
      ) {
        taskCtx.clearTaskId();
        return;
      }
      taskCtx?.updateTaskId(id);
      // Get the name of the Task and store into taskCtx (OP2-59)
      getTaskAssignmentTasksGetTaskNameTaskId(id).then((s) => {
        const ss = s.Data === undefined ? '' : s.Data;
        taskCtx?.updateTaskName(ss);
      });
    },
    [taskCtx]
  );

  const handleSelectCell = useCallback(
    (
      selectedCells: Set<string>,
      operativeAssignments: OperativeAssignmentModel[]
    ) => {
      setOperativeAssignments(operativeAssignments);
      setSelectedCells(selectedCells);
    },
    [setOperativeAssignments]
  );

  //OP2 -94 Added Logic to handle Dealy Reason validation
  //OP2 - 95 It allows for delay hours to be entered wothout entering mandatory delay reason

  const validAssignment = useCallback(
    (data: AssignmentModel) => {
      const { DelayOrComments, OperativeAssignments } = data;
      if (DelayOrComments && DelayOrComments.HoursDelayed! > 0) {
        if (!DelayOrComments.DelayReasonId || !DelayOrComments.Comment) {
          notificationCtx?.addNotification({
            Type: NotificationType.Information,
            Text: 'You are required to select delay Reason and Comments'
          });

          return false;
        }
      }

      return OperativeAssignments?.length;
      //&& !DelayOrComments
      //  (DelayOrComments || DelayOrComments.HoursDelayed! > 0 && DelayOrComments.DelayReasonId ))
      //     DelayOrComments.DelayReasonId &&
      //     DelayOrComments.HoursDelayed &&
      //     DelayOrComments.HoursDelayed > 0))
      // true
    },
    [notificationCtx]
  );

  const handleSaveAssignment = useCallback(
    (data: AssignmentModel) => {
      if (validAssignment(data)) {
        mutate(
          {
            projectId: +projectId!,
            gangId: +userId!,
            data: { ...data, TaskId: taskCtx?.taskId }
          },
          {
            onSuccess: (data) => {
              if (data?.Data?.PercentageSpare >= 0) {
                setPercentageSpare(data?.Data?.PercentageSpare);
                return;
              }
              handleCloseAppAssignment();
              taskCtx?.clearTaskId();
              taskCtx?.clearTaskName();
              taskCtx?.clearSelectedTasks();
              taskCtx?.clearSelectedDetailedCells();
              query.refetch();
              queryAssignedProjectTasks.refetch();
              queryGangsAssignedTime.refetch();
            }
          }
        );
      }
    },
    [
      taskCtx,
      projectId,
      userId,
      query,
      queryAssignedProjectTasks,
      queryGangsAssignedTime,
      handleCloseAppAssignment,
      validAssignment,
      mutate
    ]
  );

  const handleExpandTask = useCallback(
    (id: number | null) => {
      if (!id) {
        setDayBreakdowns([]);
        taskCtx?.updateInspectionMode({
          ...taskCtx.inspectionMode,
          byTask: false
        });
        taskCtx?.clearTaskId();
        return;
      }

      breakdownMutation.mutate(
        {
          projectId: +projectId!,
          gangId: +userId!,
          data: {
            ProjectTaskId: id,
            DateInWeek: dateNowUtc
          }
        },
        {
          onSuccess: (data) => {
            setDayBreakdowns(data?.Data!);
          }
        }
      );

      taskCtx?.updateTaskId(id);
    },
    [
      breakdownMutation,
      projectId,
      userId,
      dateNowUtc,
      taskCtx,
      setDayBreakdowns
    ]
  );

  const handleRemoveProjectTaskAssignmentMutation = useCallback(
    (
      projectTaskAssignmentId: number,
      operativeId: number,
      day: string,
      option?: RemoveAssignmentOption
    ) => {
      removeProjectTaskAssignmentMutation.mutate(
        {
          projectTaskAssignmentId,
          data: {
            OperativeId: operativeId,
            Day: day,
            Option: option
          }
        },
        {
          onSuccess: () => {
            query.refetch();
            queryAssignedProjectTasks.refetch();
            if (
              taskCtx?.selectedCell?.Assignments?.length &&
              taskCtx?.selectedCell?.Assignments?.length > 1
            ) {
              taskCtx?.removeAssignmentFormSelectedCell(
                projectTaskAssignmentId
              );

              //OP2-93  window does not disapear when user tries to delete more than one operative.
              setRemoveMode?.(undefined);
            } else {
              taskCtx?.clearSelectedCell();
              taskCtx?.clearSelectedTasks();
              taskCtx?.clearSelectedDetailedCells();
              taskCtx?.clearTaskId();
              taskCtx?.clearTaskName();
              setRemoveMode?.(undefined);
            }
          }
        }
      );
    },
    [
      query,
      queryAssignedProjectTasks,
      taskCtx,
      removeProjectTaskAssignmentMutation
    ]
  );

  const handleRemoveProjectTaskAssignment = useCallback(
    (projectTaskAssignmentId: number) => {
      const operativeAssignments = query.data?.TaskAssignments?.find(
        (taskAssignment) => taskAssignment.Id === projectTaskAssignmentId
      )?.OperativeAssignments;

      const operativeAssignmentsByDate = operativeAssignments?.filter(
        (operativeAssignments) =>
          operativeAssignments.AssignedOn === taskCtx?.selectedCell?.Date
      );

      const operativeAssignmentsByOperativeId = operativeAssignments?.filter(
        (operativeAssignments) =>
          operativeAssignments.OperativeId ===
          taskCtx?.selectedCell?.OperativeId
      );

      const operativeName = operativeAssignments?.find(
        (operativeAssignment) =>
          operativeAssignment.OperativeId === taskCtx?.selectedCell?.OperativeId
      )?.OperativeName;

      const hasDeletionByColumn =
        operativeAssignmentsByDate && operativeAssignmentsByDate.length > 1;
      const hasDeletionByRow =
        operativeAssignmentsByOperativeId &&
        operativeAssignmentsByOperativeId.length > 1;
      const hasDeletionAll = !!operativeAssignments?.find(
        (operativeAssignment) =>
          operativeAssignment.OperativeId !==
            taskCtx?.selectedCell?.OperativeId &&
          operativeAssignment.AssignedOn !== taskCtx?.selectedCell?.Date
      );

      if (hasDeletionByColumn || hasDeletionByRow || hasDeletionAll) {
        setRemoveMode({
          operativeName,
          projectTaskAssignmentId,
          hasDeletionByColumn,
          hasDeletionByRow,
          hasDeletionAll,
          columnDate: taskCtx?.selectedCell?.Date,
          operativeId: taskCtx?.selectedCell?.OperativeId
        });
        return;
      }

      handleRemoveProjectTaskAssignmentMutation(
        projectTaskAssignmentId,
        taskCtx?.selectedCell?.OperativeId!,
        taskCtx?.selectedCell?.Date!,
        0
      );
    },
    // eslint-disable-next-line
    [
      query,
      queryAssignedProjectTasks,
      taskCtx?.selectedCell,
      taskCtx?.removeAssignmentFormSelectedCell,
      handleRemoveProjectTaskAssignmentMutation
    ]
  );

  const toggleAssignOperativesMode = useCallback(() => {
    if (isInspectionMode || taskCtx?.inspectionMode?.withCell) {
      setDayBreakdowns([]);

      if (taskCtx?.inspectionMode?.withCell) {
        taskCtx?.updateInspectionMode({
          ...taskCtx.inspectionMode,
          withCell: false
        });
        taskCtx.clearSelectedTasks();
        taskCtx.clearSelectedCell();
        taskCtx.clearSelectedDetailedCells();
        return;
      }

      taskCtx?.clearTaskId();
      taskCtx?.clearTaskName();

      if (taskCtx?.inspectionMode?.byTask) {
        taskCtx?.updateInspectionMode({
          ...taskCtx.inspectionMode,
          byTask: false
        });
        return;
      }

      taskCtx?.updateInspectionMode({});
    } else {
      taskCtx?.updateInspectionMode({
        general: true
      });
    }
  }, [taskCtx, isInspectionMode]);

  // OP2-70: Ensure that the projectID is updated (so that the Supervisor dropdown includes percentages)
  useEffect(() => {
    if (+projectId! !== gangsAssignedTimeQueryParams.projectId) {
      setGangsAssignedTimeQueryParams({
        projectId: +projectId!,
        gangAssignedRequestModel: {
          StartOfWeek: dateNowUtc
        }
      });
    }
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    if (typeof +userId! !== 'number' || isNaN(+userId!)) {
      navigate(`/tasks/${projectId}/${userId}/operatives`);
      return;
    }

    setSearchParams((current) => ({
      ...current,
      projectId: +projectId!,
      gangId: +userId!
    }));
  }, [projectId, userId, navigate, setSearchParams]);

  useEffect(() => {
    if (isEmpty(Array.from(taskCtx?.selectedTasks!))) {
      taskCtx?.updateInspectionMode({
        ...taskCtx.inspectionMode,
        withCell: false
      });
    } else {
      taskCtx?.updateInspectionMode({
        ...taskCtx.inspectionMode,
        withCell: true
      });
    }
    // eslint-disable-next-line
  }, [
    taskCtx?.selectedTasks,
    taskCtx?.clearTaskId,
    taskCtx?.clearTaskName,
    taskCtx?.updateInspectionMode
  ]);

  useEffect(() => {
    SetStartDay(settings?.WeekCommencesOn);
  }, [settings?.WeekCommencesOn]);

  return (
    <>
      <section
        className='task-assignment-content'
        style={{ display: showContent ? 'block' : 'none' }}
      >
        <h2 className='visually-hidden'>Operative days</h2>
        <div className='operative-days'>
          <header className='operative-days-header'>
            <WeekSelector
              weekStartsOn={startDay}
              onChangeStartDayOfWeek={handleChangeWeek}
            />
            <span
              className={classNames('button-help-text main-help-text visible', {
                'with-cancel-button': isInspectionMode
              })}
            >
              <span className='spacer'></span>
              <span
                className={classNames('header-mode', {
                  inspector: isInspectionMode
                })}
              >
                {isInspectionMode ? 'Inspection' : 'Assignment'}
              </span>
              <span className='spacer'></span>
              <span>{headerHelpTextLabel}</span>
            </span>
            <div className='buttons-ctnr inspector'>
              <button
                className={classNames('assign-operatives large', {
                  'white-with-border':
                    isInspectionMode || taskCtx?.inspectionMode?.withCell,
                  yellow: !isInspectionMode
                })}
                title='View tasks assigned to operatives'
                onClick={toggleAssignOperativesMode}
                data-bind="click: toggleAssignOperativesMode, visible: toggleAssignOperativesVisible, css: { 'white-with-border': !operativeDaysContext.isTaskAssignmentModeActive() || isShowingOperativeDayPercentages() || hasSelectedOperatives(), yellow: operativeDaysContext.isTaskAssignmentModeActive() &amp;&amp; !hasSelectedOperatives() }, text: assignOperativesButtonLabel"
              >
                {assignOperativesButtonLabel}
              </button>
            </div>
          </header>
        </div>
        <section>
          <AssignmentCalendar
            data={query.status === 'success' ? query.data : {}}
            dayBreakdowns={dayBreakdowns}
            isLoading={query.status === 'loading'}
            week={searchParams.operativeDaysRequestModel?.StartOfWeek}
            onAddOperative={handleAddOperative}
            onSelectCell={handleSelectCell}
          />
        </section>
        {settings?.EventsEnabled && (
          <section className='events-tab-section'>
            <header>
              <EventsTabMenu />

              <ProjectEvents
                selectedCells={selectedCells}
                isInspectionMode={isInspectionMode || false}
                enableInternalEvents={settings?.EventsInternalEnabled}
                projectId={projectId || '0'}
              />
            </header>
          </section>
        )}

        <section>
          <header>
            <TasksTabMenu
              globalTaskTabVisible={!settings?.HideGlobalTaskTab}
              exceptionTaskTabVisible={!settings?.HideExceptionTaskTab}
            />
            <button
              className='search-tasks with-icon with-icon--search large yellow'
              title='Manage tasks'
              onClick={handleClickManageTasks}
            >
              {hasOperativesAssignments ? 'Other tasks' : 'Manage tasks'}
            </button>
          </header>
          <div
            className={classNames(
              'project-tasks relative-container block search-task-list',
              {
                'project-tasks': !isInspectionModeWithCell,
                'assignments-overview': isInspectionModeWithCell
              }
            )}
          >
            <ProjectTasks
              projectId={+projectId!}
              gangId={+userId!}
              data={mappedProjectTaskData as ProjectTaskHierarchyModel[]}
              // isLoading={queryAssignedProjectTasks.status === "loading"}
              isLoading={queryAssignedProjectTasks.isFetching}
              hasSelectedOperatives={!isEmpty(operativeAssignments)}
              handleIncludeProjectTaskInTaskList={
                handleIncludeProjectTaskInTaskList
              }
              handleExpandTask={handleExpandTask}
              forceShowProjectTasks={true}
              calendarData={query.data}
              onRemoveProjectTaskAssignment={handleRemoveProjectTaskAssignment}
            />
          </div>
        </section>
      </section>
      <section>
        {taskCtx?.taskId && hasOperativesAssignments && !isInspectionMode && (
          <AppAssignment
            operativeAssignments={operativeAssignments}
            percentageSpare={percentageSpare}
            onExpand={handleExpandAppAssignment}
            onClose={handleCloseAppAssignment}
            onSave={handleSaveAssignment}
            taskName={taskCtx?.taskName}
          />
        )}
        {removeMode &&
          (removeMode.hasDeletionAll ||
            removeMode.hasDeletionByColumn ||
            removeMode.hasDeletionByRow) && (
            <RemoveAssignmentsOptionsPanel
              removePanelOptions={removeMode}
              isLoading={removeProjectTaskAssignmentMutation.isLoading}
              onRemove={(removePanelOptions, option) => {
                handleRemoveProjectTaskAssignmentMutation(
                  removePanelOptions?.projectTaskAssignmentId!,
                  removePanelOptions?.operativeId!,
                  removePanelOptions?.columnDate!,
                  option
                );
              }}
              onCancel={() => setRemoveMode(undefined)}
            />
          )}
      </section>
    </>
  );
};
