import { RemoveAssignmentOption } from "api/model";
import { Loader } from "core/components";
import { format } from "date-fns";
import React, { useMemo } from "react";

export type RemovePanelOptions = {
  operativeName?: string;
  operativeId?: number;
  columnDate?: string;
  projectTaskAssignmentId?: number;
  hasDeletionByColumn?: boolean;
  hasDeletionByRow?: boolean;
  hasDeletionAll?: boolean;
};

type RemoveAssignmentsOptionsPanelProps = {
  removePanelOptions?: RemovePanelOptions;
  isLoading?: boolean;

  onRemove?: (
    removePanelOptions?: RemovePanelOptions,
    option?: RemoveAssignmentOption
  ) => void;
  onCancel?: () => void;
};

export const RemoveAssignmentsOptionsPanel: React.FC<
  RemoveAssignmentsOptionsPanelProps
> = ({
  removePanelOptions,
  isLoading,
  onRemove,
  onCancel,
}: RemoveAssignmentsOptionsPanelProps) => {
  const date = useMemo(
    () =>
      removePanelOptions?.columnDate &&
      format(new Date(removePanelOptions?.columnDate), "dd/MM/yyyy"),
    [removePanelOptions?.columnDate]
  );

  return (
    <div className="remove-assignment-options-panel">
      <section className="assignment-removal-options-panel docked-panel open">
        <button
          className="yellow large stacked-button"
          onClick={() => onRemove?.(removePanelOptions, 0)}
        >
          Only for {removePanelOptions?.operativeName} on {date}
        </button>

        {removePanelOptions?.hasDeletionByColumn && (
          <button
            className="yellow large stacked-button"
            onClick={() => onRemove?.(removePanelOptions, 1)}
          >
            All operatives on {date}
          </button>
        )}

        {removePanelOptions?.hasDeletionByRow && (
          <button
            className="yellow large stacked-button"
            onClick={() => onRemove?.(removePanelOptions, 2)}
          >
            Only for {removePanelOptions?.operativeName} on all days
          </button>
        )}

        {removePanelOptions?.hasDeletionAll && (
          <button
            className="yellow large stacked-button"
            onClick={() => onRemove?.(removePanelOptions, 3)}
          >
            All operatives on all days
          </button>
        )}

        <button className="white-with-border stacked-button" onClick={onCancel}>
          Cancel
        </button>
        {isLoading && <Loader className="pinned translucent" />}
      </section>
    </div>
  );
};
