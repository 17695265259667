import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

export function SignOut() {
  const { instance } = useMsal();

  useEffect(() => {
    async function logout() {
      await instance.handleRedirectPromise();

      const accounts = instance.getAllAccounts();
      if (accounts.length >= 0) {
        instance.logoutRedirect();
      }
    }

    logout();
  }, [instance]);

  return <>Signing you out...</>;
}
