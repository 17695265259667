import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@mantine/hooks';
import { useGetSupervisorOwnershipSuperusersGetUsers } from 'api/opcs';
import { UserListing } from './components';
import { useUpdateBreadcrumbs } from 'core/hooks';

const useUsers = () => {
  return useGetSupervisorOwnershipSuperusersGetUsers({
    query: {
      select: (data) => data.Data ?? []
    }
  });
};

export const SupervisorAssignableUsers: React.FC = (props) => {
  useDocumentTitle('Supervisor Ownership | OPCS');
  const query = useUsers();

  const navigate = useNavigate();
  const handleRowClicked = useCallback(
    (userId: number) => {
      navigate(`user/${userId}`);
    },
    [navigate]
  );

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments',
              selected: false
            },
            {
              name: 'Project settings',
              path: '/project-settings',
              selected: false
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership',
              selected: true
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: false
            }
          ]
        }
      ],
      []
    )
  );

  return (
    <div className='project-assignment-content'>
      <section className='project-assignable-users-content'>
        <h1 className='visually-hidden'>Project assignable users</h1>
        <div>
          <section>
            <h1 className='visually-hidden'>List of users</h1>
            <UserListing
              users={query.status === 'success' ? query.data : []}
              isLoading={query.status === 'loading'}
              onRowClicked={handleRowClicked}
            />
          </section>
        </div>
      </section>
    </div>
  );
};
