import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { compose, Lens, lensIndex, lensProp, set, view } from 'rambda';
import { useDocumentTitle } from '@mantine/hooks';
import { Button } from 'core/components';
import { FilterBar } from './components';

import {
  getGetSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserIdQueryKey,
  useGetSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserId,
  usePutSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserId,
  useGetSupervisorOwnershipSuperusersGetUsers
} from 'api/opcs';
import type {
  SuperUserSupervisorOwnershipCollectionModel,
  SuperUserSupervisorOwnershipModel
} from 'api/model';
import { UserSupervisorListing } from './components/UserSupervisorListing';
import { useUpdateBreadcrumbs } from 'core/hooks';

const usePutAssignments = (
  userId: number,
  assignments?: SuperUserSupervisorOwnershipCollectionModel
) => {
  const [newAssignments, setNewAssignments] = useState(
    assignments?.Ownerships ?? []
  );

  useEffect(() => {
    if (assignments) {
      setNewAssignments(assignments.Ownerships);
    }
  }, [assignments]);

  const handleCheckboxChange = useCallback((assignmentIdx: number) => {
    setNewAssignments((old) => {
      const lens = compose(
        lensIndex(assignmentIdx),
        lensProp('IsOwned')
      ) as Lens;

      const isOwned = view(lens, old);
      return set(lens, !Boolean(isOwned), old);
    });
  }, []);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: mutateAssignments, isLoading: isSaving } =
    usePutSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserId(
      {
        mutation: {
          onSettled: () => {
            queryClient.invalidateQueries(
              getGetSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserIdQueryKey(
                userId
              )
            );
          },
          onSuccess: () => {
            navigate(-1);
          }
        }
      }
    );

  const handleSaveClick = useCallback(() => {
    mutateAssignments({
      userId: userId,
      data: {
        Ownerships: newAssignments
      }
    });
  }, [newAssignments, mutateAssignments, userId]);

  return { handleCheckboxChange, handleSaveClick, isSaving };
};

const useSupervisorsWithSearch = (userId: number, searchQuery: string) => {
  const byNameAndPayrollID = (
    user: SuperUserSupervisorOwnershipModel
  ): boolean => {
    return (
      user.FullName?.toLowerCase().includes(searchQuery) ||
      user.PayrollId?.toLowerCase().includes(searchQuery) ||
      false
    );
  };

  return useGetSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserId(
    userId,
    {
      query: {
        select: (data) => data.Data?.Ownerships.filter(byNameAndPayrollID) ?? []
      }
    }
  );
};

export const UserSupervisorOwnershipAssignment: React.FC = () => {
  useDocumentTitle('Ownership assignments | OPCS');

  const { userId } = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const assignmentsQuery = useSupervisorsWithSearch(
    +userId!,
    searchQuery.toLowerCase()
  );

  const assignmentType: 'superuser' | 'supervisor' = useMemo(() => {
    if (!assignmentsQuery.data) {
      return 'supervisor';
    }

    const hasSuperuser = assignmentsQuery.data.some((x) => x.SuperUserId === 1);
    if (hasSuperuser) {
      return 'superuser';
    }

    return 'supervisor';
  }, [assignmentsQuery.data]);

  const navigate = useNavigate();
  const handleCancelClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const { handleCheckboxChange, handleSaveClick, isSaving } = usePutAssignments(
    +userId!,
    useMemo(
      () => ({ Ownerships: assignmentsQuery.data ?? [] }),
      [assignmentsQuery.data]
    )
  );

  const userQuery = useGetSupervisorOwnershipSuperusersGetUsers({
    query: {
      select: (data) =>
        data.Data?.filter((user) => user.Id === +userId!)[0] ?? {}
    }
  });

  const userName = useMemo(() => {
    return `${userQuery.data?.FullName} (${userQuery.data?.ActiveDirectoryUsername})`;
  }, [userQuery]);

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments',
              selected: false
            },
            {
              name: 'Project settings',
              path: '/project-settings',
              selected: false
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership',
              selected: true
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: false
            }
          ]
        },

        {
          withSearch: false,
          items: [
            {
              name: userName!,
              path: '#',
              selected: true
            }
          ]
        }
      ],
      [userName]
    )
  );

  return (
    <div className='supervisor-ownership-content'>
      <section className='superuser-supervisor-ownership-content'>
        <h1 className='visually-hidden'>Supervisor ownership</h1>
        <header className='ownership-header'>
          <section className='user-filter-panel'>
            <FilterBar onQueryChanged={setSearchQuery} />
          </section>

          <Button
            colour='yellow'
            large
            className='action-btn'
            onClick={handleSaveClick}
          >
            {isSaving ? 'Saving' : 'Save'}
          </Button>
          <Button
            colour='white'
            className='action-btn'
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </header>

        <section>
          <h1 className='visually-hidden'>Supervisor listing</h1>
          <UserSupervisorListing
            assignmentType={assignmentType}
            isLoading={assignmentsQuery.isLoading}
            data={{ Ownerships: assignmentsQuery.data ?? [] }}
            onCheckboxChange={handleCheckboxChange}
          />
        </section>
      </section>
    </div>
  );
};
