import { useGetTaskAssignmentProjectsGetProjectsAndGangs } from 'api/opcs';
import { Loader } from 'core/components';
import { useUpdateBreadcrumbs } from 'core/hooks';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type TasksRedirectProps = {};

const useGetProjectsAndGangs = () =>
  useGetTaskAssignmentProjectsGetProjectsAndGangs({
    query: {
      select: (data) => data?.Data ?? []
    }
  });

export const TasksRedirect: React.FC<TasksRedirectProps> = (props) => {
  const query = useGetProjectsAndGangs();
  const navigate = useNavigate();

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments'
            },
            {
              name: 'Project settings',
              path: '/project-settings'
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership'
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: true
            }
          ]
        }
      ],
      []
    )
  );

  useEffect(() => {
    if (query.status === 'success') {
      const selectedProject = query.data?.find(
        (project: any) => project.Selected
      );
      const selectedProjectUser =
        selectedProject &&
        selectedProject.Gangs?.find((user: any) => user.Selected);

      if (selectedProject) {
        navigate(
          `/tasks/${selectedProject?.Id || 'empty'}/${
            selectedProjectUser?.Id || 'empty'
          }/tasks`
        );
      } else {
        navigate('/tasks/empty/empty/tasks');
      }
    }
  }, [query, navigate]);

  return <Loader />;
};
