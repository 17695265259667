import React, { useCallback } from "react";

export interface FilterBarProps {
  onSearchQueryChange: (query: string) => void;
  searchQuery: string;
  onResetSearchQuery?: () => void;
}

export const FilterBar: React.FC<FilterBarProps> = ({
  onSearchQueryChange,
  onResetSearchQuery,
  searchQuery,
}) => {
  const handleResetSearchQuery = useCallback(() => {
    if (onResetSearchQuery) onResetSearchQuery();
    onSearchQueryChange("");
  }, [onSearchQueryChange, onResetSearchQuery]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onSearchQueryChange(event.target.value);
    },
    [onSearchQueryChange]
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback((event) => {
      if (event.key === "Enter") {
        event.currentTarget.blur();
      }
    }, []);

  return (
    <div className="filter-controls-ctnr">
      <input
        className="user-filter-textbox large"
        type="search"
        placeholder="Filter users"
        maxLength={100}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={searchQuery}
      />
      {searchQuery && (
        <span
          role="button"
          tabIndex={-1}
          className="reset-filter"
          onClick={handleResetSearchQuery}
        />
      )}
    </div>
  );
};
