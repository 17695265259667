import { useGetOperativeEventsAssignmentId } from 'api/opcs';

const useGetEventsByAssignmentId = (assignmentId: number) =>
  useGetOperativeEventsAssignmentId(assignmentId, {
    query: {
      select: (data) => data?.Data ?? [],
      enabled: false
    }
  });

export default useGetEventsByAssignmentId;
