import { OperativeDaysCellModel } from 'api/model';
import React, { useCallback } from 'react';

export type CellModelWithOperativeName = OperativeDaysCellModel & {
  OperativeName?: string;
};

export type InspectionMode = {
  general?: boolean;
  byTask?: boolean;
  withCell?: boolean;
};

export type TasksContextType = {
  selectedTasks: Set<string>;
  taskId?: number;
  taskName?: string;
  inspectionMode?: InspectionMode;
  selectedCell?: CellModelWithOperativeName;
  selectedDetailedCells?: OperativeDaysCellModel[];
  ownershipBackButtonEnabled?: boolean;

  clearSelectedDetailedCells: () => void;
  updateSelectedTasks: (tasks: Set<string>) => void;
  clearSelectedTasks: () => void;
  updateTaskId: (taskId: number) => void;
  clearTaskId: () => void;
  updateTaskName: (taskName: string) => void;
  clearTaskName: () => void;
  updateInspectionMode: (inspectionMode: InspectionMode) => void;
  updateSelectedCell: (
    cell: OperativeDaysCellModel,
    operativeName?: string
  ) => void;
  updateSelectedDetailedCells: (cell: OperativeDaysCellModel[]) => void;
  removeAssignmentFormSelectedCell: (projectTaskAssignmentId: number) => void;
  clearSelectedCell: () => void;
  setOwnershipBackButtonEnabled: (enabled: boolean) => void;
};

export const TasksContext = React.createContext<TasksContextType>(
  {} as TasksContextType
);

export const TasksProvider: React.FC = ({ children }) => {
  const [selectedTasks, setSelectedTasks] = React.useState<Set<string>>(
    new Set()
  );

  const [taskId, setTaskId] = React.useState<number>();
  const [taskName, setTaskName] = React.useState<string>();
  const [inspectionMode, setInspectionMode] = React.useState<InspectionMode>();
  const [selectedCell, setSelectedCell] =
    React.useState<CellModelWithOperativeName>();
  const [selectedDetailedCells, setSelectedDetailedCells] = React.useState<
    OperativeDaysCellModel[]
  >([]);

  const [ownershipBackButtonEnabled, setOwnershipBackButtonEnabled] =
    React.useState(false);

  const updateSelectedTasks = useCallback(
    (tasks: Set<string>) => {
      setSelectedTasks(tasks);
    },
    [setSelectedTasks]
  );

  const clearSelectedTasks = useCallback(() => {
    setSelectedTasks(new Set());
  }, [setSelectedTasks]);

  const clearSelectedDetailedCells = useCallback(() => {
    setSelectedDetailedCells([]);
  }, [setSelectedDetailedCells]);

  const updateTaskId = useCallback(
    (taskId: number) => {
      setTaskId(taskId);
    },
    [setTaskId]
  );

  const clearTaskId = useCallback(() => {
    setTaskId(undefined);
  }, [setTaskId]);

  const updateTaskName = useCallback(
    (taskName: string) => {
      setTaskName(taskName);
    },
    [setTaskName]
  );

  const clearTaskName = useCallback(() => {
    setTaskName(undefined);
  }, [setTaskName]);

  const updateInspectionMode = useCallback(
    (inspectionMode: InspectionMode) => setInspectionMode(inspectionMode),
    [setInspectionMode]
  );

  const updateSelectedCell = useCallback(
    (cell: OperativeDaysCellModel, operativeName?: string) => {
      setSelectedCell({ ...cell, OperativeName: operativeName });
    },
    [setSelectedCell]
  );

  const updateSelectedDetailedCells = useCallback(
    (cells: OperativeDaysCellModel[]) => {
      console.log(cells);
      setSelectedDetailedCells(cells);
    },
    [setSelectedDetailedCells]
  );

  const clearSelectedCell = useCallback(() => {
    setSelectedCell(undefined);
  }, [setSelectedCell]);

  const removeAssignmentFormSelectedCell = useCallback(
    (projectTaskAssignmentId: number) => {
      setSelectedCell((curr) => ({
        ...curr,
        Assignments: curr?.Assignments?.filter(
          (assignment) =>
            assignment.ProjectTaskAssignmentId !== projectTaskAssignmentId
        )
      }));
    },
    [setSelectedCell]
  );

  return (
    <TasksContext.Provider
      value={{
        selectedTasks,
        taskId,
        taskName,
        inspectionMode,
        selectedCell,
        selectedDetailedCells,
        ownershipBackButtonEnabled,
        updateSelectedTasks,
        clearSelectedDetailedCells,
        clearSelectedTasks,
        updateTaskId,
        clearTaskId,
        updateTaskName,
        clearTaskName,
        updateInspectionMode,
        updateSelectedCell,
        updateSelectedDetailedCells,
        clearSelectedCell,
        removeAssignmentFormSelectedCell,
        setOwnershipBackButtonEnabled
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};
