import { Route, Routes } from "react-router-dom";
import { ProjectSettingsList } from "features/project-settings/ProjectSettingsList";
import { ProjectSettingsCompany } from "features/project-settings/ProjectSettingsCompany";

export const ProjectSettings: React.FC = () => {
  return (
    <Routes>
      <Route path=":companyId" element={<ProjectSettingsCompany />} />
      <Route path="/" element={<ProjectSettingsList />} />
    </Routes>
  );
};
