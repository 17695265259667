import React from 'react';

interface ProjectSettingsSectionProps {
  title: string;
  children: React.ReactNode;
}

const ProjectSettingsSection = ({
  title,
  children
}: ProjectSettingsSectionProps) => {
  return (
    <section className='margin-bottom-2x'>
      <div className='tooltip visible section-title'>{title}</div>
      <div>{children}</div>
    </section>
  );
};

export default ProjectSettingsSection;
