import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

type OwnershipSearcherProps = {
  debounceDelay?: number;
  onClickCancel?: () => void;
  onChangeSearchTerm: (searchTerm: string) => void;
};

export const OwnershipSearcher: React.FC<OwnershipSearcherProps> = (props) => {
  const { debounceDelay, onClickCancel, onChangeSearchTerm } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDebounced] = useDebounce(searchTerm, debounceDelay || 600);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback((event) => {
      if (event.key === "Enter") {
        event.currentTarget.blur();
      }
    }, []);

  useEffect(() => {
    onChangeSearchTerm(searchTermDebounced);

    return () => {
      onChangeSearchTerm("");
    };
  }, [searchTermDebounced, onChangeSearchTerm]);

  return (
    <section className="search">
      <form id="search-operatives-form">
        <input
          className="search-textbox large"
          type="search"
          placeholder="Search operatives"
          maxLength={100}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button className="white-with-border cancel" onClick={onClickCancel}>
          Close
        </button>
      </form>
    </section>
  );
};
