import cx from "classnames";

export interface EmptyListProps {
  className?: string;
  message: string;
}

export const EmptyList: React.FC<EmptyListProps> = (props) => {
  const { className, message } = props;

  return <div className={cx("empty empty-list", className)}>{message}</div>;
};
