import { useCallback, useState } from 'react';
import cx from 'classnames';
import { EmptyList, Loader } from 'core/components';
import {
  UserProjectAssignmentCollectionExtendedModel,
  UserProjectAssignmentExtendedModel
} from '../UserProjectAssignment';
import { getProjectAssignmentUserProjectAssignmentGetUserUserId } from 'api/opcs';
import { UserDetailModelEnvelope } from 'api/model';

// Page updated for OP2-66
// Now uses MouseEnter/Leave to display tooltip

export interface UserProjectListingProps {
  isLoading?: boolean;
  data: UserProjectAssignmentCollectionExtendedModel;

  onCheckboxChange?: (assignment: UserProjectAssignmentExtendedModel) => void;
}

export const UserProjectListing: React.FC<UserProjectListingProps> = (
  props
) => {
  const { isLoading, data, onCheckboxChange } = props;

  const [tooltipText, setTooltipText] = useState<string>('');
  const [isSuperuser, setIsSuperuser] = useState<boolean | undefined>(
    undefined
  );
  const [isSupervisor, setIsSupervisor] = useState<boolean | undefined>(
    undefined
  );

  const hasItems = data.Assignments.length > 0;
  // const hasSuperuser = useMemo(
  //   () => data.Assignments && data.Assignments[0] && Object.keys(data.Assignments[0]).includes("Assigned1"),
  //   [data.Assignments]
  // );

  if (hasItems) {
    const userID: number | undefined = data.Assignments[0].UserId;
    if (userID !== undefined) {
      getProjectAssignmentUserProjectAssignmentGetUserUserId(userID).then(
        (result: UserDetailModelEnvelope) => {
          if (!result.HasErrors) {
            setIsSuperuser(result.Data?.IsSuperUser);
            setIsSupervisor(result.Data?.IsSupervisor);
          }
        }
      );
    }
  }

  // const isSuperuser = useMemo(() => data.Assignments && data.Assignments[0] && data.Assignments[0].AssignmentType === 1, [data.Assignments]);

  const handleCheckboxChange = useCallback(
    (assignment: UserProjectAssignmentExtendedModel) => {
      onCheckboxChange?.(assignment);
    },
    [onCheckboxChange]
  );

  const [tooltipVisible, setTooltipVisible] = useState(false);

  // Show a Tooltip given the tip to display
  function showTooltip(tooltip: string) {
    setTooltipText(tooltip);
    setTooltipVisible(true);
  }
  // Stop showing a tooltip (after 5 seconds)
  function stopTooltip() {
    setTimeout(() => {
      setTooltipVisible((status) => {
        return false;
      });
    }, 5000);
  }

  return (
    <>
      <div className='listing-container'>
        <div className='assignment-tooltip--floating'>
          <div className={cx('tooltip', { visible: tooltipVisible })}>
            {tooltipText}
          </div>
        </div>
        {!isLoading && hasItems && (
          <table className='user-project-listing table-with-headers'>
            <caption className='visually-hidden'>List of projects</caption>
            <colgroup>
              {isSupervisor === true && (
                <col className='supervisor-assignment' />
              )}
              {isSuperuser === true && <col className='superuser-assignment' />}
              <col className='project-name' />
              <col className='project-folio' />
              <col className='assignment-label' />
            </colgroup>
            <thead className='nowrap'>
              <tr>
                {isSupervisor === true && (
                  <th
                    className='supervisor-assignment'
                    onMouseEnter={() =>
                      showTooltip(
                        "Toggle project assignments for this user's supervisor role"
                      )
                    }
                    onMouseLeave={() => stopTooltip()}
                  />
                )}
                {isSuperuser === true && (
                  <th
                    className='superuser-assignment'
                    onMouseEnter={() =>
                      showTooltip(
                        "Toggle project assignments for this user's superuser role"
                      )
                    }
                    onMouseLeave={() => stopTooltip()}
                  />
                )}
                <th title='Project name'>Project name</th>
                <th title='Project folio'>Project folio</th>
                <th title='Project assignment'>Project assignment</th>
              </tr>
            </thead>
            <tbody className='no-wrap'>
              {data.Assignments.map((assignment) => (
                <tr key={assignment.ProjectId}>
                  {isSupervisor === true && (
                    <td className='checkbox-label'>
                      <input
                        id={`supervisor-assignment-cbx-${assignment.ProjectId}`}
                        name={`supervisor-assignment-cbx-${assignment.ProjectId}`}
                        type='checkbox'
                        defaultChecked={assignment.Assigned}
                        onChange={(e) =>
                          handleCheckboxChange({
                            ...assignment,
                            Assigned: e.target.checked
                          })
                        }
                      />
                      <label
                        htmlFor={`supervisor-assignment-cbx-${assignment.ProjectId}`}
                      />
                    </td>
                  )}
                  {isSuperuser === true && (
                    <td className='checkbox-label'>
                      <input
                        id={`superuser-assignment-cbx-${assignment.ProjectId}`}
                        name={`superuser-assignment-cbx-${assignment.ProjectId}`}
                        type='checkbox'
                        defaultChecked={assignment.Assigned1}
                        onChange={(e) =>
                          handleCheckboxChange({
                            ...assignment,
                            Assigned1: e.target.checked
                          })
                        }
                      />
                      <label
                        htmlFor={`superuser-assignment-cbx-${assignment.ProjectId}`}
                      />
                    </td>
                  )}
                  <td className='project-name' title={assignment.ProjectName}>
                    {assignment.ProjectName}
                  </td>
                  <td className='project-folio' title={assignment.ProjectFolio}>
                    {assignment.ProjectFolio}
                  </td>
                  <td
                    className='assignment-label'
                    title={(assignment as any).assignmentLabel}
                  >
                    {(assignment as any).assignmentLabel}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {isLoading && <Loader className='pinned translucent' />}
      </div>
      {!isLoading && !hasItems && (
        <EmptyList message='There are no projects to display.' />
      )}
    </>
  );
};
