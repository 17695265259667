import { EmptyData } from "api/model";
import { EmptyList, Loader } from "core/components";
import React from "react";

type OperativesTableProps = {
  operatives: EmptyData;
  isLoading?: boolean;
};

export const OperativesTable: React.FC<OperativesTableProps> = (props) => {
  const { operatives, isLoading } = props;

  const hasItems = operatives.Count > 0;

  return (
    <>
      {hasItems && (
        <table className="sticky-header-table">
          <colgroup>
            <col style={{ width: "200px" }} />
            <col style={{ width: "100px" }} />
            <col style={{ width: "200px" }} />
            <col style={{ width: "150px" }} />
            <col style={{ width: "150px" }} />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th scope="col" className="sortable" title="Gang">
                Gang
              </th>
              <th scope="col" className="sortable" title="Borrowed">
                Borrowed
              </th>
              <th scope="col" className="sortable" title="Payroll ID">
                Payroll ID
              </th>
              <th scope="col" className="sortable" title="First name">
                First name
              </th>
              <th scope="col" className="sortable" title="Last name">
                Last name
              </th>
              <th scope="col" className="sortable" title="Trade description">
                Trade description
              </th>
            </tr>
          </thead>
        </table>
      )}
      {hasItems && (
        <div className="relative-container">
          <table className="listing">
            <caption className="visually-hidden">List of operatives</caption>
            <colgroup>
              <col style={{ width: "200px" }} />
              <col style={{ width: "100px" }} />
              <col style={{ width: "200px" }} />
              <col style={{ width: "150px" }} />
              <col style={{ width: "150px" }} />
              <col />
            </colgroup>
            <tbody className="no-wrap">
              {operatives.Items.map(
                (operativesItem: EmptyData, idx: number) => (
                  <tr key={idx}>
                    <td title={operativesItem.Gang}>{operativesItem.Gang}</td>
                    <td title=""></td>
                    <td title={operativesItem.PayrollId}>
                      {operativesItem.PayrollId}
                    </td>
                    <td title={operativesItem.FirstName}>
                      {operativesItem.FirstName}
                    </td>
                    <td title={operativesItem.LastName}>
                      {operativesItem.LastName}
                    </td>
                    <td title={operativesItem.TradeDescription}>
                      {operativesItem.TradeDescription}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          {isLoading && <Loader className="pinned translucent" />}
        </div>
      )}
      {!isLoading && !hasItems && (
        <EmptyList message="There are no operatives to display." />
      )}
    </>
  );
};
