import { ProjectModel } from "api/model";
import React, { useCallback, useState } from "react";

export type SettingsContextType = {
  settings: ProjectModel[];
  getSettingsById?: (projectId: number) => ProjectModel | undefined;
  updateSettingsAll?: (settingsAll: ProjectModel[]) => void;
  updateSettingsOne?: (settingsOne: ProjectModel) => void;
  clearSettings?: () => void;
};

export const SettingsContext = React.createContext<SettingsContextType | null>(
  null
);

export const SettingsProvider: React.FC = ({ children }) => {
  const [settings, setSettings] = useState<ProjectModel[]>([]);

  const updateSettingsAll = useCallback(
    (settingsAll: ProjectModel[]) => {
      setSettings(settingsAll);
    },
    [setSettings]
  );

  const updateSettingsOne = useCallback(
    (settingsOne: ProjectModel) => {
      setSettings((curr) =>
        curr.map((oldSettings) => {
          if (oldSettings.Id === settingsOne.Id) {
            return settingsOne;
          }
          return oldSettings;
        })
      );
    },
    [setSettings]
  );

  const clearSettings = useCallback(() => {
    setSettings([]);
  }, [setSettings]);

  const getSettingsById = useCallback(
    (projectId: number) => {
      return settings.find((settingsItem) => settingsItem.Id === projectId);
    },
    [settings]
  );

  return (
    <SettingsContext.Provider
      value={{
        settings,
        getSettingsById,
        updateSettingsAll,
        updateSettingsOne,
        clearSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
