import { format, parseISO } from 'date-fns';
import { Button } from '../Button';
import { NotificationContext, TasksContext } from 'core/context';
import { usePutOperativeEventsUnassign } from 'api/opcs';
import type { ListedEventModel, OperativeEventUnassignDto } from 'api/model';
import { useGetCalendar } from 'features/tasks/components/hooks';
import { useCalendarContext } from 'core/context/CalendarContext';
import { useContext } from 'react';
import { NotificationType } from 'core/context/NotificationContext.types';

interface ModalContentProps {
  currentEvent: ListedEventModel;
  onModalClose?: () => void;
  fetchedEvents: ListedEventModel[];
}

interface DeleteEventOptions {
  date?: string;
  operativeId?: number;
  unassignFutureEvents?: boolean;
}

const DeleteEventModal = ({
  currentEvent,
  onModalClose = undefined,
  fetchedEvents
}: ModalContentProps) => {
  const { mutate } = usePutOperativeEventsUnassign();
  const { addNotification } = useContext(NotificationContext);
  const taskCtx = useContext(TasksContext);
  const {
    clearSelectedDetailedCells,
    clearSelectedTasks,
    clearSelectedCell,
    selectedCell
  } = taskCtx;
  const { searchParams } = useCalendarContext();
  const { refetch: calendarRefetch, isSuccess: isCalendarRefetchSuccess } =
    useGetCalendar(searchParams, false);
  const parsedDate = format(parseISO(selectedCell?.Date || ''), 'yyyy/MM/dd');

  const event = selectedCell?.Events?.find(
    (e) => e.EventId === currentEvent.Id
  );

  const singleDayMultipleOperatives =
    fetchedEvents.filter(
      // @ts-expect-error bad types in API
      (event) => event.EventDate === selectedCell?.Date
    ).length > 1;

  const multipleDaysSingleOperative =
    fetchedEvents.some(
      // @ts-expect-error bad types in API
      (event) => event.EventDate !== selectedCell?.Date
    ) &&
    fetchedEvents.every(
      // @ts-expect-error bad types in API
      (event) => event.OperativeId === selectedCell?.OperativeId
    );

  const multipleDaysSingleOperativeFiltered =
    fetchedEvents.some(
      // @ts-expect-error bad types in API
      (event) => event.EventDate !== selectedCell?.Date
    ) &&
    !!fetchedEvents.filter(
      // @ts-expect-error bad types in API
      (event) => event.OperativeId === selectedCell?.OperativeId
    ).length;

  const multipleDaysMultipleOperatives =
    fetchedEvents.some(
      // @ts-expect-error bad types in API
      (event) => event.EventDate !== selectedCell?.Date
    ) &&
    fetchedEvents.some(
      // @ts-expect-error bad types in API
      (event) => event.OperativeId !== selectedCell?.OperativeId
    );

  console.log({
    multipleDaysSingleOperative,
    singleDayMultipleOperatives,
    multipleDaysSingleOperativeFiltered
  });

  const handleDeleteEvent = ({
    operativeId = undefined,
    date = undefined,
    unassignFutureEvents = false
  }: DeleteEventOptions = {}) => {
    const eventToDelete: OperativeEventUnassignDto = {
      AssignmentId: event?.EventAssignmentID,
      EventId: event?.EventId,
      OperativeId: operativeId,
      EventDate: date,
      UnassignFutureEvents: unassignFutureEvents
    };

    mutate(
      {
        data: eventToDelete
      },
      {
        onSuccess: () => {
          addNotification({
            Type: NotificationType.Success,
            Text: 'Your changes have been saved successfully.'
          });
          calendarRefetch();
          if (isCalendarRefetchSuccess) {
            clearSelectedDetailedCells();
            clearSelectedTasks();
            clearSelectedCell();
          }
        },
        onError: () => {
          addNotification({
            Type: NotificationType.Error,
            Text: 'There was an error saving the changes. Please try again later.'
          });
        }
      }
    );
  };

  return selectedCell ? (
    <div className='modalWrapper'>
      <div className='modalButtons'>
        <Button
          colour='yellow'
          className='modalButton'
          onClick={() =>
            handleDeleteEvent({
              date: selectedCell.Date,
              operativeId: selectedCell.OperativeId
            })
          }
        >
          Only for {selectedCell.OperativeName} on {parsedDate}
        </Button>
        {singleDayMultipleOperatives ? (
          <Button
            colour='yellow'
            className='modalButton'
            onClick={() =>
              handleDeleteEvent({
                date: selectedCell.Date
              })
            }
          >
            For all operatives on {parsedDate}
          </Button>
        ) : null}
        {multipleDaysSingleOperative || multipleDaysSingleOperativeFiltered ? (
          <Button
            colour='yellow'
            className='modalButton'
            onClick={() =>
              handleDeleteEvent({
                operativeId: selectedCell.OperativeId,
                unassignFutureEvents: true
              })
            }
          >
            Only for {selectedCell.OperativeName} on all days
          </Button>
        ) : null}
        {multipleDaysMultipleOperatives ? (
          <Button
            colour='yellow'
            className='modalButton'
            onClick={() =>
              handleDeleteEvent({
                unassignFutureEvents: true
              })
            }
          >
            All operatives on all days
          </Button>
        ) : null}
        {onModalClose ? (
          <Button className='modalDeleteCloseButton' onClick={onModalClose}>
            Cancel
          </Button>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default DeleteEventModal;
