interface SVGIconProps {
  color?: string;
}

const LockIcon = ({ color = "#000" }: SVGIconProps) => {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59091 5.09082H1.40909C1.18316 5.09082 1 5.27398 1 5.49991V11.2272C1 11.4531 1.18316 11.6363 1.40909 11.6363H9.59091C9.81684 11.6363 10 11.4531 10 11.2272V5.49991C10 5.27398 9.81684 5.09082 9.59091 5.09082Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.45459 5.09091V3.27273C3.45459 2.66996 3.67009 2.09189 4.05369 1.66567C4.43729 1.23945 4.95756 1 5.50004 1C6.04253 1 6.5628 1.23945 6.9464 1.66567C7.33 2.09189 7.5455 2.66996 7.5455 3.27273V5.09091"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockIcon;
