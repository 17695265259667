import {
  useGetTaskAssignmentOperativesGetGangOperativesProjectIdGangId,
  useGetTaskAssignmentProjectsGetProjectsAndGangs
} from 'api/opcs';
import { useUpdateBreadcrumbs } from 'core/hooks';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { convertOldGangIdToNewGangId } from 'utils/convertOldGangIdToNewGangId';
import { OperativesTable } from './components';

type TasksOperativesProps = {};

const useGetOperatives = (projectId: string, gangId: string) =>
  useGetTaskAssignmentOperativesGetGangOperativesProjectIdGangId(
    +projectId,
    +gangId,
    {
      query: {
        select: (data) => data?.Data ?? []
      }
    }
  );

const useGetProjectsAndGangs = () =>
  useGetTaskAssignmentProjectsGetProjectsAndGangs({
    query: {
      select: (data) => data?.Data ?? []
    }
  });

export const TasksOperatives: React.FC<TasksOperativesProps> = (props) => {
  const { projectId, userId } = useParams();
  const query = useGetOperatives(projectId!, userId!);
  const queryProjectsAndGangs = useGetProjectsAndGangs();

  const getProjectsBreadcrumb = useCallback(() => {
    return queryProjectsAndGangs.status === 'success'
      ? queryProjectsAndGangs.data.map((project: any) => {
          // OP2-58: Map the current gangID into the project's gangID
          let newGangId: string | undefined = convertOldGangIdToNewGangId(
            queryProjectsAndGangs.data,
            projectId,
            userId,
            project.Gangs
          );
          return {
            name: project.Name,
            path: `/tasks/${project.Id}/${newGangId}/operatives`,
            selected: project.Id === +projectId!
          };
        })
      : [
          {
            name: 'NO PROJECTS ASSIGNED',
            path: '#',
            selected: true
          }
        ];
  }, [
    queryProjectsAndGangs.status,
    queryProjectsAndGangs.data,
    projectId,
    userId
  ]);

  const getProjectUsersBreadcrumb = useCallback(() => {
    if (queryProjectsAndGangs.status === 'success') {
      // OP2-58: Don't use Selected attribute, use the projectID
      const selectedProject = queryProjectsAndGangs.data.find(
        (project: any) => project.Id === +projectId!
      );
      // const selectedProject = queryProjectsAndGangs.data.find((project: any) => project.Selected);

      return [
        {
          name: 'All Gangs',
          path: `/tasks/${projectId}/all/operatives`,
          selected: userId === 'all'
        },
        ...selectedProject?.Gangs.map((gang: any) => ({
          name: gang.Name,
          path: `/tasks/${projectId}/${gang.Id}/operatives`,
          selected: gang.Id === +userId!,
          deactivated: gang.Deactivated
        }))
      ];
    }
    return [
      {
        name: 'NO AVAILABLE PROJECTS',
        path: '#',
        selected: true
      }
    ];
  }, [
    queryProjectsAndGangs.status,
    queryProjectsAndGangs.data,
    projectId,
    userId
  ]);

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments'
            },
            {
              name: 'Project settings',
              path: '/project-settings'
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership'
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: true
            }
          ]
        },
        {
          withSearch: false,
          items: getProjectsBreadcrumb()
        },
        {
          withSearch: true,
          items: getProjectUsersBreadcrumb()
        },
        {
          withSearch: false,
          items: [
            {
              name: 'Tasks',
              path: `/tasks/${projectId}/${userId}/tasks`
            },
            {
              name: 'Operatives',
              path: '#',
              selected: true
            },
            {
              name: 'Ownership',
              path: `/tasks/${projectId}/${userId}/ownership`,
              selected: false
            }
          ]
        }
      ],
      [getProjectsBreadcrumb, getProjectUsersBreadcrumb, projectId, userId]
    )
  );

  return (
    <section className='operatives-list-content'>
      <OperativesTable
        operatives={query.status === 'success' ? query.data : []}
        isLoading={query.status === 'loading'}
      />
    </section>
  );
};
