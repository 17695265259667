import { Route, Routes } from "react-router-dom";
import { ProjectAssignableUsers, Promotion, UserProjectAssignment } from "features/project-assignments";

export const ProjectAssignments: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectAssignableUsers />} />
      <Route path="/user/:userId" element={<UserProjectAssignment/>} />
      <Route path="/promotion" element={<Promotion />} />
    </Routes>
  );
};
