import { useCallback, useEffect, useRef } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import cx from "classnames";

export interface PagerProps {
  className?: string;
  currentPage: number;
  totalPages: number;

  isEnabled?: boolean;
  isPreviousEnabled?: boolean;
  isNextEnabled?: boolean;
  isCurrentEnabled?: boolean;

  onPageChanged?: (newPage: number) => void;
}

export const Pager: React.FC<PagerProps> = (props) => {
  const {
    className,
    currentPage,
    totalPages,
    isEnabled,
    isPreviousEnabled,
    isNextEnabled,
    isCurrentEnabled,
    onPageChanged,
  } = props;

  const { isOpen, onClose, onToggle } = useDisclosure();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      ref.current?.focus();
    }
  }, [isOpen]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        if (event.key === "Enter") {
          event.currentTarget.blur();
          const newPage = +event.currentTarget.value;
          if (!isNaN(newPage) && newPage > 0 && newPage <= totalPages) {
            onPageChanged?.(newPage);
          }
          onClose();
        }
      },
      [onClose, onPageChanged, totalPages]
    );

  return (
    <div className={cx("pager-row", className)}>
      <div
        className={cx("pager", {
          disabled: isEnabled === false,
        })}
      >
        <div
          className={cx("pager-button previous-page", {
            disabled: isPreviousEnabled === false,
          })}
          onClick={() => onPageChanged?.(currentPage - 1)}
        />
        <div
          className={cx("pager-button current-page", {
            disabled: isCurrentEnabled === false,
          })}
          onClick={onToggle}
        >
          Page {currentPage} of {totalPages}
        </div>
        <div
          className={cx("pager-button next-page", {
            disabled: isNextEnabled === false,
          })}
          onClick={() => onPageChanged?.(currentPage + 1)}
        />
        {isOpen && (
          <div
            className="page-picker hover-panel fixed"
            style={{
              display: "block",
              position: "absolute",
              top: "-64px",
              left: "40px",
            }}
          >
            <div
              className="panel-arrow down"
              style={{
                top: "49px",
                left: "26px",
              }}
            />
            <input
              type="text"
              ref={ref}
              defaultValue={currentPage}
              onKeyDown={handleKeyDown}
            />
          </div>
        )}
      </div>
    </div>
  );
};
