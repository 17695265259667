import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { TopBarModal } from "./TopBarModal";
import classNames from "classnames";
import { BreadcrumbsContext, BreadcrumbsContextType } from "core/context";

export interface BreadcrumbsItem {
  name: string;
  path: string;
  selected?: boolean;
  hiddenInDropdown?: boolean;
  deactivated?: boolean;
}

export interface BreadcrumbsCategory {
  withSearch?: boolean;
  items: BreadcrumbsItem[];
}

export interface BreadcrumbsProps {}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const [openModal, setOpenModal] = useState({} as any);
  const [searchTerm, setSearchTerm] = useState("");
  const { breadcrumbs } = useContext(
    BreadcrumbsContext
  ) as BreadcrumbsContextType;
  const location = useLocation();

  const getSelectedBreadcrumb = (
    breadcrumbsCategory: BreadcrumbsCategory,
    className?: string
  ) => {
    return (
      <>
        {breadcrumbsCategory.items
          .filter((item) => item.selected)
          .map((item) => (
            <div className={className} title={item.name} key={item.name}>
              {item.name?.toUpperCase()}
            </div>
          ))}
      </>
    );
  };

  const showModal = (breadcrumbsCategory: BreadcrumbsCategory) => {
    return breadcrumbsCategory.items.length > 1;
  };

  const handleCloseModal = useCallback((idx: number) => {
    setOpenModal((curr: any) => ({ ...curr, [idx]: false }));
    setSearchTerm("");
  }, []);

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  }, []);

  const getBreadcrumbsItem = useCallback(
    (breadcrumbsCategory: BreadcrumbsCategory) => {
      const filtered = breadcrumbsCategory.items
        .filter(
          (item) =>
            !item.hiddenInDropdown &&
            !item.deactivated &&
            item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
        .map((item, idx) => {
          return (
            <Link
              to={item.path}
              key={idx}
              className={classNames({
                "with-icon selected": item.selected,
              })}
            >
              {item.name}
            </Link>
          );
        });
      return filtered.length > 0 ? (
        filtered
      ) : (
        <span className="menu-panel__subtext">No matching items found</span>
      );
    },
    [searchTerm]
  );

  const getBreadcrumbsItemDeactivated = useCallback(
    (breadcrumbsCategory: BreadcrumbsCategory) => {
      return breadcrumbsCategory.items
        .filter(
          (item) =>
            !item.hiddenInDropdown &&
            item.deactivated &&
            item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
        .map((item, idx) => {
          return (
            <Link
              to={item.path}
              key={idx}
              className={classNames({
                "with-icon selected": item.selected,
              })}
            >
              <span className="menu-panel__title menu-panel__title--deactivated">
                {item.name}
              </span>
            </Link>
          );
        });
    },
    [searchTerm]
  );

  useEffect(() => {
    setOpenModal({});
  }, [location]);

  return (
    <div className="breadcrumb-menu-container">
      <nav className="breadcrumb-menu">
        <h2 className="visually-hidden">Breadcrumb navigation</h2>

        {breadcrumbs.map((breadcrumbsCategory, idx) => {
          return (
            <div
              className={classNames("main-menu-item menu truncate active", {
                "with-icon": showModal(breadcrumbsCategory),
                "has-search": breadcrumbsCategory.withSearch,
              })}
              key={idx}
              onClick={() => {
                setOpenModal((curr: any) => ({ ...curr, [idx]: true }));
              }}
            >
              {getSelectedBreadcrumb(breadcrumbsCategory)}
              <TopBarModal
                openModal={openModal[idx]}
                onCloseModal={() => handleCloseModal(idx)}
              >
                {showModal(breadcrumbsCategory) && (
                  <div className="menu-panel main-menu-panel">
                    {getSelectedBreadcrumb(breadcrumbsCategory, "menu")}
                    {breadcrumbsCategory.withSearch && (
                      <div className="menu-search-contain">
                        <div className="menu-search-icon"></div>
                        <input
                          type="search"
                          className="menu-search"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={handleChangeSearch}
                        />
                        <div
                          className={classNames("menu-search-clear", {
                            "clear-disabled": !!!searchTerm.length,
                          })}
                          onClick={() => setSearchTerm("")}
                        ></div>
                      </div>
                    )}
                    {getBreadcrumbsItem(breadcrumbsCategory)}

                    {getBreadcrumbsItemDeactivated(breadcrumbsCategory) &&
                      getBreadcrumbsItemDeactivated(breadcrumbsCategory)
                        .length > 0 && (
                        <>
                          <div className="menu-panel-divider"></div>
                          {getBreadcrumbsItemDeactivated(breadcrumbsCategory)}
                        </>
                      )}
                  </div>
                )}
              </TopBarModal>
            </div>
          );
        })}
      </nav>
    </div>
  );
};
