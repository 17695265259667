/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * LOR OPCS API
 * OpenAPI spec version: v1
 */

export type UserProjectAssignmentType = 0 | 1;


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProjectAssignmentType = {
  NUMBER_0: 0 as UserProjectAssignmentType,
  NUMBER_1: 1 as UserProjectAssignmentType,
};
