import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import type { OperativeDaysModel, ProjectTaskModel } from 'api/model';
import { useGetTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskId } from 'api/opcs';
import { Loader } from 'core/components';
import { TasksContext } from 'core/context';
import { ProjectTaskAssignments } from './ProjectTaskAssignments';

export interface ProjectHierarchyProjectTasksProps {
  projectId: number;
  gangId: number;

  isFirst?: boolean;
  isLast?: boolean;
  depth: number;

  task: ProjectTaskModel;
  selectedProjectTasks: Set<number>;
  handleProjectTaskClick?: () => void;

  isSavingProjectTaskId?: number;
  handleIncludeProjectTaskInTaskList?: (
    projectTaskId: number,
    include: boolean
  ) => void;

  hasSelectedOperatives?: boolean;
  isManageMode?: boolean;
  calendarData?: OperativeDaysModel;
  onRemoveProjectTaskAssignment?: (projectTaskAssignmentId: number) => void;
}

export const ProjectHierarchyProjectTasks: React.FC<
  ProjectHierarchyProjectTasksProps
> = (props) => {
  const {
    projectId,
    gangId,
    isFirst,
    isLast,
    depth,
    task,
    selectedProjectTasks,
    hasSelectedOperatives,
    isManageMode,
    handleProjectTaskClick,
    isSavingProjectTaskId,
    handleIncludeProjectTaskInTaskList,
    calendarData,
    onRemoveProjectTaskAssignment
  } = props;
  const taskCtx = useContext(TasksContext);

  const {
    data: assignedHoursBreakdown,
    isLoading: isLoadingAssignedHoursBreakdown
  } = useGetTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskId(
    projectId,
    gangId,
    task.Id!,
    {
      query: {
        enabled:
          Boolean(task?.AssignedHours) && selectedProjectTasks.has(task.Id!)
      }
    }
  );

  const inspectionMode = useMemo(
    () => taskCtx?.inspectionMode?.byTask || taskCtx?.inspectionMode?.general,
    [taskCtx?.inspectionMode]
  );

  const inspectionModeWithSelectedCell = useMemo(
    () => inspectionMode && taskCtx?.inspectionMode?.withCell,
    [inspectionMode, taskCtx?.inspectionMode]
  );

  return (
    <>
      {inspectionModeWithSelectedCell && (
        <ProjectTaskAssignments
          task={task}
          calendarData={calendarData}
          onRemoveProjectTaskAssignment={onRemoveProjectTaskAssignment}
        />
      )}
      {!inspectionModeWithSelectedCell && (
        <div
          className={cx('task-details full-width', {
            selected: selectedProjectTasks.has(task.Id!),
            'default-cursor': hasSelectedOperatives, // operativeDays.isAssigningOperatives() && !isSearchMode(),
            first: isFirst,
            last: isLast
          })}
          title={task.Name}
          onClick={handleProjectTaskClick}
        >
          {task.IsSynced && (hasSelectedOperatives || isManageMode) && (
            <button
              className={cx('task-assignment', {
                'has-even-depth-parent': depth % 2 === 0,
                'is-saving': isSavingProjectTaskId === task.Id,
                'with-icon--unpinned':
                  !hasSelectedOperatives && task.IsIncludedInTaskList, // !hasSelectedOperatives && task.IsIncludedInTaskList
                'with-icon--pinned':
                  !hasSelectedOperatives && !task.IsIncludedInTaskList // !hasSelectedOperatives && !task.IsIncludedInTaskList
              })}
              title={
                task.IsIncludedInTaskList
                  ? 'Remove task from Task Assignment list'
                  : 'Add task to Task Assignment list'
              }
              disabled={Boolean(isSavingProjectTaskId)}
              onClick={(ev) => {
                ev.stopPropagation();

                handleIncludeProjectTaskInTaskList?.(
                  task.Id!,
                  !task.IsIncludedInTaskList
                );
              }}
              data-bind='text: taskAssignmentButtonText, click: $root.selectProjectTask.bind($root)'
            >
              {hasSelectedOperatives && 'ASSIGN'}
            </button>
          )}
          <p className='left-column task-name' title={task.Name}>
            {task.Name}
          </p>
          <p
            className={cx('right-column', {
              shorter: true, // taskAssignmentButtonVisible(),
              'shorter--with-text-button': hasSelectedOperatives // operativeDays.hasSelectedOperatives(),
            })}
            title={`${task.PrimaryDetailLabel}${task.PrimaryDetailText}`}
          >
            <span className='label'>{task.PrimaryDetailLabel}</span>
            <span>{task.PrimaryDetailText}</span>
          </p>
          {selectedProjectTasks.has(task.Id!) && inspectionMode && (
            <>
              {task.AttributeOneLabel && (
                <>
                  <p className='left-column padding-left-1x'>
                    <span
                      className='label'
                      title={`${task.AttributeOneLabel}${task.AttributeOneText}`}
                    >
                      {task.AttributeOneLabel}
                    </span>
                    <span>{task.AttributeOneText}</span>
                  </p>
                  <p
                    className={cx('right-column', {
                      shorter: true, // taskAssignmentButtonVisible(),
                      'shorter--with-text-button': hasSelectedOperatives // operativeDays.hasSelectedOperatives(),
                    })}
                    title={`${task.AttributeTwoLabel}${task.AttributeTwoText}`}
                  >
                    <span className='label'>{task.AttributeTwoLabel}</span>
                    <span>{task.AttributeTwoText}</span>
                  </p>
                </>
              )}
              {task.AttributeThreeLabel && (
                <>
                  <p
                    className='left-column padding-left-1x'
                    title={`${task.AttributeThreeLabel}${task.AttributeThreeText}`}
                  >
                    <span className='label'>{task.AttributeThreeLabel}</span>
                    <span>{task.AttributeThreeText}</span>
                  </p>
                  <p
                    className={cx('right-column', {
                      shorter: true, //taskAssignmentButtonVisible(),
                      'shorter--with-text-button': hasSelectedOperatives // operativeDays.hasSelectedOperatives(),
                    })}
                    title={`${task.AttributeFourLabel}${task.AttributeFourText}`}
                  >
                    <span className='label'>{task.AttributeFourLabel}</span>
                    <span>{task.AttributeFourText}</span>
                  </p>
                </>
              )}
              {task.DisplayAssignmentToGangs &&
                (isLoadingAssignedHoursBreakdown ||
                  Boolean(assignedHoursBreakdown?.Data?.length)) && (
                  <div className='gang-assigned-hours-breakdown'>
                    {isLoadingAssignedHoursBreakdown && (
                      <Loader className='pinned' />
                    )}
                    {assignedHoursBreakdown?.Data?.map((data, idx) => (
                      <React.Fragment key={idx}>
                        <p
                          className='left-column padding-left-1x'
                          title={`Assigned to Gang · ${data.SupervisorName}`}
                        >
                          Assigned to Gang · <span>{data.SupervisorName}</span>
                        </p>
                        <p
                          className='right-column'
                          title={`Assigned hours · ${data.AssignedHours}`}
                        >
                          Assigned hours ·{' '}
                          <span>{data.AssignedHours?.toFixed(1)}</span>
                        </p>
                      </React.Fragment>
                    ))}
                  </div>
                )}
            </>
          )}
        </div>
      )}
    </>
  );
};
