import { NotificationType } from "core/context/NotificationContext.types";

export const getNotificationClassname = (n: {
  Type?: NotificationType;
}): string => {
  switch (n.Type) {
    case NotificationType.None:
      return "";
    case NotificationType.Information:
      return "info";
    case NotificationType.Success:
      return "tick";
    case NotificationType.Warning:
      return "alert";
    case NotificationType.Error:
      return "alert";
    default:
      return "";
  }
};
