import { ProjectModel } from "api/model";
import { Field, useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

type CheckboxDoubleBlockProps = {
  first: {
    id: string;
    name: string;
    value: boolean;
  };
  second: {
    id: string;
    name: string;
    value?: boolean | number;
  };
  label: string;
  isDisabled?: boolean;
  onSetDisabled?: () => void;
};

export const CustomCheckbox = (props: any) => {
  const { setFieldValue } = useFormikContext<ProjectModel>();
  const [field] = useField(props);

  useEffect(() => {
    if (props.firstvalue !== "true") {
      setFieldValue(field.name, false);
    }
  }, [props.firstvalue, field.name, setFieldValue]);

  useEffect(() => {
    if (field.checked) {
      if (field.name !== "DisplayAssignedHours2")
        setFieldValue("DisplayAssignedHours2", false);
      if (field.name !== "DisplayPlannedHours2")
        setFieldValue("DisplayPlannedHours2", false);
      if (field.name !== "DisplayPercentComplete2")
        setFieldValue("DisplayPercentComplete2", false);
      if (field.name !== "DisplayTaskType2")
        setFieldValue("DisplayTaskType2", false);
    }
  }, [field.checked, field.name, setFieldValue]);

  return <input {...props} {...field} />;
};

export const CheckboxDoubleBlock: React.FC<CheckboxDoubleBlockProps> = (
  props
) => {
  const { first, second, label, isDisabled, onSetDisabled } = props;
  const [disabled, setDisabled] = useState(true);

  // Based on the disabled argument, decide if the checkbox should is disabled
  var disableControl = isDisabled && isDisabled === true ? true : false;

  useEffect(() => {
    if (!first.value) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [first, onSetDisabled]);

  return (
    <tr>
      <td className="checkbox-label">
        <Field
          id={first.id}
          name={first.name}
          type="checkbox"
          disabled={disableControl}
        />
        <label htmlFor={first.id}></label>
      </td>
      <td className="checkbox-label">
        <CustomCheckbox
          type="checkbox"
          id={second.id}
          name={second.name}
          disabled={disableControl || disabled}
          firstvalue={first.value.toString()}
          secondvalue={second.value}
        />
        <label htmlFor={second.id}></label>
      </td>
      <td className="project-name">
        <label>{label}</label>
      </td>
    </tr>
  );
};
