/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * LOR OPCS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  EmptyEnvelope,
  AssignmentModel,
  RemoveAssignmentModel,
  DelayReasonArrayEnvelope,
  ListedEventModelArrayEnvelope,
  ListedNotificationModelArrayEnvelope,
  OperativeDaysModelEnvelope,
  OperativeDaysRequestModel,
  OperativeHoursWorkedByDateListEnvelope,
  OperativeEventAssignmentDto,
  OperativeEventUnassignDto,
  OperativeListingModelPageResultEnvelope,
  OperativeOwnershipListingModelPageResultEnvelope,
  OperativeOwnershipRequestModel,
  OperativeOwnershipUpdateModel,
  UserModelIEnumerableEnvelope,
  GangAssignedRequestModel,
  GangSelectorRefreshModelListEnvelope,
  ProjectSettingsModelResponseModelListEnvelope,
  ProjectModelListEnvelope,
  ProjectSettingsModel,
  ProjectTaskHierarchyModelArrayEnvelope,
  ProjectTaskRequestModel,
  ProjectTaskModelArrayEnvelope,
  ProjectTaskGangAssignedHoursModelArrayEnvelope,
  OperativeDayPercentageModelArrayEnvelope,
  OperativeDayPercentageBreakdownRequestModel,
  ProjectTaskListingModelPageResultEnvelope,
  StringEnvelope,
  OperativeModelPageResultEnvelope,
  SearchModel,
  OperativePromotionModel,
  UserResponseModelIEnumerableEnvelope,
  SuperUserSupervisorOwnershipCollectionModelEnvelope,
  SuperUserSupervisorOwnershipCollectionModel,
  UserProjectAssignmentCollectionModelEnvelope,
  UserDetailModelEnvelope,
  UserProjectAssignmentCollectionModel
} from './model'
import { customInstance, ErrorType } from './mutator/custom-instance'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postTaskAssignmentAssignmentSaveProjectIdGangId = (
    projectId: number,
    gangId: number,
    assignmentModel: AssignmentModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/task-assignment/assignment/save/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: assignmentModel
    },
      options);
    }
  


    export type PostTaskAssignmentAssignmentSaveProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentAssignmentSaveProjectIdGangId>>
    export type PostTaskAssignmentAssignmentSaveProjectIdGangIdMutationBody = AssignmentModel
    export type PostTaskAssignmentAssignmentSaveProjectIdGangIdMutationError = ErrorType<unknown>

    export const usePostTaskAssignmentAssignmentSaveProjectIdGangId = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentAssignmentSaveProjectIdGangId>, TError,{projectId: number;gangId: number;data: AssignmentModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentAssignmentSaveProjectIdGangId>, {projectId: number;gangId: number;data: AssignmentModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentAssignmentSaveProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentAssignmentSaveProjectIdGangId>, TError, {projectId: number;gangId: number;data: AssignmentModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTaskAssignmentAssignmentRemoveProjectTaskAssignmentId = (
    projectTaskAssignmentId: number,
    removeAssignmentModel: RemoveAssignmentModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/task-assignment/assignment/remove/${projectTaskAssignmentId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: removeAssignmentModel
    },
      options);
    }
  


    export type PostTaskAssignmentAssignmentRemoveProjectTaskAssignmentIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentAssignmentRemoveProjectTaskAssignmentId>>
    export type PostTaskAssignmentAssignmentRemoveProjectTaskAssignmentIdMutationBody = RemoveAssignmentModel
    export type PostTaskAssignmentAssignmentRemoveProjectTaskAssignmentIdMutationError = ErrorType<unknown>

    export const usePostTaskAssignmentAssignmentRemoveProjectTaskAssignmentId = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentAssignmentRemoveProjectTaskAssignmentId>, TError,{projectTaskAssignmentId: number;data: RemoveAssignmentModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentAssignmentRemoveProjectTaskAssignmentId>, {projectTaskAssignmentId: number;data: RemoveAssignmentModel}> = (props) => {
          const {projectTaskAssignmentId,data} = props || {};

          return  postTaskAssignmentAssignmentRemoveProjectTaskAssignmentId(projectTaskAssignmentId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentAssignmentRemoveProjectTaskAssignmentId>, TError, {projectTaskAssignmentId: number;data: RemoveAssignmentModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTaskAssignmentDelayreasonsProjectId = (
    projectId: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<DelayReasonArrayEnvelope>(
      {url: `/task-assignment/delayreasons/${projectId}`, method: 'get'
    },
      options);
    }
  

export const getGetTaskAssignmentDelayreasonsProjectIdQueryKey = (projectId: number,) => [`/task-assignment/delayreasons/${projectId}`];

    
export type GetTaskAssignmentDelayreasonsProjectIdQueryResult = NonNullable<AsyncReturnType<typeof getTaskAssignmentDelayreasonsProjectId>>
export type GetTaskAssignmentDelayreasonsProjectIdQueryError = ErrorType<unknown>

export const useGetTaskAssignmentDelayreasonsProjectId = <TData = AsyncReturnType<typeof getTaskAssignmentDelayreasonsProjectId>, TError = ErrorType<unknown>>(
 projectId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTaskAssignmentDelayreasonsProjectId>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTaskAssignmentDelayreasonsProjectIdQueryKey(projectId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTaskAssignmentDelayreasonsProjectId>> = () => getTaskAssignmentDelayreasonsProjectId(projectId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTaskAssignmentDelayreasonsProjectId>, TError, TData>(queryKey, queryFn, {enabled: !!(projectId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getEventsAll = (
    
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ListedEventModelArrayEnvelope>(
      {url: `/events/all`, method: 'get'
    },
      options);
    }
  

export const getGetEventsAllQueryKey = () => [`/events/all`];

    
export type GetEventsAllQueryResult = NonNullable<AsyncReturnType<typeof getEventsAll>>
export type GetEventsAllQueryError = ErrorType<unknown>

export const useGetEventsAll = <TData = AsyncReturnType<typeof getEventsAll>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getEventsAll>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetEventsAllQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getEventsAll>> = () => getEventsAll(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getEventsAll>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getOperativeEventsDataData = (
    
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ListedEventModelArrayEnvelope>(
      {url: `/operative-events-data/data`, method: 'get'
    },
      options);
    }
  

export const getGetOperativeEventsDataDataQueryKey = () => [`/operative-events-data/data`];

    
export type GetOperativeEventsDataDataQueryResult = NonNullable<AsyncReturnType<typeof getOperativeEventsDataData>>
export type GetOperativeEventsDataDataQueryError = ErrorType<EmptyEnvelope>

export const useGetOperativeEventsDataData = <TData = AsyncReturnType<typeof getOperativeEventsDataData>, TError = ErrorType<EmptyEnvelope>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getOperativeEventsDataData>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetOperativeEventsDataDataQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getOperativeEventsDataData>> = () => getOperativeEventsDataData(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getOperativeEventsDataData>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getOperativeEventsDataSyncLogs = (
    
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ListedEventModelArrayEnvelope>(
      {url: `/operative-events-data/sync-logs`, method: 'get'
    },
      options);
    }
  

export const getGetOperativeEventsDataSyncLogsQueryKey = () => [`/operative-events-data/sync-logs`];

    
export type GetOperativeEventsDataSyncLogsQueryResult = NonNullable<AsyncReturnType<typeof getOperativeEventsDataSyncLogs>>
export type GetOperativeEventsDataSyncLogsQueryError = ErrorType<EmptyEnvelope>

export const useGetOperativeEventsDataSyncLogs = <TData = AsyncReturnType<typeof getOperativeEventsDataSyncLogs>, TError = ErrorType<EmptyEnvelope>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getOperativeEventsDataSyncLogs>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetOperativeEventsDataSyncLogsQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getOperativeEventsDataSyncLogs>> = () => getOperativeEventsDataSyncLogs(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getOperativeEventsDataSyncLogs>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getNotificationsAll = (
    
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ListedNotificationModelArrayEnvelope>(
      {url: `/notifications/all`, method: 'get'
    },
      options);
    }
  

export const getGetNotificationsAllQueryKey = () => [`/notifications/all`];

    
export type GetNotificationsAllQueryResult = NonNullable<AsyncReturnType<typeof getNotificationsAll>>
export type GetNotificationsAllQueryError = ErrorType<unknown>

export const useGetNotificationsAll = <TData = AsyncReturnType<typeof getNotificationsAll>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getNotificationsAll>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetNotificationsAllQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getNotificationsAll>> = () => getNotificationsAll(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getNotificationsAll>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangId = (
    projectId: number,
    gangId: number,
    operativeDaysRequestModel: OperativeDaysRequestModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<OperativeDaysModelEnvelope>(
      {url: `/task-assignment/operative-days/get-operative-days/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: operativeDaysRequestModel
    },
      options);
    }
  


    export type PostTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangId>>
    export type PostTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangIdMutationBody = OperativeDaysRequestModel
    export type PostTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangIdMutationError = ErrorType<EmptyEnvelope>

    export const usePostTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangId = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangId>, TError,{projectId: number;gangId: number;data: OperativeDaysRequestModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangId>, {projectId: number;gangId: number;data: OperativeDaysRequestModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentOperativeDaysGetOperativeDaysProjectIdGangId>, TError, {projectId: number;gangId: number;data: OperativeDaysRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonth = (
    projectId: number,
    gangId: number,
    year: number,
    month: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<OperativeHoursWorkedByDateListEnvelope>(
      {url: `/task-assignment/operative-days/get-hours-worked-by-date/${projectId}/${gangId}/${year}/${month}`, method: 'get'
    },
      options);
    }
  

export const getGetTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonthQueryKey = (projectId: number,
    gangId: number,
    year: number,
    month: number,) => [`/task-assignment/operative-days/get-hours-worked-by-date/${projectId}/${gangId}/${year}/${month}`];

    
export type GetTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonthQueryResult = NonNullable<AsyncReturnType<typeof getTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonth>>
export type GetTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonthQueryError = ErrorType<unknown>

export const useGetTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonth = <TData = AsyncReturnType<typeof getTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonth>, TError = ErrorType<unknown>>(
 projectId: number,
    gangId: number,
    year: number,
    month: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonth>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonthQueryKey(projectId,gangId,year,month);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonth>> = () => getTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonth(projectId,gangId,year,month, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonth>, TError, TData>(queryKey, queryFn, {enabled: !!(projectId && gangId && year && month), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const postOperativeEventsAssign = (
    operativeEventAssignmentDto: OperativeEventAssignmentDto,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ListedEventModelArrayEnvelope>(
      {url: `/operative-events/assign`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: operativeEventAssignmentDto
    },
      options);
    }
  


    export type PostOperativeEventsAssignMutationResult = NonNullable<AsyncReturnType<typeof postOperativeEventsAssign>>
    export type PostOperativeEventsAssignMutationBody = OperativeEventAssignmentDto
    export type PostOperativeEventsAssignMutationError = ErrorType<EmptyEnvelope>

    export const usePostOperativeEventsAssign = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postOperativeEventsAssign>, TError,{data: OperativeEventAssignmentDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postOperativeEventsAssign>, {data: OperativeEventAssignmentDto}> = (props) => {
          const {data} = props || {};

          return  postOperativeEventsAssign(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postOperativeEventsAssign>, TError, {data: OperativeEventAssignmentDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const putOperativeEventsUnassign = (
    operativeEventUnassignDto: OperativeEventUnassignDto,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ListedEventModelArrayEnvelope>(
      {url: `/operative-events/unassign`, method: 'put',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: operativeEventUnassignDto
    },
      options);
    }
  


    export type PutOperativeEventsUnassignMutationResult = NonNullable<AsyncReturnType<typeof putOperativeEventsUnassign>>
    export type PutOperativeEventsUnassignMutationBody = OperativeEventUnassignDto
    export type PutOperativeEventsUnassignMutationError = ErrorType<EmptyEnvelope>

    export const usePutOperativeEventsUnassign = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putOperativeEventsUnassign>, TError,{data: OperativeEventUnassignDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putOperativeEventsUnassign>, {data: OperativeEventUnassignDto}> = (props) => {
          const {data} = props || {};

          return  putOperativeEventsUnassign(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof putOperativeEventsUnassign>, TError, {data: OperativeEventUnassignDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getOperativeEventsAssignmentId = (
    assignmentId: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ListedEventModelArrayEnvelope>(
      {url: `/operative-events/${assignmentId}`, method: 'get'
    },
      options);
    }
  

export const getGetOperativeEventsAssignmentIdQueryKey = (assignmentId: number,) => [`/operative-events/${assignmentId}`];

    
export type GetOperativeEventsAssignmentIdQueryResult = NonNullable<AsyncReturnType<typeof getOperativeEventsAssignmentId>>
export type GetOperativeEventsAssignmentIdQueryError = ErrorType<EmptyEnvelope>

export const useGetOperativeEventsAssignmentId = <TData = AsyncReturnType<typeof getOperativeEventsAssignmentId>, TError = ErrorType<EmptyEnvelope>>(
 assignmentId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getOperativeEventsAssignmentId>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetOperativeEventsAssignmentIdQueryKey(assignmentId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getOperativeEventsAssignmentId>> = () => getOperativeEventsAssignmentId(assignmentId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getOperativeEventsAssignmentId>, TError, TData>(queryKey, queryFn, {enabled: !!(assignmentId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getTaskAssignmentOperativesGetGangOperativesProjectIdGangId = (
    projectId: number,
    gangId: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/task-assignment/operatives/get-gang-operatives/${projectId}/${gangId}`, method: 'get'
    },
      options);
    }
  

export const getGetTaskAssignmentOperativesGetGangOperativesProjectIdGangIdQueryKey = (projectId: number,
    gangId: number,) => [`/task-assignment/operatives/get-gang-operatives/${projectId}/${gangId}`];

    
export type GetTaskAssignmentOperativesGetGangOperativesProjectIdGangIdQueryResult = NonNullable<AsyncReturnType<typeof getTaskAssignmentOperativesGetGangOperativesProjectIdGangId>>
export type GetTaskAssignmentOperativesGetGangOperativesProjectIdGangIdQueryError = ErrorType<unknown>

export const useGetTaskAssignmentOperativesGetGangOperativesProjectIdGangId = <TData = AsyncReturnType<typeof getTaskAssignmentOperativesGetGangOperativesProjectIdGangId>, TError = ErrorType<unknown>>(
 projectId: number,
    gangId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTaskAssignmentOperativesGetGangOperativesProjectIdGangId>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTaskAssignmentOperativesGetGangOperativesProjectIdGangIdQueryKey(projectId,gangId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTaskAssignmentOperativesGetGangOperativesProjectIdGangId>> = () => getTaskAssignmentOperativesGetGangOperativesProjectIdGangId(projectId,gangId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTaskAssignmentOperativesGetGangOperativesProjectIdGangId>, TError, TData>(queryKey, queryFn, {enabled: !!(projectId && gangId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getTaskAssignmentOperativesGetAllGangOperativesProjectId = (
    projectId: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<OperativeListingModelPageResultEnvelope>(
      {url: `/task-assignment/operatives/get-all-gang-operatives/${projectId}`, method: 'get'
    },
      options);
    }
  

export const getGetTaskAssignmentOperativesGetAllGangOperativesProjectIdQueryKey = (projectId: number,) => [`/task-assignment/operatives/get-all-gang-operatives/${projectId}`];

    
export type GetTaskAssignmentOperativesGetAllGangOperativesProjectIdQueryResult = NonNullable<AsyncReturnType<typeof getTaskAssignmentOperativesGetAllGangOperativesProjectId>>
export type GetTaskAssignmentOperativesGetAllGangOperativesProjectIdQueryError = ErrorType<EmptyEnvelope>

export const useGetTaskAssignmentOperativesGetAllGangOperativesProjectId = <TData = AsyncReturnType<typeof getTaskAssignmentOperativesGetAllGangOperativesProjectId>, TError = ErrorType<EmptyEnvelope>>(
 projectId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTaskAssignmentOperativesGetAllGangOperativesProjectId>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTaskAssignmentOperativesGetAllGangOperativesProjectIdQueryKey(projectId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTaskAssignmentOperativesGetAllGangOperativesProjectId>> = () => getTaskAssignmentOperativesGetAllGangOperativesProjectId(projectId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTaskAssignmentOperativesGetAllGangOperativesProjectId>, TError, TData>(queryKey, queryFn, {enabled: !!(projectId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const postTaskAssignmentOperativesOwnershipGetOperativesProjectIdGangId = (
    projectId: number,
    gangId: number,
    operativeOwnershipRequestModel: OperativeOwnershipRequestModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<OperativeOwnershipListingModelPageResultEnvelope>(
      {url: `/task-assignment/operatives-ownership/get-operatives/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: operativeOwnershipRequestModel
    },
      options);
    }
  


    export type PostTaskAssignmentOperativesOwnershipGetOperativesProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipGetOperativesProjectIdGangId>>
    export type PostTaskAssignmentOperativesOwnershipGetOperativesProjectIdGangIdMutationBody = OperativeOwnershipRequestModel
    export type PostTaskAssignmentOperativesOwnershipGetOperativesProjectIdGangIdMutationError = ErrorType<EmptyEnvelope>

    export const usePostTaskAssignmentOperativesOwnershipGetOperativesProjectIdGangId = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipGetOperativesProjectIdGangId>, TError,{projectId: number;gangId: number;data: OperativeOwnershipRequestModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipGetOperativesProjectIdGangId>, {projectId: number;gangId: number;data: OperativeOwnershipRequestModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentOperativesOwnershipGetOperativesProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipGetOperativesProjectIdGangId>, TError, {projectId: number;gangId: number;data: OperativeOwnershipRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangId = (
    projectId: number,
    gangId: number,
    operativeOwnershipUpdateModel: OperativeOwnershipUpdateModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/task-assignment/operatives-ownership/borrow-operative/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: operativeOwnershipUpdateModel
    },
      options);
    }
  


    export type PostTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangId>>
    export type PostTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangIdMutationBody = OperativeOwnershipUpdateModel
    export type PostTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangIdMutationError = ErrorType<unknown>

    export const usePostTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangId = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangId>, TError,{projectId: number;gangId: number;data: OperativeOwnershipUpdateModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangId>, {projectId: number;gangId: number;data: OperativeOwnershipUpdateModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipBorrowOperativeProjectIdGangId>, TError, {projectId: number;gangId: number;data: OperativeOwnershipUpdateModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangId = (
    projectId: number,
    gangId: number,
    operativeOwnershipUpdateModel: OperativeOwnershipUpdateModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/task-assignment/operatives-ownership/take-ownership/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: operativeOwnershipUpdateModel
    },
      options);
    }
  


    export type PostTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangId>>
    export type PostTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangIdMutationBody = OperativeOwnershipUpdateModel
    export type PostTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangIdMutationError = ErrorType<unknown>

    export const usePostTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangId = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangId>, TError,{projectId: number;gangId: number;data: OperativeOwnershipUpdateModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangId>, {projectId: number;gangId: number;data: OperativeOwnershipUpdateModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipTakeOwnershipProjectIdGangId>, TError, {projectId: number;gangId: number;data: OperativeOwnershipUpdateModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangId = (
    projectId: number,
    gangId: number,
    operativeOwnershipUpdateModel: OperativeOwnershipUpdateModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/task-assignment/operatives-ownership/remove-borrowed-operative/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: operativeOwnershipUpdateModel
    },
      options);
    }
  


    export type PostTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangId>>
    export type PostTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangIdMutationBody = OperativeOwnershipUpdateModel
    export type PostTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangIdMutationError = ErrorType<unknown>

    export const usePostTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangId = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangId>, TError,{projectId: number;gangId: number;data: OperativeOwnershipUpdateModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangId>, {projectId: number;gangId: number;data: OperativeOwnershipUpdateModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentOperativesOwnershipRemoveBorrowedOperativeProjectIdGangId>, TError, {projectId: number;gangId: number;data: OperativeOwnershipUpdateModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const getProjectAssignmentProjectAssignableUsersGetUsers = (
    
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<UserModelIEnumerableEnvelope>(
      {url: `/project-assignment/project-assignable-users/get-users`, method: 'get'
    },
      options);
    }
  

export const getGetProjectAssignmentProjectAssignableUsersGetUsersQueryKey = () => [`/project-assignment/project-assignable-users/get-users`];

    
export type GetProjectAssignmentProjectAssignableUsersGetUsersQueryResult = NonNullable<AsyncReturnType<typeof getProjectAssignmentProjectAssignableUsersGetUsers>>
export type GetProjectAssignmentProjectAssignableUsersGetUsersQueryError = ErrorType<unknown>

export const useGetProjectAssignmentProjectAssignableUsersGetUsers = <TData = AsyncReturnType<typeof getProjectAssignmentProjectAssignableUsersGetUsers>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getProjectAssignmentProjectAssignableUsersGetUsers>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetProjectAssignmentProjectAssignableUsersGetUsersQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getProjectAssignmentProjectAssignableUsersGetUsers>> = () => getProjectAssignmentProjectAssignableUsersGetUsers(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getProjectAssignmentProjectAssignableUsersGetUsers>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTaskAssignmentProjectsGetGangsAssignedTimeProjectId = (
    projectId: number,
    gangAssignedRequestModel: GangAssignedRequestModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/task-assignment/projects/get-gangs-assigned-time/${projectId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: gangAssignedRequestModel
    },
      options);
    }
  


    export type PostTaskAssignmentProjectsGetGangsAssignedTimeProjectIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentProjectsGetGangsAssignedTimeProjectId>>
    export type PostTaskAssignmentProjectsGetGangsAssignedTimeProjectIdMutationBody = GangAssignedRequestModel
    export type PostTaskAssignmentProjectsGetGangsAssignedTimeProjectIdMutationError = ErrorType<unknown>

    export const usePostTaskAssignmentProjectsGetGangsAssignedTimeProjectId = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentProjectsGetGangsAssignedTimeProjectId>, TError,{projectId: number;data: GangAssignedRequestModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentProjectsGetGangsAssignedTimeProjectId>, {projectId: number;data: GangAssignedRequestModel}> = (props) => {
          const {projectId,data} = props || {};

          return  postTaskAssignmentProjectsGetGangsAssignedTimeProjectId(projectId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentProjectsGetGangsAssignedTimeProjectId>, TError, {projectId: number;data: GangAssignedRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTaskAssignmentProjectsGetProjectsAndGangs = (
    
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/task-assignment/projects/get-projects-and-gangs`, method: 'get'
    },
      options);
    }
  

export const getGetTaskAssignmentProjectsGetProjectsAndGangsQueryKey = () => [`/task-assignment/projects/get-projects-and-gangs`];

    
export type GetTaskAssignmentProjectsGetProjectsAndGangsQueryResult = NonNullable<AsyncReturnType<typeof getTaskAssignmentProjectsGetProjectsAndGangs>>
export type GetTaskAssignmentProjectsGetProjectsAndGangsQueryError = ErrorType<unknown>

export const useGetTaskAssignmentProjectsGetProjectsAndGangs = <TData = AsyncReturnType<typeof getTaskAssignmentProjectsGetProjectsAndGangs>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTaskAssignmentProjectsGetProjectsAndGangs>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTaskAssignmentProjectsGetProjectsAndGangsQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTaskAssignmentProjectsGetProjectsAndGangs>> = () => getTaskAssignmentProjectsGetProjectsAndGangs(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTaskAssignmentProjectsGetProjectsAndGangs>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getTaskAssignmentProjectsGetGangsForUserUserId = (
    userId: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<GangSelectorRefreshModelListEnvelope>(
      {url: `/task-assignment/projects/get-gangs-for-user/${userId}`, method: 'get'
    },
      options);
    }
  

export const getGetTaskAssignmentProjectsGetGangsForUserUserIdQueryKey = (userId: number,) => [`/task-assignment/projects/get-gangs-for-user/${userId}`];

    
export type GetTaskAssignmentProjectsGetGangsForUserUserIdQueryResult = NonNullable<AsyncReturnType<typeof getTaskAssignmentProjectsGetGangsForUserUserId>>
export type GetTaskAssignmentProjectsGetGangsForUserUserIdQueryError = ErrorType<unknown>

export const useGetTaskAssignmentProjectsGetGangsForUserUserId = <TData = AsyncReturnType<typeof getTaskAssignmentProjectsGetGangsForUserUserId>, TError = ErrorType<unknown>>(
 userId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTaskAssignmentProjectsGetGangsForUserUserId>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTaskAssignmentProjectsGetGangsForUserUserIdQueryKey(userId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTaskAssignmentProjectsGetGangsForUserUserId>> = () => getTaskAssignmentProjectsGetGangsForUserUserId(userId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTaskAssignmentProjectsGetGangsForUserUserId>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getTaskAssignmentProjectsGetProjectsSettings = (
    
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ProjectSettingsModelResponseModelListEnvelope | void>(
      {url: `/task-assignment/projects/get-projects-settings`, method: 'get'
    },
      options);
    }
  

export const getGetTaskAssignmentProjectsGetProjectsSettingsQueryKey = () => [`/task-assignment/projects/get-projects-settings`];

    
export type GetTaskAssignmentProjectsGetProjectsSettingsQueryResult = NonNullable<AsyncReturnType<typeof getTaskAssignmentProjectsGetProjectsSettings>>
export type GetTaskAssignmentProjectsGetProjectsSettingsQueryError = ErrorType<unknown>

export const useGetTaskAssignmentProjectsGetProjectsSettings = <TData = AsyncReturnType<typeof getTaskAssignmentProjectsGetProjectsSettings>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTaskAssignmentProjectsGetProjectsSettings>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTaskAssignmentProjectsGetProjectsSettingsQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTaskAssignmentProjectsGetProjectsSettings>> = () => getTaskAssignmentProjectsGetProjectsSettings(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTaskAssignmentProjectsGetProjectsSettings>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getProjectAssignmentProjectSettingsGetProjects = (
    
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ProjectModelListEnvelope>(
      {url: `/project-assignment/project-settings/get-projects`, method: 'get'
    },
      options);
    }
  

export const getGetProjectAssignmentProjectSettingsGetProjectsQueryKey = () => [`/project-assignment/project-settings/get-projects`];

    
export type GetProjectAssignmentProjectSettingsGetProjectsQueryResult = NonNullable<AsyncReturnType<typeof getProjectAssignmentProjectSettingsGetProjects>>
export type GetProjectAssignmentProjectSettingsGetProjectsQueryError = ErrorType<unknown>

export const useGetProjectAssignmentProjectSettingsGetProjects = <TData = AsyncReturnType<typeof getProjectAssignmentProjectSettingsGetProjects>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getProjectAssignmentProjectSettingsGetProjects>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetProjectAssignmentProjectSettingsGetProjectsQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getProjectAssignmentProjectSettingsGetProjects>> = () => getProjectAssignmentProjectSettingsGetProjects(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getProjectAssignmentProjectSettingsGetProjects>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postProjectAssignmentProjectSettingsPostProject = (
    projectSettingsModel: ProjectSettingsModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/project-assignment/project-settings/post-project`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: projectSettingsModel
    },
      options);
    }
  


    export type PostProjectAssignmentProjectSettingsPostProjectMutationResult = NonNullable<AsyncReturnType<typeof postProjectAssignmentProjectSettingsPostProject>>
    export type PostProjectAssignmentProjectSettingsPostProjectMutationBody = ProjectSettingsModel
    export type PostProjectAssignmentProjectSettingsPostProjectMutationError = ErrorType<unknown>

    export const usePostProjectAssignmentProjectSettingsPostProject = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postProjectAssignmentProjectSettingsPostProject>, TError,{data: ProjectSettingsModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postProjectAssignmentProjectSettingsPostProject>, {data: ProjectSettingsModel}> = (props) => {
          const {data} = props || {};

          return  postProjectAssignmentProjectSettingsPostProject(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postProjectAssignmentProjectSettingsPostProject>, TError, {data: ProjectSettingsModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangId = (
    projectId: number,
    gangId: number,
    projectTaskRequestModel: ProjectTaskRequestModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ProjectTaskHierarchyModelArrayEnvelope>(
      {url: `/task-assignment/tasks/get-assigned-project-tasks/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: projectTaskRequestModel
    },
      options);
    }
  


    export type PostTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangId>>
    export type PostTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangIdMutationBody = ProjectTaskRequestModel
    export type PostTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangIdMutationError = ErrorType<EmptyEnvelope>

    export const usePostTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangId = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangId>, TError,{projectId: number;gangId: number;data: ProjectTaskRequestModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangId>, {projectId: number;gangId: number;data: ProjectTaskRequestModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentTasksGetAssignedProjectTasksProjectIdGangId>, TError, {projectId: number;gangId: number;data: ProjectTaskRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTaskAssignmentTasksGetAllProjectTasksProjectIdGangIdProjectTaskHierarchyId = (
    projectId: number,
    gangId: number,
    projectTaskHierarchyId: number,
    projectTaskRequestModel: ProjectTaskRequestModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ProjectTaskModelArrayEnvelope>(
      {url: `/task-assignment/tasks/get-all-project-tasks/${projectId}/${gangId}/${projectTaskHierarchyId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: projectTaskRequestModel
    },
      options);
    }
  


    export type PostTaskAssignmentTasksGetAllProjectTasksProjectIdGangIdProjectTaskHierarchyIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentTasksGetAllProjectTasksProjectIdGangIdProjectTaskHierarchyId>>
    export type PostTaskAssignmentTasksGetAllProjectTasksProjectIdGangIdProjectTaskHierarchyIdMutationBody = ProjectTaskRequestModel
    export type PostTaskAssignmentTasksGetAllProjectTasksProjectIdGangIdProjectTaskHierarchyIdMutationError = ErrorType<EmptyEnvelope>

    export const usePostTaskAssignmentTasksGetAllProjectTasksProjectIdGangIdProjectTaskHierarchyId = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentTasksGetAllProjectTasksProjectIdGangIdProjectTaskHierarchyId>, TError,{projectId: number;gangId: number;projectTaskHierarchyId: number;data: ProjectTaskRequestModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentTasksGetAllProjectTasksProjectIdGangIdProjectTaskHierarchyId>, {projectId: number;gangId: number;projectTaskHierarchyId: number;data: ProjectTaskRequestModel}> = (props) => {
          const {projectId,gangId,projectTaskHierarchyId,data} = props || {};

          return  postTaskAssignmentTasksGetAllProjectTasksProjectIdGangIdProjectTaskHierarchyId(projectId,gangId,projectTaskHierarchyId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentTasksGetAllProjectTasksProjectIdGangIdProjectTaskHierarchyId>, TError, {projectId: number;gangId: number;projectTaskHierarchyId: number;data: ProjectTaskRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskId = (
    projectId: number,
    gangId: number,
    projectTaskId: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ProjectTaskGangAssignedHoursModelArrayEnvelope>(
      {url: `/task-assignment/tasks/get-assigned-hours-breakdown/${projectId}/${gangId}/${projectTaskId}`, method: 'get'
    },
      options);
    }
  

export const getGetTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskIdQueryKey = (projectId: number,
    gangId: number,
    projectTaskId: number,) => [`/task-assignment/tasks/get-assigned-hours-breakdown/${projectId}/${gangId}/${projectTaskId}`];

    
export type GetTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskIdQueryResult = NonNullable<AsyncReturnType<typeof getTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskId>>
export type GetTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskIdQueryError = ErrorType<EmptyEnvelope>

export const useGetTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskId = <TData = AsyncReturnType<typeof getTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskId>, TError = ErrorType<EmptyEnvelope>>(
 projectId: number,
    gangId: number,
    projectTaskId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskId>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskIdQueryKey(projectId,gangId,projectTaskId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskId>> = () => getTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskId(projectId,gangId,projectTaskId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTaskAssignmentTasksGetAssignedHoursBreakdownProjectIdGangIdProjectTaskId>, TError, TData>(queryKey, queryFn, {enabled: !!(projectId && gangId && projectTaskId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const postTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangId = (
    projectId: number,
    gangId: number,
    operativeDayPercentageBreakdownRequestModel: OperativeDayPercentageBreakdownRequestModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<OperativeDayPercentageModelArrayEnvelope>(
      {url: `/task-assignment/tasks/get-operative-day-percentage-breakdown/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: operativeDayPercentageBreakdownRequestModel
    },
      options);
    }
  


    export type PostTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangId>>
    export type PostTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangIdMutationBody = OperativeDayPercentageBreakdownRequestModel
    export type PostTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangIdMutationError = ErrorType<EmptyEnvelope>

    export const usePostTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangId = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangId>, TError,{projectId: number;gangId: number;data: OperativeDayPercentageBreakdownRequestModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangId>, {projectId: number;gangId: number;data: OperativeDayPercentageBreakdownRequestModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentTasksGetOperativeDayPercentageBreakdownProjectIdGangId>, TError, {projectId: number;gangId: number;data: OperativeDayPercentageBreakdownRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTaskAssignmentTasksGetGlobalTasksProjectIdGangId = (
    projectId: number,
    gangId: number,
    projectTaskRequestModel: ProjectTaskRequestModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ProjectTaskListingModelPageResultEnvelope>(
      {url: `/task-assignment/tasks/get-global-tasks/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: projectTaskRequestModel
    },
      options);
    }
  


    export type PostTaskAssignmentTasksGetGlobalTasksProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentTasksGetGlobalTasksProjectIdGangId>>
    export type PostTaskAssignmentTasksGetGlobalTasksProjectIdGangIdMutationBody = ProjectTaskRequestModel
    export type PostTaskAssignmentTasksGetGlobalTasksProjectIdGangIdMutationError = ErrorType<EmptyEnvelope>

    export const usePostTaskAssignmentTasksGetGlobalTasksProjectIdGangId = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentTasksGetGlobalTasksProjectIdGangId>, TError,{projectId: number;gangId: number;data: ProjectTaskRequestModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentTasksGetGlobalTasksProjectIdGangId>, {projectId: number;gangId: number;data: ProjectTaskRequestModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentTasksGetGlobalTasksProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentTasksGetGlobalTasksProjectIdGangId>, TError, {projectId: number;gangId: number;data: ProjectTaskRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTaskAssignmentTasksGetExceptionTasksProjectIdGangId = (
    projectId: number,
    gangId: number,
    projectTaskRequestModel: ProjectTaskRequestModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ProjectTaskListingModelPageResultEnvelope>(
      {url: `/task-assignment/tasks/get-exception-tasks/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: projectTaskRequestModel
    },
      options);
    }
  


    export type PostTaskAssignmentTasksGetExceptionTasksProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentTasksGetExceptionTasksProjectIdGangId>>
    export type PostTaskAssignmentTasksGetExceptionTasksProjectIdGangIdMutationBody = ProjectTaskRequestModel
    export type PostTaskAssignmentTasksGetExceptionTasksProjectIdGangIdMutationError = ErrorType<EmptyEnvelope>

    export const usePostTaskAssignmentTasksGetExceptionTasksProjectIdGangId = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentTasksGetExceptionTasksProjectIdGangId>, TError,{projectId: number;gangId: number;data: ProjectTaskRequestModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentTasksGetExceptionTasksProjectIdGangId>, {projectId: number;gangId: number;data: ProjectTaskRequestModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentTasksGetExceptionTasksProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentTasksGetExceptionTasksProjectIdGangId>, TError, {projectId: number;gangId: number;data: ProjectTaskRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTaskAssignmentTasksSearchProjectTasksProjectIdGangId = (
    projectId: number,
    gangId: number,
    projectTaskRequestModel: ProjectTaskRequestModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ProjectTaskHierarchyModelArrayEnvelope>(
      {url: `/task-assignment/tasks/search-project-tasks/${projectId}/${gangId}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: projectTaskRequestModel
    },
      options);
    }
  


    export type PostTaskAssignmentTasksSearchProjectTasksProjectIdGangIdMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentTasksSearchProjectTasksProjectIdGangId>>
    export type PostTaskAssignmentTasksSearchProjectTasksProjectIdGangIdMutationBody = ProjectTaskRequestModel
    export type PostTaskAssignmentTasksSearchProjectTasksProjectIdGangIdMutationError = ErrorType<EmptyEnvelope>

    export const usePostTaskAssignmentTasksSearchProjectTasksProjectIdGangId = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentTasksSearchProjectTasksProjectIdGangId>, TError,{projectId: number;gangId: number;data: ProjectTaskRequestModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentTasksSearchProjectTasksProjectIdGangId>, {projectId: number;gangId: number;data: ProjectTaskRequestModel}> = (props) => {
          const {projectId,gangId,data} = props || {};

          return  postTaskAssignmentTasksSearchProjectTasksProjectIdGangId(projectId,gangId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentTasksSearchProjectTasksProjectIdGangId>, TError, {projectId: number;gangId: number;data: ProjectTaskRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList = (
    projectId: number,
    gangId: number,
    projectTaskId: number,
    includeInTaskList: boolean,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/task-assignment/tasks/update-project-task-task-list-inclusion/${projectId}/${gangId}/${projectTaskId}/${includeInTaskList}`, method: 'post'
    },
      options);
    }
  


    export type PostTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskListMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList>>
    
    export type PostTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskListMutationError = ErrorType<unknown>

    export const usePostTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList>, TError,{projectId: number;gangId: number;projectTaskId: number;includeInTaskList: boolean}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList>, {projectId: number;gangId: number;projectTaskId: number;includeInTaskList: boolean}> = (props) => {
          const {projectId,gangId,projectTaskId,includeInTaskList} = props || {};

          return  postTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList(projectId,gangId,projectTaskId,includeInTaskList,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList>, TError, {projectId: number;gangId: number;projectTaskId: number;includeInTaskList: boolean}, TContext>(mutationFn, mutationOptions)
    }
    
export const getTaskAssignmentTasksGetTaskNameTaskId = (
    taskId: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<StringEnvelope>(
      {url: `/task-assignment/tasks/get-task-name/${taskId}`, method: 'get'
    },
      options);
    }
  

export const getGetTaskAssignmentTasksGetTaskNameTaskIdQueryKey = (taskId: number,) => [`/task-assignment/tasks/get-task-name/${taskId}`];

    
export type GetTaskAssignmentTasksGetTaskNameTaskIdQueryResult = NonNullable<AsyncReturnType<typeof getTaskAssignmentTasksGetTaskNameTaskId>>
export type GetTaskAssignmentTasksGetTaskNameTaskIdQueryError = ErrorType<unknown>

export const useGetTaskAssignmentTasksGetTaskNameTaskId = <TData = AsyncReturnType<typeof getTaskAssignmentTasksGetTaskNameTaskId>, TError = ErrorType<unknown>>(
 taskId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTaskAssignmentTasksGetTaskNameTaskId>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTaskAssignmentTasksGetTaskNameTaskIdQueryKey(taskId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTaskAssignmentTasksGetTaskNameTaskId>> = () => getTaskAssignmentTasksGetTaskNameTaskId(taskId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTaskAssignmentTasksGetTaskNameTaskId>, TError, TData>(queryKey, queryFn, {enabled: !!(taskId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const postTaskAssignmentTasksUpdateAssignedHours = (
    
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<ProjectTaskHierarchyModelArrayEnvelope>(
      {url: `/task-assignment/tasks/update-assigned-hours`, method: 'post'
    },
      options);
    }
  


    export type PostTaskAssignmentTasksUpdateAssignedHoursMutationResult = NonNullable<AsyncReturnType<typeof postTaskAssignmentTasksUpdateAssignedHours>>
    
    export type PostTaskAssignmentTasksUpdateAssignedHoursMutationError = ErrorType<EmptyEnvelope>

    export const usePostTaskAssignmentTasksUpdateAssignedHours = <TError = ErrorType<EmptyEnvelope>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postTaskAssignmentTasksUpdateAssignedHours>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postTaskAssignmentTasksUpdateAssignedHours>, TVariables> = () => {
          ;

          return  postTaskAssignmentTasksUpdateAssignedHours(requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postTaskAssignmentTasksUpdateAssignedHours>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postProjectAssignmentPromotionGetOperatives = (
    searchModel: SearchModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<OperativeModelPageResultEnvelope>(
      {url: `/project-assignment/promotion/get-operatives`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: searchModel
    },
      options);
    }
  


    export type PostProjectAssignmentPromotionGetOperativesMutationResult = NonNullable<AsyncReturnType<typeof postProjectAssignmentPromotionGetOperatives>>
    export type PostProjectAssignmentPromotionGetOperativesMutationBody = SearchModel
    export type PostProjectAssignmentPromotionGetOperativesMutationError = ErrorType<EmptyEnvelope>

    export const usePostProjectAssignmentPromotionGetOperatives = <TError = ErrorType<EmptyEnvelope>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postProjectAssignmentPromotionGetOperatives>, TError,{data: SearchModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postProjectAssignmentPromotionGetOperatives>, {data: SearchModel}> = (props) => {
          const {data} = props || {};

          return  postProjectAssignmentPromotionGetOperatives(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postProjectAssignmentPromotionGetOperatives>, TError, {data: SearchModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postProjectAssignmentPromotionPromoteToSupervisor = (
    operativePromotionModel: OperativePromotionModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/project-assignment/promotion/promote-to-supervisor`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: operativePromotionModel
    },
      options);
    }
  


    export type PostProjectAssignmentPromotionPromoteToSupervisorMutationResult = NonNullable<AsyncReturnType<typeof postProjectAssignmentPromotionPromoteToSupervisor>>
    export type PostProjectAssignmentPromotionPromoteToSupervisorMutationBody = OperativePromotionModel
    export type PostProjectAssignmentPromotionPromoteToSupervisorMutationError = ErrorType<unknown>

    export const usePostProjectAssignmentPromotionPromoteToSupervisor = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postProjectAssignmentPromotionPromoteToSupervisor>, TError,{data: OperativePromotionModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postProjectAssignmentPromotionPromoteToSupervisor>, {data: OperativePromotionModel}> = (props) => {
          const {data} = props || {};

          return  postProjectAssignmentPromotionPromoteToSupervisor(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof postProjectAssignmentPromotionPromoteToSupervisor>, TError, {data: OperativePromotionModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const getSupervisorOwnershipSuperusersGetUsers = (
    
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<UserResponseModelIEnumerableEnvelope>(
      {url: `/supervisor-ownership/superusers/get-users`, method: 'get'
    },
      options);
    }
  

export const getGetSupervisorOwnershipSuperusersGetUsersQueryKey = () => [`/supervisor-ownership/superusers/get-users`];

    
export type GetSupervisorOwnershipSuperusersGetUsersQueryResult = NonNullable<AsyncReturnType<typeof getSupervisorOwnershipSuperusersGetUsers>>
export type GetSupervisorOwnershipSuperusersGetUsersQueryError = ErrorType<unknown>

export const useGetSupervisorOwnershipSuperusersGetUsers = <TData = AsyncReturnType<typeof getSupervisorOwnershipSuperusersGetUsers>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getSupervisorOwnershipSuperusersGetUsers>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetSupervisorOwnershipSuperusersGetUsersQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getSupervisorOwnershipSuperusersGetUsers>> = () => getSupervisorOwnershipSuperusersGetUsers(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getSupervisorOwnershipSuperusersGetUsers>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserId = (
    userId: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<SuperUserSupervisorOwnershipCollectionModelEnvelope>(
      {url: `/supervisor-ownership/super-user-supervisor-ownership/get-ownerships/${userId}`, method: 'get'
    },
      options);
    }
  

export const getGetSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserIdQueryKey = (userId: number,) => [`/supervisor-ownership/super-user-supervisor-ownership/get-ownerships/${userId}`];

    
export type GetSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserIdQueryResult = NonNullable<AsyncReturnType<typeof getSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserId>>
export type GetSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserIdQueryError = ErrorType<EmptyEnvelope>

export const useGetSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserId = <TData = AsyncReturnType<typeof getSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserId>, TError = ErrorType<EmptyEnvelope>>(
 userId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserId>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserIdQueryKey(userId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserId>> = () => getSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserId(userId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getSupervisorOwnershipSuperUserSupervisorOwnershipGetOwnershipsUserId>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const putSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserId = (
    userId: number,
    superUserSupervisorOwnershipCollectionModel: SuperUserSupervisorOwnershipCollectionModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/supervisor-ownership/super-user-supervisor-ownership/update-ownerships/${userId}`, method: 'put',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: superUserSupervisorOwnershipCollectionModel
    },
      options);
    }
  


    export type PutSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserIdMutationResult = NonNullable<AsyncReturnType<typeof putSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserId>>
    export type PutSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserIdMutationBody = SuperUserSupervisorOwnershipCollectionModel
    export type PutSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserIdMutationError = ErrorType<unknown>

    export const usePutSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserId = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserId>, TError,{userId: number;data: SuperUserSupervisorOwnershipCollectionModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserId>, {userId: number;data: SuperUserSupervisorOwnershipCollectionModel}> = (props) => {
          const {userId,data} = props || {};

          return  putSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserId(userId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof putSupervisorOwnershipSuperUserSupervisorOwnershipUpdateOwnershipsUserId>, TError, {userId: number;data: SuperUserSupervisorOwnershipCollectionModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const getProjectAssignmentUserProjectAssignmentGetAssignmentsUserId = (
    userId: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<UserProjectAssignmentCollectionModelEnvelope>(
      {url: `/project-assignment/user-project-assignment/get-assignments/${userId}`, method: 'get'
    },
      options);
    }
  

export const getGetProjectAssignmentUserProjectAssignmentGetAssignmentsUserIdQueryKey = (userId: number,) => [`/project-assignment/user-project-assignment/get-assignments/${userId}`];

    
export type GetProjectAssignmentUserProjectAssignmentGetAssignmentsUserIdQueryResult = NonNullable<AsyncReturnType<typeof getProjectAssignmentUserProjectAssignmentGetAssignmentsUserId>>
export type GetProjectAssignmentUserProjectAssignmentGetAssignmentsUserIdQueryError = ErrorType<EmptyEnvelope>

export const useGetProjectAssignmentUserProjectAssignmentGetAssignmentsUserId = <TData = AsyncReturnType<typeof getProjectAssignmentUserProjectAssignmentGetAssignmentsUserId>, TError = ErrorType<EmptyEnvelope>>(
 userId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getProjectAssignmentUserProjectAssignmentGetAssignmentsUserId>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetProjectAssignmentUserProjectAssignmentGetAssignmentsUserIdQueryKey(userId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getProjectAssignmentUserProjectAssignmentGetAssignmentsUserId>> = () => getProjectAssignmentUserProjectAssignmentGetAssignmentsUserId(userId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getProjectAssignmentUserProjectAssignmentGetAssignmentsUserId>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getProjectAssignmentUserProjectAssignmentGetUserUserId = (
    userId: number,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<UserDetailModelEnvelope>(
      {url: `/project-assignment/user-project-assignment/get-user/${userId}`, method: 'get'
    },
      options);
    }
  

export const getGetProjectAssignmentUserProjectAssignmentGetUserUserIdQueryKey = (userId: number,) => [`/project-assignment/user-project-assignment/get-user/${userId}`];

    
export type GetProjectAssignmentUserProjectAssignmentGetUserUserIdQueryResult = NonNullable<AsyncReturnType<typeof getProjectAssignmentUserProjectAssignmentGetUserUserId>>
export type GetProjectAssignmentUserProjectAssignmentGetUserUserIdQueryError = ErrorType<EmptyEnvelope>

export const useGetProjectAssignmentUserProjectAssignmentGetUserUserId = <TData = AsyncReturnType<typeof getProjectAssignmentUserProjectAssignmentGetUserUserId>, TError = ErrorType<EmptyEnvelope>>(
 userId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getProjectAssignmentUserProjectAssignmentGetUserUserId>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetProjectAssignmentUserProjectAssignmentGetUserUserIdQueryKey(userId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getProjectAssignmentUserProjectAssignmentGetUserUserId>> = () => getProjectAssignmentUserProjectAssignmentGetUserUserId(userId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getProjectAssignmentUserProjectAssignmentGetUserUserId>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const putProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserId = (
    userId: number,
    userProjectAssignmentCollectionModel: UserProjectAssignmentCollectionModel,
 options?: SecondParameter<typeof customInstance>) => {
      return customInstance<EmptyEnvelope>(
      {url: `/project-assignment/user-project-assignment/update-assignments/${userId}`, method: 'put',
      headers: {'Content-Type': 'application/json-patch+json'},
      data: userProjectAssignmentCollectionModel
    },
      options);
    }
  


    export type PutProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserIdMutationResult = NonNullable<AsyncReturnType<typeof putProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserId>>
    export type PutProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserIdMutationBody = UserProjectAssignmentCollectionModel
    export type PutProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserIdMutationError = ErrorType<unknown>

    export const usePutProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserId = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserId>, TError,{userId: number;data: UserProjectAssignmentCollectionModel}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserId>, {userId: number;data: UserProjectAssignmentCollectionModel}> = (props) => {
          const {userId,data} = props || {};

          return  putProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserId(userId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof putProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserId>, TError, {userId: number;data: UserProjectAssignmentCollectionModel}, TContext>(mutationFn, mutationOptions)
    }
    
