import { Field } from "formik";
import React from "react";

export interface SelectOption {
  value: string;
  text?: string;
}

type SelectBlockProps = {
  id: string;
  name: string;
  options: SelectOption[];
  label: string;
};

export const SelectBlock: React.FC<SelectBlockProps> = (props) => {
  const { id, name, label, options } = props;
  return (
    <tr>
      <td className="dropdown">
        <Field id={id} name={name} as="select">
          {options &&
            options.map((item) => (
              <option key={item.value} value={item.value}>{item.text || item.value}</option>
            ))}
        </Field>
      </td>
      <td className="project-name">
        <label htmlFor={id}>{label}</label>
      </td>
    </tr>
  );
};
