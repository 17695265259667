import React, { useRef, useState, useEffect, useCallback } from "react";
import { useOutsideClick } from "@chakra-ui/hooks";

export interface TopBarModalProps {
  children: any;
  openModal: boolean;
  onCloseModal: any;
}

export const TopBarModal: React.FC<TopBarModalProps> = (props) => {
  const { children, openModal, onCloseModal } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    if (isModalOpen) {
      setIsModalOpen(false);
      onCloseModal();
    }
  }, [isModalOpen, onCloseModal]);

  useOutsideClick({
    ref: ref,
    handler: () => closeModal(),
  });

  useEffect(() => {
    setIsModalOpen(openModal);
    if (!openModal) {
      onCloseModal();
    }
    // eslint-disable-next-line
  }, [openModal]);

  return <>{isModalOpen && <div ref={ref}>{children}</div>}</>;
};
