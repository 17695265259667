import React, { useState, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/SlickNew/slick.css';

import change_week from '../../styles/Images/help-cards/task-assignment/change-week.svg';
import enter_inspector_mode from '../../styles/Images/help-cards/task-assignment/enter-inspector-mode.svg';
import choose_operative_weeks from '../../styles/Images/help-cards/task-assignment/choose-operative-weeks.svg';
import choose_a_task from '../../styles/Images/help-cards/task-assignment/choose-a-task.svg';
import choose_percentage from '../../styles/Images/help-cards/task-assignment/choose-percentage.svg';
import project_assignment from '../../styles/Images/help-cards/project-assignment/project-assignment.svg';

interface myProps {
  onCloseModal: any;
}

const taskAssignmentHelpCards = [
  {
    image: change_week,
    narrativeHtml:
      'Use the week navigator to change weeks. Tap anywhere on the date to bring up a more detailed picker and choose a specific date.'
  },
  {
    image: enter_inspector_mode,
    narrativeHtml:
      'Tap the inspector button to view the tasks assigned to operatives.'
  },
  {
    image: choose_operative_weeks,
    narrativeHtml: 'Tap on one or more operative days to make your selection.'
  },
  {
    image: choose_a_task,
    narrativeHtml:
      'After selecting operative days, browse the task and event list to choose a task or event to assign to operatives. You can also use the task search to find a specific task.'
  },
  {
    image: choose_percentage,
    narrativeHtml:
      "Choose how much operatives' time to assign to your chosen task. Add comments or delay details if appropriate, and finally tap save to complete the task assignment."
  }
];

const projectAssignmentHelpCards = [
  {
    image: project_assignment,
    narrativeHtml:
      'Select a user and then assign to one or more projects as supervisor or super user.'
  }
];

const sliderSettings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const HelpCardModal: React.FC<myProps> = (props) => {
  const { onCloseModal } = props;
  const [skipLinkText, setSkipLinkText] = useState('');

  const location = useLocation();
  const pageSource = location.pathname.split('/')[1];

  const handleAfterChange = useCallback((sliderdata: any, index: number) => {
    const isLastSlide = sliderdata.length - 1 === index;
    setSkipLinkText(isLastSlide ? 'OK, let’s go' : 'Skip');
  }, []);
  useEffect(() => {
    handleAfterChange(
      pageSource === 'tasks'
        ? taskAssignmentHelpCards
        : projectAssignmentHelpCards,
      0
    );
  }, [pageSource, handleAfterChange]);

  const getSliderData = useCallback((sliderData: any) => {
    return sliderData.map((slide: any, index: number) => {
      return (
        <div className='help-card' key={index}>
          <img className='help-card__image' src={slide.image} alt='' />
          <div
            className='help-card__narrative'
            style={{
              minHeight: '120px',
              lineHeight: '32px',
              backgroundColor: '#FFF',
              padding: '10px 10px'
            }}
          >
            {slide.narrativeHtml}
          </div>
        </div>
      );
    });
  }, []);
  return (
    <div
      className='backdrop'
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1
      }}
    >
      <div className='help-cards help-cards--overlay-dimmed'>
        <div className='help-cards__container'>
          <Slider
            {...sliderSettings}
            afterChange={(index: number) =>
              handleAfterChange(
                pageSource === 'tasks'
                  ? taskAssignmentHelpCards
                  : projectAssignmentHelpCards,
                index
              )
            }
          >
            {getSliderData(
              pageSource === 'tasks'
                ? taskAssignmentHelpCards
                : projectAssignmentHelpCards
            )}
          </Slider>
          <a href='/#' onClick={onCloseModal} className='help-cards__skip'>
            {skipLinkText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default HelpCardModal;
