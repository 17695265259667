import { OperativeDaysCellModel, OperativeEventData } from 'api/model';

const getAssignmentCellsValidModel = (
  selectedCells: OperativeDaysCellModel[],
  eventId: number
) => {
  const ignoredCells = selectedCells?.filter(
    (detailedCell) =>
      !detailedCell?.Events?.some((event) => event.EventId === eventId)
  );

  const cellsMappedArray = ignoredCells.map((cell) => {
    return {
      OperativeId: cell.OperativeId || 0,
      CellDates: [cell.Date || '']
    };
  });

  const cellsHashmap = cellsMappedArray.reduce(
    (obj: { [key: string]: OperativeEventData }, item) => {
      obj[item.OperativeId]
        ? obj[item.OperativeId].CellDates?.push(...item.CellDates)
        : (obj[item.OperativeId] = { ...item });
      return obj;
    },
    {}
  );

  const result = Object.values(cellsHashmap);
  return result;
};

export default getAssignmentCellsValidModel;
