import classNames from "classnames";
import React from "react";

type ToggleButtonProps = {
  checked: boolean;
  toggleOnLabel?: string;
  toggleOffLabel?: string;
  isWide?: boolean;

  onToggle?: () => void;
};

export const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const { checked, isWide, toggleOnLabel, toggleOffLabel, onToggle } = props;
  return (
    <div className="toggle-button">
      <div
        className={classNames("toggle btn btn-info", {
          off: !checked,
          "toggle-btn-wide": isWide,
        })}
      >
        <div className="toggle-group" onClick={onToggle}>
          <label className="btn btn-info toggle-on">{toggleOnLabel}</label>
          <label className="btn btn-default active toggle-off">
            {toggleOffLabel}
          </label>
          <span className="toggle-handle btn btn-default"></span>
        </div>
      </div>
    </div>
  );
};
