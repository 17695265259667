import { useGetTaskAssignmentProjectsGetProjectsAndGangs } from "api/opcs";

const useGetProjectsAndGangs = () =>
  useGetTaskAssignmentProjectsGetProjectsAndGangs({
    query: {
      select: (data) => data?.Data ?? [],
    },
  });

export default useGetProjectsAndGangs;
