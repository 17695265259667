import { useContext, useRef, useState } from 'react';
import Modal from 'react-modal';

import { Button } from './Button';
// import EyeIcon from './iconComponents/EyeIcon';
import CloseIcon from './iconComponents/CloseIcon';
import LockIcon from './iconComponents/LockIcon';
import type {
  ListedEventModel,
  OperativeEventAssignmentDto,
  OperativeEventUnassignDto
} from 'api/model';
import {
  usePostOperativeEventsAssign,
  usePutOperativeEventsUnassign
} from 'api/opcs';
import { NotificationContext, TasksContext } from 'core/context';
import { NotificationType } from 'core/context/NotificationContext.types';
import { useCalendarContext } from 'core/context/CalendarContext';
import { useGetCalendar } from 'features/tasks/components/hooks';
import isValidHex from 'core/utilities/isValidHex';
import getAssignmentCellsValidModel from 'core/utilities/getAssignmentCellsValidModel';
import ErrorModal from './modals/ErrorModal';
import DeleteEventModal from './modals/DeleteEventModal';
import useGetEventsByAssignmentId from 'features/tasks/components/hooks/useGetEventsByAssignmentId';

interface EventRowProps {
  event: ListedEventModel;
  isInspectionMode: boolean;
  hasPastDates?: boolean;
  color?: string;
}

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99999,
    borderRadius: 0,
    border: 0,
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.25',
    maxWidth: '400px'
  },
  overlay: {
    backgroundColor: 'transparent',
    zIndex: 9999
  }
};

const deleteModalStyles = {
  content: {
    width: '100%',
    top: 'auto',
    left: '0',
    bottom: '0',
    zIndex: 99999,
    borderRadius: 0,
    border: 0,
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.25'
  },
  overlay: {
    backgroundColor: 'transparent',
    zIndex: 9999
  }
};

Modal.setAppElement('#root');

const EventRow = ({
  event,
  isInspectionMode,
  hasPastDates = false,
  color = '#333'
}: EventRowProps) => {
  const { addNotification } = useContext(NotificationContext);
  const taskCtx = useContext(TasksContext);
  const {
    selectedDetailedCells,
    clearSelectedDetailedCells,
    clearSelectedTasks,
    clearSelectedCell,
    selectedTasks,
    selectedCell
  } = taskCtx;
  const [errorModalState, setErrorModalState] = useState({
    open: false,
    message: ''
  });
  const [deleteEventModalOpen, setDeleteEventModalOpen] = useState(false);
  const [buttonSaveDisabled, setButtonSaveDisabled] = useState(false);
  const eventsDataRef = useRef<ListedEventModel[] | null>(null);

  // fetch hooks
  const { mutate } = usePutOperativeEventsUnassign();
  const { searchParams } = useCalendarContext();
  const { refetch: calendarRefetch, isSuccess: isCalendarRefetchSuccess } =
    useGetCalendar(searchParams, false);
  const mutation = usePostOperativeEventsAssign();
  const matchedEvent = selectedCell?.Events?.find(
    (e) => e.EventId === event.Id
  );
  const { refetch: fetchEvents } = useGetEventsByAssignmentId(
    matchedEvent?.EventAssignmentID || 0
  );

  const hasSelection = selectedTasks.size > 0;
  const isInternal = event.Type === 'Internal';

  // handlers
  const handleOpenDatesModal = () => {
    setErrorModalState({
      open: true,
      message:
        'You are only able to assign availability to future days; please unselect any past dates.'
    });
  };

  const handleOpenErrorModal = () => {
    setErrorModalState({
      open: true,
      message:
        'You do not have permission to delete this event; please contact an admin'
    });
  };

  const handleOpenDeleteModal = async () => {
    const { data, isSuccess, isFetching, isLoading } = await fetchEvents();

    console.log({
      isSuccess,
      isFetching,
      isLoading
    });
    console.log({ data });
    if (isSuccess) {
      eventsDataRef.current = data;
      if (data?.length === 1) {
        const eventToDelete: OperativeEventUnassignDto = {
          AssignmentId: matchedEvent?.EventAssignmentID,
          EventId: matchedEvent?.EventId,
          OperativeId: selectedCell?.OperativeId,
          EventDate: selectedCell?.Date,
          UnassignFutureEvents: false
        };

        mutate(
          {
            data: eventToDelete
          },
          {
            onSuccess: () => {
              addNotification({
                Type: NotificationType.Success,
                Text: 'Your changes have been saved successfully.'
              });
              calendarRefetch();
              if (isCalendarRefetchSuccess) {
                clearSelectedDetailedCells();
                clearSelectedTasks();
                clearSelectedCell();
              }
            },
            onError: () => {
              addNotification({
                Type: NotificationType.Error,
                Text: 'There was an error saving the changes. Please try again later.'
              });
            }
          }
        );
      } else {
        setDeleteEventModalOpen(true);
      }
    } else {
      addNotification({
        Type: NotificationType.Error,
        Text: 'There was an error saving the changes. Please try again later.'
      });
    }
  };

  const handleCloseModal = () => {
    if (errorModalState.open) {
      setErrorModalState({ open: false, message: '' });
    }

    if (deleteEventModalOpen) {
      setDeleteEventModalOpen(false);
    }
  };

  const handleAssignEvent = () => {
    if (!selectedDetailedCells?.length) {
      setButtonSaveDisabled(false);
      return;
    }

    const cellData = getAssignmentCellsValidModel(
      selectedDetailedCells,
      event.Id || 0
    );

    const eventToAssign: OperativeEventAssignmentDto = {
      EventName: event.Name || 'Unnamed Event',
      EventId: event.Id,
      Data: cellData
    };

    if (!eventToAssign.Data?.length) {
      addNotification({
        Type: NotificationType.Error,
        Text: 'This event is already assigned to this Operative.'
      });

      setButtonSaveDisabled(false);
      return;
    }

    mutation.mutate(
      { data: eventToAssign },
      {
        onSuccess: () => {
          addNotification({
            Type: NotificationType.Success,
            Text: 'Your changes have been saved successfully.'
          });
          calendarRefetch();
          if (isCalendarRefetchSuccess) {
            clearSelectedDetailedCells();
            clearSelectedTasks();
            clearSelectedCell();
          }
        },
        onError: () => {
          addNotification({
            Type: NotificationType.Error,
            Text: 'There was an error saving the changes. Please try again later.'
          });
        },
        "onSettled": () =>{
          setButtonSaveDisabled(false);
        }
      }
    );
  };

  return (
    <div className='info-row info-row-event'>
      <div
        className='event'
        style={{
          backgroundColor: isValidHex(color.trim() || '') ? color : '#333'
        }}
      />
      <div className='event-title'>{event.Name || 'Unnamed Event'}</div>
      {hasSelection && !isInspectionMode && !hasPastDates ? (
        <Button
          colour='yellow'
          className={`event-button ${buttonSaveDisabled ? 'event-textButton' : 'event-textButton-disabled'}`}
          disabled={buttonSaveDisabled}
          onClick={() =>
            {
              if (!buttonSaveDisabled) {
                setButtonSaveDisabled(true);
                handleAssignEvent();
              }
            }}
        >
          + Assign
        </Button>
      ) : null}

      {hasSelection && !isInspectionMode && hasPastDates ? (
        <Button
          colour='yellow'
          className='event-button event-textButton event-textButton-disabled'
          onClick={handleOpenDatesModal}
        >
          + Assign
        </Button>
      ) : null}

      {hasSelection && isInspectionMode ? (
        <>
          {/* <Button colour='yellow' className='event-button event-iconButton'>
            <EyeIcon />
          </Button> */}
          {isInternal && hasPastDates && (
            <Button
              colour='yellow'
              className='event-button event-iconButton disabled event-iconButton-disabled'
              onClick={handleOpenErrorModal}
              iconOnly
            >
              <LockIcon />
            </Button>
          )}
          {isInternal && !hasPastDates && (
            <Button
              colour='yellow'
              className='event-button event-iconButton'
              disabled={hasPastDates}
              iconOnly
              onClick={handleOpenDeleteModal}
            >
              <CloseIcon />
            </Button>
          )}

          {!isInternal && hasPastDates && (
            <Button
              colour='yellow'
              className='event-button event-iconButton disabled event-iconButton-disabled'
              onClick={handleOpenErrorModal}
              iconOnly
            >
              <LockIcon />
            </Button>
          )}
          {!isInternal && !hasPastDates && (
            <Button
              colour='yellow'
              className='event-button event-iconButton disabled event-iconButton-disabled'
              onClick={handleOpenErrorModal}
              iconOnly
            >
              <LockIcon />
            </Button>
          )}
        </>
      ) : null}
      <Modal
        isOpen={errorModalState.open}
        onRequestClose={handleCloseModal}
        contentLabel='Error modal'
        style={modalStyles}
      >
        <ErrorModal
          errorMessage={errorModalState.message}
          onModalClose={handleCloseModal}
        />
      </Modal>
      <Modal
        isOpen={deleteEventModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel='Delete event'
        style={deleteModalStyles}
      >
        <DeleteEventModal
          currentEvent={event}
          onModalClose={handleCloseModal}
          fetchedEvents={eventsDataRef.current || []}
        />
      </Modal>
    </div>
  );
};

export default EventRow;
