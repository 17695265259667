import {
  OperativeAssignmentModel,
  OperativeDayPercentageModel,
  OperativeDaysCellModel,
  OperativeDaysModel,
  OperativeDaysRowModel,
  OperativeEventsCellAssignmentModel
} from 'api/model';
import classNames from 'classnames';
import { EmptyList, Loader } from 'core/components';
import { TasksContext } from 'core/context';
import isValidHex from 'core/utilities/isValidHex';
import { addDays, format, isBefore, isEqual, parseISO } from 'date-fns';
import { sum } from 'rambda';
import { useCallback, useContext, useEffect, useState, useMemo } from 'react';

export type SelectedCell = {
  date: string;
  operativeId: string;
};

interface CellIconsProps {
  cell: OperativeDaysCellModel;
  data: OperativeDaysModel;
}

interface CellEventProps {
  cell: OperativeDaysCellModel;
}

interface TotalDelayLabelProps {
  cell: OperativeDaysCellModel;
  data: OperativeDaysModel;
  toggler: Boolean;
}

type AssignmentCalendarProps = {
  data: OperativeDaysModel;
  isLoading: boolean;
  dayBreakdowns?: OperativeDayPercentageModel[];
  week?: string;
  onAddOperative?: () => void;
  onSelectCell?: (
    selectedCells: Set<string>,
    operativeAssignments: OperativeAssignmentModel[]
  ) => void;
};

// utilities
const getTooltip = (cell: OperativeDaysCellModel): string => {
  const borrowingGangs = [];
  const borrowingGangIds = [];

  const selectedGangId = cell.SelectedGangId;
  const assigningGangs = cell.Assignments;

  if (assigningGangs !== undefined) {
    for (const gang of assigningGangs) {
      if (
        gang.GangId !== selectedGangId &&
        borrowingGangIds.indexOf(gang.GangId) < 0
      ) {
        borrowingGangs.push(gang.Gang);
        borrowingGangIds.push(gang.GangId);
      }
    }
  }

  switch (borrowingGangs.length) {
    case 0:
      return 'Time has not been assigned to any other gangs on this day';
    case 1:
      return `Time has been assigned by ${borrowingGangs[0]} on this day`;
    default:
      const lastGang = borrowingGangs.pop();
      return `Time has been assigned by ${borrowingGangs.join(
        ', '
      )} and ${lastGang} on this day`;
  }
};

const getRelevantTaskAssignments = (
  cell: OperativeDaysCellModel,
  data: OperativeDaysModel
) => {
  const taskAssignmentIds = cell?.Assignments?.map(
    (assignment) => assignment.ProjectTaskAssignmentId
  );
  return data.TaskAssignments?.filter((taskAssignment) =>
    taskAssignmentIds?.includes(taskAssignment.Id)
  );
};

const getDelayHours = (
  cell: OperativeDaysCellModel,
  data: OperativeDaysModel
): number => {
  let retVal = 0;
  if (cell.Assignments && cell.Assignments.length > 0) {
    const relevantTaskAssignments = getRelevantTaskAssignments(cell, data);
    const totalDelay =
      relevantTaskAssignments?.reduce(
        (prev, curr) => prev + (curr?.DelayInHoursPerOperative || 0),
        0
      ) || 0;
    retVal =
      cell.HasHoursWorked && cell.GeneralHoursWorked! < totalDelay
        ? cell.GeneralHoursWorked!
        : totalDelay;
  }
  return retVal;
};

const getPercentageAssigned = (cell: OperativeDaysCellModel) => {
  return sum(cell.Assignments!.map((cell) => cell.Percentage!)) || 0;
};

const getDefaultCellCssClass = (cell: OperativeDaysCellModel) => {
  if (cell.HoursWorkedDataAvailable && cell.ShiftType !== 1) {
    return 'allocation-percentage-none';
  } else {
    return 'allocation-percentage-' + getPercentageAssigned(cell).toString();
  }
};

const isDateBefore = (cell: OperativeDaysCellModel) => {
  const parsedDate = parseISO(cell.Date?.split('T')[0] || '');
  const parsedToday = parseISO(new Date().toISOString().split('T')[0]);
  const isBeforeToday = isBefore(parsedDate, parsedToday);

  return isBeforeToday;
};

const getCellStyle = (selectedCell: OperativeDaysCellModel) => {
  if (selectedCell.Date) {
    const clonedEvents: OperativeEventsCellAssignmentModel[] | undefined =
      JSON.parse(JSON.stringify(selectedCell.Events));

    const sortedEvents = clonedEvents?.sort((a, b) =>
      (a.EventText || '').localeCompare(b.EventText || '')
    );

    const result = sortedEvents?.reduce((prev, curr) =>
      (prev.EventRank || 0) <= (curr.EventRank || 0) ? prev : curr
    ).EventColour;

    return isValidHex(result?.trim() || '') ? result : '#777';
  }
};

const getCellEventText = (selectedCell: OperativeDaysCellModel) => {
  if (selectedCell.Date) {
    const clonedEvents: OperativeEventsCellAssignmentModel[] | undefined =
      JSON.parse(JSON.stringify(selectedCell.Events));

    const sortedEvents = clonedEvents?.sort((a, b) =>
      (a.EventText || '').localeCompare(b.EventText || '')
    );

    const result = sortedEvents?.length
      ? sortedEvents?.reduce((prev, curr) =>
          (prev.EventRank || 0) <= (curr.EventRank || 0) ? prev : curr
        ).EventText
      : '';

    return result;
  }
};

const getRowOrColumnSelection = (
  rowCells: OperativeDaysCellModel[],
  selectedCells: OperativeDaysCellModel[]
) => {
  let newDetailedCells: OperativeDaysCellModel[] = JSON.parse(
    JSON.stringify(selectedCells)
  );

  const alreadySelected = rowCells.every((cell) =>
    newDetailedCells.some(
      (selectedCell) =>
        selectedCell.Date === cell.Date &&
        selectedCell.OperativeId === cell.OperativeId
    )
  );

  if (alreadySelected) {
    newDetailedCells = newDetailedCells.filter(
      (cell) =>
        !rowCells.some(
          (rowCell) =>
            cell.Date === rowCell.Date &&
            cell.OperativeId === rowCell.OperativeId
        )
    );
  } else {
    const cellsToSelect = rowCells.filter(
      (rowCell) =>
        !newDetailedCells.some(
          (cell) =>
            cell.Date === rowCell.Date &&
            cell.OperativeId === rowCell.OperativeId
        )
    );

    if (cellsToSelect) {
      newDetailedCells = newDetailedCells.concat(cellsToSelect);
    }
  }

  return newDetailedCells;
};

const hoursWorkedLabel = (cell: OperativeDaysCellModel) => {
  if (cell.HoursWorkedDataAvailable) {
    if (cell.ShiftType === 1 && cell.HasHoursWorked) {
      return cell.GeneralHoursWorked?.toFixed(1);
    }

    return '-';
  } else {
    return '?';
  }
};

// components
const CellIcons = ({ cell, data }: CellIconsProps) => {
  const cellHasException = useCallback((): boolean => {
    let retVal = false;
    if (cell.Assignments && cell.Assignments.length > 0) {
      const relevantTaskAssignments = getRelevantTaskAssignments(cell, data);
      const hasException = relevantTaskAssignments?.some(
        (ta) => ta.IsException
      );
      retVal = hasException === undefined ? false : hasException;
    }
    return retVal;
  }, [cell, data]);

  const cellHasDelayorComment = useCallback((): boolean => {
    let retVal = false;
    if (cell.Assignments && cell.Assignments.length > 0) {
      const relevantTaskAssignments = getRelevantTaskAssignments(cell, data);
      const hasCommentOrDelay = relevantTaskAssignments?.some(
        (ta) => ta.HasDelay || ta.Comment
      );
      retVal = hasCommentOrDelay === undefined ? false : hasCommentOrDelay;
    }

    return retVal;
  }, [cell, data]);

  const cellShowBorrowedIcon = useCallback(() => {
    const selectedGangId = cell.SelectedGangId;
    const assigningGangs = cell.Assignments;
    if (assigningGangs) {
      for (const gang of assigningGangs) {
        if (gang.GangId !== selectedGangId) {
          return true;
        }
      }
    }
    return false;
  }, [cell.Assignments, cell.SelectedGangId]);

  return (
    <div className='cell-icon-container'>
      {cellHasException() && (
        <div
          className='cell-icon exception'
          title='There is at least one exception task for this day'
        />
      )}
      {cellHasDelayorComment() && (
        <div
          className='cell-icon comment-or-delay'
          title='There is a comment or delay against a task for this day'
          data-bind='visible: hasCommentOrDelay'
        />
      )}
      {cellShowBorrowedIcon() && (
        <div className='cell-icon borrowed' title={getTooltip(cell)} />
      )}
    </div>
  );
};

const CellEvents = ({ cell }: CellEventProps) => (
  <div className='cell-event-info'>
    {!isDateBefore(cell) && (
      <span className='cell-event-title'>{getCellEventText(cell)}</span>
    )}
    {cell.Events?.length ? (
      <div className='cell-events-amount'>{cell.Events.length}</div>
    ) : null}
  </div>
);

const TotalDelayLabel = ({ cell, data, toggler }: TotalDelayLabelProps) => {
  const delayHours = getDelayHours(cell, data);
  const isDelayAndToggler = delayHours > 0 && toggler;
  return (
    <span
      className='percentage-assigned'
      title={isDelayAndToggler ? 'Delay, in hours' : 'Percentage assigned'}
    >
      {isDelayAndToggler
        ? `(${delayHours})`
        : `${getPercentageAssigned(cell)}%`}
    </span>
  );
};

export const AssignmentCalendar = ({
  data,
  isLoading,
  dayBreakdowns,
  week,
  onAddOperative,
  onSelectCell
}: AssignmentCalendarProps) => {
  const [date, setDate] = useState<string | undefined>(week);
  const [calendarData, setCalendarData] = useState<OperativeDaysRowModel[]>();
  const [toggler, setToggler] = useState<boolean>(false);

  const ctx = useContext(TasksContext);
  const hasCalendarData = calendarData && calendarData.length > 0;
  const isClickableColumnOrRow = useMemo(() => {
    return !(ctx?.inspectionMode?.general || ctx?.inspectionMode?.byTask);
  }, [ctx]);

  const getCellDateAndOperativeId = useCallback((id: string) => {
    const [date, operativeId] = id.split('_');
    return { Date: date, OperativeId: +operativeId };
  }, []);

  const getWeekDay = useCallback(
    (daysToAdd: number = 0) => {
      if (date) {
        return format(addDays(new Date(date!), daysToAdd), 'E, dd/MM');
      }
    },
    [date]
  );

  const getPercentageDayWorked = useCallback(
    (cell: OperativeDaysCellModel) => {
      return `${(cell.GeneralHoursWorked || 0).toFixed(1)}${
        ctx?.taskId ? '%' : ''
      }`;
    },
    [ctx?.taskId]
  );

  const getCellUniqueId = useCallback(
    (cell: OperativeDaysCellModel) => `${cell.Date}_${cell.OperativeId}`,
    []
  );

  const isSelectedCell = useCallback(
    (cell) => !!ctx?.selectedTasks.has(getCellUniqueId(cell)),
    [ctx, getCellUniqueId]
  );

  const notSelectedForDayOverview = useCallback(
    (cell: OperativeDaysCellModel) => {
      return (
        (ctx?.inspectionMode?.general || ctx?.inspectionMode?.byTask) &&
        ctx?.inspectionMode?.withCell &&
        !isSelectedCell(cell)
      );
    },
    [ctx, isSelectedCell]
  );

  const selectedForDayOverview = useCallback(
    (cell: OperativeDaysCellModel) => {
      return (
        (ctx?.inspectionMode?.general || ctx?.inspectionMode?.byTask) &&
        ctx?.inspectionMode?.withCell &&
        isSelectedCell(cell)
      );
    },
    [ctx, isSelectedCell]
  );

  const awaitingSelectionForDayOverview = useCallback(() => {
    return ctx?.inspectionMode?.general || ctx?.inspectionMode?.byTask;
  }, [ctx]);

  const getDataStateCell = useCallback(
    (cell: OperativeDaysCellModel) => {
      if (notSelectedForDayOverview(cell)) {
        return 'not-selected-for-day-overview';
      }

      if (selectedForDayOverview(cell)) {
        return 'selected-for-day-overview';
      }

      if (awaitingSelectionForDayOverview()) {
        return 'awaiting-selection-for-day-overview';
      }

      if (isSelectedCell(cell)) {
        return 'selected-for-task-assignment';
      }

      return 'awaiting-selection-for-task-assignment';
    },
    [
      notSelectedForDayOverview,
      selectedForDayOverview,
      awaitingSelectionForDayOverview,
      isSelectedCell
    ]
  );

  const handleClickCell = useCallback(
    (cell: OperativeDaysCellModel, operativeName: string) => {
      if (notSelectedForDayOverview(cell)) {
        return;
      }
      if (ctx?.inspectionMode?.general || ctx?.inspectionMode?.byTask) {
        if (!ctx.selectedCell) {
          ctx.updateSelectedCell(cell, operativeName);
        } else {
          ctx.clearSelectedCell();
        }
      }
      const newSet = new Set(ctx?.selectedTasks);
      let newDetailedCells: OperativeDaysCellModel[] = JSON.parse(
        JSON.stringify(ctx?.selectedDetailedCells)
      );
      const id = getCellUniqueId(cell);
      if (ctx?.selectedTasks.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }

      if (
        ctx?.selectedDetailedCells?.find(
          (cell) => `${cell.Date}_${cell.OperativeId}` === id
        )
      ) {
        newDetailedCells = newDetailedCells.filter(
          (cell) => `${cell.Date}_${cell.OperativeId}` !== id
        );
      } else {
        newDetailedCells.push(cell);
      }

      ctx?.updateSelectedDetailedCells(newDetailedCells);
      ctx?.updateSelectedTasks(newSet);
    },
    [ctx, getCellUniqueId, notSelectedForDayOverview]
  );

  const handleClickColumn = useCallback(
    (column: number) => {
      if (isClickableColumnOrRow) {
        const currentDate = new Date(date!);
        const cellDate = addDays(currentDate, column);
        const columnCells = calendarData
          ?.reduce<OperativeDaysCellModel[]>(
            (prev, curr) => (prev = [...prev, ...curr.Cells!]),
            []
          )
          .filter((cell) => isEqual(new Date(cell.Date!), cellDate));

        const columnCellsIds = columnCells?.map((cell) =>
          getCellUniqueId(cell)
        );

        const allItemsSelected = columnCellsIds?.every((r) =>
          ctx?.selectedTasks.has(r)
        );

        const newSet = new Set(ctx?.selectedTasks);
        const newDetailedCells = getRowOrColumnSelection(
          columnCells || [],
          ctx.selectedDetailedCells || []
        );

        if (allItemsSelected) {
          columnCellsIds?.forEach((id) => newSet.delete(id));
        } else {
          columnCellsIds?.forEach((id) => newSet.add(id));
        }
        ctx?.updateSelectedDetailedCells(newDetailedCells);
        ctx?.updateSelectedTasks(newSet);
      }
    },
    [calendarData, date, ctx, isClickableColumnOrRow, getCellUniqueId]
  );

  const handleClickRow = useCallback(
    (row: OperativeDaysRowModel) => {
      if (isClickableColumnOrRow) {
        const rowCellsIds = row.Cells?.map((c) => getCellUniqueId(c));
        const allItemsSelected = rowCellsIds?.every((r) =>
          ctx?.selectedTasks.has(r)
        );
        const newSet = new Set(ctx?.selectedTasks);
        const newDetailedCells = getRowOrColumnSelection(
          row.Cells || [],
          ctx.selectedDetailedCells || []
        );

        if (allItemsSelected) {
          rowCellsIds?.forEach((id) => newSet.delete(id));
        } else {
          rowCellsIds?.forEach((id) => newSet.add(id));
        }
        ctx?.updateSelectedDetailedCells(newDetailedCells);
        ctx?.updateSelectedTasks(newSet);
      }
    },
    [ctx, isClickableColumnOrRow, getCellUniqueId]
  );

  const isClickableCell = useCallback(
    (cell: OperativeDaysCellModel) => {
      return (
        (!ctx?.inspectionMode?.general && !ctx?.inspectionMode?.byTask) ||
        ((ctx?.inspectionMode?.general || ctx?.inspectionMode?.byTask) &&
          !ctx?.inspectionMode?.withCell) ||
        (ctx?.inspectionMode?.withCell && isSelectedCell(cell))
      );
    },
    [ctx, isSelectedCell]
  );

  useEffect(() => {
    setDate(data.Date);
  }, [data.Date]);

  useEffect(() => {
    const selectedTasks = ctx?.selectedTasks;
    const inspectionMode =
      ctx?.inspectionMode?.byTask || ctx?.inspectionMode?.general;
    const transformed = data.Rows?.filter((row) => {
      if (inspectionMode && selectedTasks) {
        const selectedTask = Array.from(selectedTasks)[0];
        if (selectedTask) {
          const selectedTaskOperativeId =
            getCellDateAndOperativeId(selectedTask).OperativeId;
          return row.OperativeId === selectedTaskOperativeId;
        }
      }

      return row;
    }).map((row, idx) => {
      return {
        ...row,
        Cells: row.Cells?.map((cell) => {
          const dayBrakdownsPercentage = dayBreakdowns
            ?.filter((day) => {
              return (
                isEqual(new Date(day.Date!), new Date(cell.Date!)) &&
                day.OperativeId === cell.OperativeId
              );
            })
            .map((day) => day.Percentage)
            .reduce((prev, curr) => prev! + curr! || 0, 0);

          if (ctx?.taskId) {
            cell = { ...cell, GeneralHoursWorked: dayBrakdownsPercentage || 0 };
          }

          return cell;
        })
      };
    });
    setCalendarData(transformed as OperativeDaysRowModel[]);
  }, [
    data.Rows,
    ctx?.selectedTasks,
    ctx?.inspectionMode?.byTask,
    ctx?.inspectionMode?.general,
    ctx?.taskId,
    dayBreakdowns,
    getCellDateAndOperativeId
  ]);

  useEffect(() => {
    onSelectCell?.(
      ctx?.selectedTasks!,
      Array.from(ctx?.selectedTasks!).map((id) => getCellDateAndOperativeId(id))
    );
  }, [ctx?.selectedTasks, onSelectCell, getCellDateAndOperativeId]);

  useEffect(() => {
    const inerval = setInterval(() => {
      setToggler((current) => !current);
    }, 5000);
    return () => clearInterval(inerval);
  }, [setToggler]);

  return (
    <div className='operative-days-grid'>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <table className='sticky-header-table is-assigning'>
            <colgroup>
              <col style={{ width: '209px' }} />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <td></td>
                <td
                  className={classNames('header-day', {
                    clickable: isClickableColumnOrRow
                  })}
                  onClick={() => handleClickColumn(0)}
                >
                  {getWeekDay(0)}
                </td>
                <td
                  className={classNames('header-day', {
                    clickable: isClickableColumnOrRow
                  })}
                  onClick={() => handleClickColumn(1)}
                >
                  {getWeekDay(1)}
                </td>
                <td
                  className={classNames('header-day', {
                    clickable: isClickableColumnOrRow
                  })}
                  onClick={() => handleClickColumn(2)}
                >
                  {getWeekDay(2)}
                </td>
                <td
                  className={classNames('header-day', {
                    clickable: isClickableColumnOrRow
                  })}
                  onClick={() => handleClickColumn(3)}
                >
                  {getWeekDay(3)}
                </td>
                <td
                  className={classNames('header-day', {
                    clickable: isClickableColumnOrRow
                  })}
                  onClick={() => handleClickColumn(4)}
                >
                  {getWeekDay(4)}
                </td>
                <td
                  className={classNames('header-day', {
                    clickable: isClickableColumnOrRow
                  })}
                  onClick={() => handleClickColumn(5)}
                >
                  {getWeekDay(5)}
                </td>
                <td
                  className={classNames('header-day', {
                    clickable: isClickableColumnOrRow
                  })}
                  onClick={() => handleClickColumn(6)}
                >
                  {getWeekDay(6)}
                </td>
              </tr>
            </thead>
          </table>
          <div
            className='relative-container'
            data-bind="style: { 'min-height': containerMinHeight }"
            style={{ minHeight: '0px' }}
          >
            {!hasCalendarData && (
              <EmptyList
                message='There are no operatives available for this week.'
                className='empty empty-list has-loaded-days-grid'
              />
            )}
            {hasCalendarData && (
              <table
                className='content-table is-assigning'
                data-bind='css: tableCssClass'
              >
                <colgroup>
                  <col style={{ width: '209px' }} />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <tbody data-bind='foreach: rows'>
                  {calendarData.map((row, idx) => (
                    <tr key={idx} className='operative-row'>
                      <td
                        className={
                          row.Borrowed
                            ? 'operative-name-cell borrowed'
                            : 'operative-name-cell'
                        }
                        title={row.OperativeName}
                        onClick={() => handleClickRow(row)}
                      >
                        <div className='cell-content'>{row.OperativeName}</div>
                      </td>
                      {row.Cells?.map((cell, idx) => (
                        <td
                          key={idx}
                          className={classNames('operative-day-cell', {
                            [getDefaultCellCssClass(cell)]: true,
                            clickable: isClickableCell(cell),
                            'show-selected-circle show-selected-circle-fade-background':
                              isSelectedCell(cell)
                          })}
                          data-cell-state={getDataStateCell(cell)}
                          onClick={() =>
                            handleClickCell(cell, row.OperativeName || '')
                          }
                          style={{
                            ...(!isDateBefore(cell) &&
                              (getDefaultCellCssClass(cell).includes(
                                'allocation-percentage-0'
                              ) ||
                                getDefaultCellCssClass(cell).includes(
                                  'allocation-percentage-none'
                                )) &&
                              cell.Events?.length && {
                                backgroundColor: getCellStyle(cell)
                              })
                          }}
                        >
                          <div className='cell-content'>
                            <div className='cell-hours-allocation'>
                              <span
                                className='hours-worked'
                                title='Hours worked'
                                data-bind='text: hoursWorkedLabel()'
                              >
                                {ctx?.taskId && ctx?.inspectionMode?.byTask
                                  ? getPercentageDayWorked(cell)
                                  : hoursWorkedLabel(cell)}
                              </span>
                              <div className='carousel-toggling-info'>
                                <TotalDelayLabel
                                  cell={cell}
                                  data={data}
                                  toggler={toggler}
                                />
                              </div>
                            </div>
                            {/* {!isDateBefore(cell) && cell.Events?.length ? ( */}
                            <CellEvents cell={cell} />
                            {/* ) : null} */}
                            <CellIcons cell={cell} data={data} />
                          </div>
                          {isSelectedCell(cell) && (
                            <div className='selected-circle-wrapper'>
                              <div
                                className='selected-circle show with-tick'
                                style={{
                                  ...(!isDateBefore(cell) &&
                                    (getDefaultCellCssClass(cell).includes(
                                      'allocation-percentage-0'
                                    ) ||
                                      getDefaultCellCssClass(cell).includes(
                                        'allocation-percentage-none'
                                      )) &&
                                    cell.Events?.length && {
                                      backgroundColor: getCellStyle(cell)
                                    })
                                }}
                              />
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <button
            style={{ width: '208px' }}
            className='large with-icon with-icon--plus yellow'
            onClick={onAddOperative}
          >
            Add operative
          </button>
        </>
      )}
    </div>
  );
};
