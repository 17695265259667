import { DelayReason } from "api/model";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { EmptyList } from "./EmptyList";
import { Loader } from "./Loader";

type DelayReasonSelectProps = {
  delayReasons: DelayReason[];
  isLoading?: boolean;

  onChangeDelayReason?: (id: number) => void;
};

export const DelayReasonSelect: React.FC<DelayReasonSelectProps> = (props) => {
  const { delayReasons, isLoading, onChangeDelayReason } = props;
  const [selected, setSelected] = useState<DelayReason | null>(null);

  const isSelected = useCallback(
    (delayReason: DelayReason) => {
      return delayReason.Id === selected?.Id;
    },
    [selected]
  );

  const handleClickItem = useCallback(
    (delayReason: DelayReason) => {
      setSelected((current) =>
        current?.Id !== delayReason.Id ? delayReason : null
      );
    },
    [setSelected]
  );

  useEffect(() => {
    onChangeDelayReason?.(selected?.Id!);
  }, [selected, onChangeDelayReason]);

  const hasItems = delayReasons.length > 0;

  return (
    <div className="field field--delay-toggle-or-reasons">
      <div className="field-label field-label--delay-toggle-or-reason">
        <label>Delay reason</label>
      </div>
      <div className="field-holder delay-reasons-listing">
        <div className="relative-container margin-bottom-2x">
          {!isLoading && (
            <table className="listing alternative">
              <caption className="visually-hidden">
                List of Delay Reasons
              </caption>
              <tbody className="no-wrap">
                {delayReasons.map((delayReason) => (
                  <tr
                    key={delayReason.Id}
                    className={classNames("selectable", {
                      selected: isSelected(delayReason),
                    })}
                    onClick={() => handleClickItem(delayReason)}
                  >
                    <td className="full-width">{delayReason.Text}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {isLoading && <Loader className="pinned translucent" />}
          {!hasItems && (
            <EmptyList message="There are no delay reasons to display." />
          )}
        </div>
      </div>
    </div>
  );
};
