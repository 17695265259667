import { useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

export const PrivateRoute: React.FC = ({ children }) => {
  const { instance } = useMsal();

  useEffect(() => {
    async function login() {
      await instance.handleRedirectPromise();

      const accounts = instance.getAllAccounts();
      if (accounts.length === 0) {
        // No user signed in
        instance.loginRedirect();
      }
    }

    login();
  }, [instance]);

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <h6>Please sign-in.</h6>
      </UnauthenticatedTemplate>
    </>
  );
};
