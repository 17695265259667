export const getUTCDateISOString = (now: Date = new Date()) => {
  const yearUTC = now.getUTCFullYear();
  const monthUTC = now.getUTCMonth() + 1;
  const dayUTC = now.getUTCDate();
  const month = monthUTC > 9 ? monthUTC : `0${monthUTC}`;
  const day = dayUTC > 9 ? dayUTC : `0${dayUTC}`;
  return `${yearUTC}-${month}-${day}T00:00:00`;
};

export const getDateISOString = (now: Date = new Date()) => {
  const yearUTC = now.getFullYear();
  const monthUTC = now.getMonth() + 1;
  const dayUTC = now.getDate();
  const month = monthUTC > 9 ? monthUTC : `0${monthUTC}`;
  const day = dayUTC > 9 ? dayUTC : `0${dayUTC}`;
  return `${yearUTC}-${month}-${day}T00:00:00`;
};
