import { useDocumentTitle } from "@mantine/hooks";
import { useGetNotificationsAll } from "api/opcs";
import { useUpdateBreadcrumbs } from "core/hooks";
import React, { useMemo } from "react";
import { NotificationsTable } from "./components/NotificationsTable";

interface NotificationListingProps {}

const useGetProjectSettings = () => {
  return useGetNotificationsAll({
    query: {
      select: (data) => data?.Data ?? [],
    },
  });
};

export const NotificationListing: React.FC<NotificationListingProps> = (
  props
) => {
  useDocumentTitle("Notifications | OPCS");
  const query = useGetProjectSettings();

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: "Project assignments",
              path: "/project-assignments",
            },
            {
              name: "Project settings",
              path: "/project-settings",
            },
            {
              name: "Notifications",
              path: "/notification",
              selected: true,
              hiddenInDropdown: true,
            },
          ],
        },
      ],
      []
    )
  );

  return (
    <NotificationsTable
      notifications={query.status === "success" ? query.data : []}
      isLoading={query.status === "loading"}
    />
  );
};
