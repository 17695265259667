import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { msalInstance } from "index";

type Hub =
  | "notifications"
  | "colorblind"
  | "projectassignments"
  | "projectsettings"
  | "supervisorownership";

const accessTokenFactory = async () => {
  await msalInstance.handleRedirectPromise();
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  return msalInstance
    .acquireTokenSilent({
      scopes: [process.env.REACT_APP_AZURE_AD_API_SCOPE!],
      account: account,
    })
    .then((response) => response.accessToken);
};

const useHub = (hub: Hub) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    async function connect() {
      const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_BASE_URL}/${hub}`, {
          accessTokenFactory,
        })
        .build();

      connection
        .start()
        .then(() => {
          setConnection(connection);
        })
        .catch((err) => console.error(err));
    }
    connect();
  }, [hub]);

  const invoke = useCallback(
    (methodName: string, ...args: any[]) => {
      return connection?.invoke(methodName, ...args);
    },
    [connection]
  );

  return { invoke, isReady: Boolean(connection) };
};

type Hubs = Record<Hub, ReturnType<typeof useHub>>;

export interface SignalRContextData {
  hubs: Hubs;
}

const SignalRContext = createContext<SignalRContextData | null>(null);

export const SignalRContextProvider: React.FC = ({ children }) => {
  const hubs: Hubs = {
    notifications: useHub("notifications"),
    colorblind: useHub("colorblind"),
    projectassignments: useHub("projectassignments"),
    projectsettings: useHub("projectsettings"),
    supervisorownership: useHub("supervisorownership"),
  };

  return (
    <SignalRContext.Provider value={{ hubs }}>
      {children}
    </SignalRContext.Provider>
  );
};

export const useSignalRContext = () => {
  const context = useContext(SignalRContext);
  if (!context) {
    throw new Error(`useSignalRContext needs to wrapped in SignalRContext`);
  }

  return context;
};

export const useSignalRHub = (hub: Hub) => {
  const { hubs } = useSignalRContext();
  return hubs[hub];
};

export const useSignalRInvoke = (
  hub: Hub,
  methodName: string,
  ...args: any[]
) => {
  const { invoke } = useSignalRHub(hub);
  invoke(methodName, ...args);
};
