import { OperativeOwnershipListingModel } from "api/model";
import classNames from "classnames";
import { EmptyList, Loader, Pager } from "core/components";
import React from "react";

type OwnershipTableProps = {
  isLoading?: boolean;
  data: OperativeOwnershipListingModel[];
  selectedOwnership: Set<number>;
  currentPage: number;
  totalPages: number;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;

  onOwnershipSelected?: (ownershipId: number) => void;
  onPageChanged?: (newPage: number) => void;
};

export const OwnershipTable: React.FC<OwnershipTableProps> = (props) => {
  const {
    isLoading,
    data,
    selectedOwnership,
    currentPage,
    totalPages,
    hasNextPage,
    hasPreviousPage,
    onOwnershipSelected,
    onPageChanged,
  } = props;

  const hasItems = data.length > 0;

  return (
    <div className="relative-container">
      {isLoading && <Loader className="pinned translucent" />}
      {!isLoading && (
        <table className="listing" data-bind="with: listing">
          <caption className="visually-hidden">List of ownership</caption>
          <colgroup>
            <col style={{ width: "200px" }} />
            <col style={{ width: "200px" }} />
            <col />
            <col style={{ width: "200px" }} />
            <col style={{ width: "200px" }} />
          </colgroup>
          <tbody className="no-wrap">
            {data.map((ownership) => (
              <tr
                key={ownership.Id}
                className={classNames("selectable", {
                  selected: selectedOwnership.has(ownership.Id!),
                })}
                onClick={() => onOwnershipSelected?.(ownership.Id!)}
              >
                <td title={ownership.PayrollId}>{ownership.PayrollId}</td>
                <td
                  title={ownership.FullName}
                  className={classNames({
                    "supervisor-icon": ownership.IsSupervisor,
                  })}
                >
                  {ownership.FullName}
                </td>
                <td title={ownership.TradeDescription}>
                  {ownership.TradeDescription}
                </td>
                <td title={ownership.CurrentGang}>{ownership.CurrentGang}</td>
                <td title={ownership.BorrowedBy}>{ownership.BorrowedBy}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!hasItems && !isLoading && (
        <EmptyList message="There are no operatives to display." />
      )}
      {hasItems && totalPages > 1 && (
        <section className="listing-pager padding-top-2x padding-bottom-2x">
          <div className="button-array">
            <Pager
              className="inline right"
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChanged={onPageChanged}
              isNextEnabled={hasNextPage}
              isPreviousEnabled={hasPreviousPage}
            />
          </div>
        </section>
      )}
    </div>
  );
};
