import { BreadcrumbsCategory } from "core/components";
import { BreadcrumbsContext, BreadcrumbsContextType } from "core/context";
import { useContext, useEffect } from "react";

export const useUpdateBreadcrumbs = (breadcrumbs: BreadcrumbsCategory[]) => {
  const { updateBreadcrumbs } = useContext(
    BreadcrumbsContext
  ) as BreadcrumbsContextType;

  useEffect(() => {
    updateBreadcrumbs(breadcrumbs);
  }, [breadcrumbs, updateBreadcrumbs]);
};
