import { useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@mantine/hooks';
import type { UserModel } from 'api/model';
import { useGetProjectAssignmentProjectAssignableUsersGetUsers } from 'api/opcs';
import { FilterBar, UserListing } from './components';
import { useUpdateBreadcrumbs } from 'core/hooks';

export const SEARCH_QUERY_KEY = 'projectAssignableUsersSearchQuery';

// Updated to include search by AD Username
const useUsersWithSearch = (searchQuery: string) => {
  const byNameAndPayrollID = (user: UserModel): boolean => {
    return (
      user.FullName?.toLowerCase().includes(searchQuery) ||
      user.PayrollId?.toLowerCase().includes(searchQuery) ||
      user.ActiveDirectoryUsername?.toLowerCase().includes(searchQuery) ||
      false
    );
  };

  return useGetProjectAssignmentProjectAssignableUsersGetUsers({
    query: {
      select: (data) => data.Data?.filter(byNameAndPayrollID) ?? []
    }
  });
};

export const ProjectAssignableUsers: React.FC = () => {
  useDocumentTitle('Project assignments | OPCS');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const query = useUsersWithSearch(searchQuery.toLowerCase());

  const handleSaveSearchQueryInLocalStorage = useCallback(() => {
    localStorage.setItem(SEARCH_QUERY_KEY, searchQuery);
  }, [searchQuery]);

  const retrieveSaveSearchQueryFromLocalStorage = useCallback(() => {
    const retrievedValue = localStorage.getItem(SEARCH_QUERY_KEY);

    if (retrievedValue) setSearchQuery(retrievedValue);
  }, [setSearchQuery]);

  const navigate = useNavigate();

  const handleRowClicked = useCallback(
    (userId: number) => {
      navigate(`user/${userId}`);
    },
    [navigate]
  );

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments',
              selected: true
            },
            {
              name: 'Project settings',
              path: '/project-settings',
              selected: false
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership',
              selected: false
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: false
            }
          ]
        },
        {
          withSearch: false,
          items: [
            {
              name: 'Assignment',
              path: '/project-assignments',
              selected: true
            },
            {
              name: 'Promotion',
              path: '/project-assignments/promotion',
              selected: false
            }
          ]
        }
      ],
      []
    )
  );
  useEffect(() => {
    if (searchQuery) handleSaveSearchQueryInLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleResetSearchQueryFromLocalStorage = useCallback(
    () => localStorage.removeItem(SEARCH_QUERY_KEY),
    []
  );

  useEffect(() => {
    retrieveSaveSearchQueryFromLocalStorage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='project-assignment-content'>
      <section className='project-assignable-users-content'>
        <h1 className='visually-hidden'>Project assignable users</h1>
        <div>
          <section className='user-filter-panel'>
            <h1 className='visually-hidden'>User filtering</h1>
            <FilterBar
              onSearchQueryChange={setSearchQuery}
              searchQuery={searchQuery}
              onResetSearchQuery={handleResetSearchQueryFromLocalStorage}
            />
          </section>
          <section>
            <h1 className='visually-hidden'>List of users</h1>
            <UserListing
              users={query.status === 'success' ? query.data : []}
              isLoading={query.status === 'loading'}
              onRowClicked={handleRowClicked}
            />
          </section>
        </div>
      </section>
    </div>
  );
};
