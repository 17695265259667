import { useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import type {
  OperativeModel,
  OperativeModelPageResultEnvelope,
  SearchModel
} from 'api/model';
import { usePostProjectAssignmentPromotionPromoteToSupervisor } from 'api/opcs';
import customInstance from 'api/mutator/custom-instance';
import { useDocumentTitle } from '@mantine/hooks';
import { useUpdateBreadcrumbs } from 'core/hooks';
import { OperativePromotion } from './components/OperativePromotion';

/**
 * Orval doesn't seem to pick up header params (like `x-pageNumber`), so we have
 * a little copying and pasting to do
 */

type PaginatedSearchModel = SearchModel & {
  Page: number;
};

const pageSize = 100;

export const postProjectassignmentPromotionGetoperatives = (
  searchModel: PaginatedSearchModel
) => {
  return customInstance<OperativeModelPageResultEnvelope>({
    url: `/project-assignment/promotion/get-operatives`,
    method: 'post',
    data: searchModel,
    headers: {
      'x-pageNumber': searchModel.Page,
      'x-pageSize': pageSize
    }
  });
};

const useGetPromotionOperatives = (searchModel: PaginatedSearchModel) => {
  return useQuery(
    ['promotion', 'operatives', searchModel],
    () => postProjectassignmentPromotionGetoperatives(searchModel),
    {
      select: (data) => {
        const result = data.Data;
        return result
          ? { items: result.Items, pages: Math.ceil(result.Count! / pageSize) }
          : { items: [], pages: 0 };
      }
    }
  );
};

export const Promotion: React.FC = (props) => {
  useDocumentTitle('Project assignments | OPCS');
  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments',
              selected: true
            },
            {
              name: 'Project settings',
              path: '/project-settings',
              selected: false
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership',
              selected: false
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: false
            }
          ]
        },
        {
          withSearch: false,
          items: [
            {
              name: 'Assignment',
              path: '/project-assignments',
              selected: false
            },
            {
              name: 'Promotion',
              path: '/project-assignments/promotion',
              selected: true
            }
          ]
        }
      ],
      []
    )
  );

  const [searchParams, setSearchParams] = useState<PaginatedSearchModel>({
    Page: 1
  });
  const [selectedOperative, setSelectedOperative] =
    useState<OperativeModel | null>(null);
  const query = useGetPromotionOperatives(searchParams);

  const { mutate: save, isLoading: isSaving } =
    usePostProjectAssignmentPromotionPromoteToSupervisor();

  const handleOperativeSelected = useCallback(
    (operative: OperativeModel | null) => {
      setSelectedOperative((current) => {
        if (current && operative && current.Id === operative.Id) {
          // deselect
          return null;
        }
        return operative;
      });
    },
    []
  );

  const handleQueryChanged = useCallback((query: string) => {
    setSearchParams((current) => ({
      ...current,
      SearchTerm: query,
      Page: 1
    }));
  }, []);

  const handlePageChanged = useCallback((page: number) => {
    setSearchParams((current) => ({
      ...current,
      Page: page
    }));
  }, []);

  const queryClient = useQueryClient();
  const handleSave = useCallback(() => {
    save(
      { data: { OperativeIndividualId: selectedOperative?.Id } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['promotion', 'operatives']);
          setSelectedOperative(null);
        }
      }
    );
  }, [queryClient, save, selectedOperative?.Id]);

  const totalPages = query.data?.pages || 0;

  return (
    <div className='project-assignment-content'>
      <section className='operative-promotion-content'>
        <h1 className='visually-hidden'>Promotable operatives</h1>
        <OperativePromotion
          isLoading={query.isLoading}
          data={query.data?.items || []}
          selectedOperative={selectedOperative}
          currentPage={searchParams.Page}
          totalPages={totalPages}
          hasPreviousPage={searchParams.Page > 1}
          hasNextPage={searchParams.Page < totalPages}
          isSaving={isSaving}
          onOperativeSelected={handleOperativeSelected}
          onQueryChanged={handleQueryChanged}
          onPageChanged={handlePageChanged}
          onSave={handleSave}
        />
      </section>
    </div>
  );
};
