import { EmptyList } from "core/components";
import { useUpdateBreadcrumbs } from "core/hooks";
import React, { useMemo } from "react";

type TasksOperativesEmptyProps = {};

export const TasksOperativesEmpty: React.FC<TasksOperativesEmptyProps> = (
  props
) => {
  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: "Project assignments",
              path: "/project-assignments",
            },
            {
              name: "Project settings",
              path: "/project-settings",
            },
            {
              name: "Supervisor ownership",
              path: "/supervisor-ownership",
            },
            {
              name: "Tasks",
              path: "/tasks",
              selected: true,
            },
          ],
        },
        {
          withSearch: false,
          items: [
            {
              name: "NO PROJECTS ASSIGNED",
              path: "#",
              selected: true,
            },
          ],
        },
        {
          withSearch: false,
          items: [
            {
              name: "NO AVAILABLE PROJECTS",
              path: "#",
              selected: true,
            },
          ],
        },
        {
          withSearch: false,
          items: [
            {
              name: "Tasks",
              path: "/tasks",
            },
            {
              name: "Operatives",
              path: "#",
              selected: true,
            },
          ],
        },
      ],
      []
    )
  );

  return (
    <section className="operatives-list-content">
      <EmptyList message="There are no operatives to display." />
    </section>
  );
};
