import { ProjectModel } from "api/model";
import { EmptyList, Loader } from "core/components";

export interface ProjectListingProps {
  isLoading?: boolean;
  projects: ProjectModel[];

  onRowClicked?: (projectId: number) => void;
}

export const ProjectListing: React.FC<ProjectListingProps> = (props) => {
  const { isLoading, projects, onRowClicked } = props;

  const hasItems = projects.length > 0;

  return (
    <div className="project-listing">
      {hasItems && (
        <table className="sticky-header-table table-with-headers">
          <colgroup>
            <col className="name" />
            <col className="company" />
            <col className="folio" />
            <col className="averageHoursPerDay" />
          </colgroup>
          <thead>
            <tr>
              <th className="name" title="Name">
                Name
              </th>
              <th className="company" title="Company">
                Company
              </th>
              <th className="folio" title="Folio">
                Folio
              </th>
              <th className="averageHoursPerDay" title="Average hours per day">
                Average hours per day
              </th>
            </tr>
          </thead>
        </table>
      )}
      <div className="listing-container">
        {hasItems && (
          <table className="listing projects-listing table-with-headers">
            <caption className="visually-hidden">List of projects</caption>
            <colgroup>
              <col className="name" />
              <col className="company" />
              <col className="folio" />
              <col className="averageHoursPerDay" />
            </colgroup>
            <tbody className="no-wrap">
              {projects.map((project) => (
                <tr
                  className="selectable"
                  key={project.Id}
                  onClick={() => onRowClicked?.(project.Id!)}
                >
                  <td className="name" title={project.Name}>
                    {project.Name}
                  </td>
                  <td className="company" title={project.Company}>
                    {project.Company}
                  </td>
                  <td className="folio" title={project.Folio}>
                    {project.Folio}
                  </td>
                  <td
                    className="averageHoursPerDay"
                    title={`${project.AverageHoursPerDay}`}
                  >
                    {project.AverageHoursPerDay}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {isLoading && <Loader className="pinned translucent" />}
      </div>
      {!isLoading && !hasItems && (
        <EmptyList message="There are no projects to display." />
      )}
    </div>
  );
};
