import React, { useCallback, useEffect, useMemo, useState } from "react";
import { startOfWeek, format, add, sub, addDays } from "date-fns";
import { DatePickerModal } from "./DatePickerModal";
import { getDateISOString } from "utils/date";

type WeekSelectorProps = {
  startDate?: Date;
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  onChangeWeek?: (date: string) => void;
  onChangeStartDayOfWeek?: (date: string) => void;
};

const DATE_KEY = "selectedDate";

export const WeekSelector: React.FC<WeekSelectorProps> = (props) => {
  const { startDate, weekStartsOn, onChangeWeek, onChangeStartDayOfWeek } =
    props;

  const retrieveDateFromSessionStorage = useCallback((): Date | null => {
    const retrievedDateConfigJson = sessionStorage.getItem(DATE_KEY);

    if (retrievedDateConfigJson) {
      const retrievedDateString = JSON.parse(retrievedDateConfigJson);

      const date = new Date(retrievedDateString);

      return date;
    }
    return null;
  }, []);

  const [date, setDate] = useState<Date>(
    retrieveDateFromSessionStorage() || startDate || new Date()
  );

  const [showDatePickerModal, setShowDatePickerModal] = useState(false);

  const dateByStartDateDay = useMemo(() => {
    return startOfWeek(date, { weekStartsOn });
  }, [weekStartsOn, date]);

  const selectedDayAndMonth = useMemo(() => {
    return format(dateByStartDateDay, "dd/MM");
  }, [dateByStartDateDay]);

  const selectedYear = useMemo(() => {
    return format(dateByStartDateDay, "yyyy");
  }, [dateByStartDateDay]);

  const onClickPrevDate = useCallback(() => {
    setDate(sub(date, { weeks: 1 }));
  }, [date, setDate]);

  const onClickNextDate = useCallback(() => {
    setDate(add(date, { weeks: 1 }));
  }, [date, setDate]);

  const onChangeDate = useCallback(
    (selectedDate: Date) => {
      setDate(selectedDate);
      setShowDatePickerModal(false);
    },
    [setDate, setShowDatePickerModal]
  );

  const handleSaveDateInSessionStorage = useCallback((date: Date) => {
    sessionStorage.setItem(DATE_KEY, JSON.stringify(date));
  }, []);

  useEffect(() => {
    onChangeWeek?.(getDateISOString(date));
    // OP2-63: Did add one day to startOfWeek, now adds 0 days
    // In addition to this to handle daylight time change issue created new method getDateISOString instead of getUTCDateISOString
    onChangeStartDayOfWeek?.(
      getDateISOString(addDays(startOfWeek(date, { weekStartsOn }), 0))
    );

    if (date) handleSaveDateInSessionStorage(date);
  }, [
    date,
    weekStartsOn,
    onChangeWeek,
    onChangeStartDayOfWeek,
    handleSaveDateInSessionStorage,
  ]);

  return (
    <>
      <div className="week-selector margin-right-2x">
        <button
          className="week-navigator yellow large previous"
          onClick={onClickPrevDate}
        ></button>
        <div
          id="operative-days-date-picker"
          className="date-picker relative-container"
          data-attach-to-direction="left"
          onClick={() => setShowDatePickerModal(true)}
        >
          <div className="date-month-label">{selectedDayAndMonth}</div>
          <div className="date-year-label">{selectedYear}</div>
        </div>
        <button
          className="week-navigator yellow large next"
          onClick={onClickNextDate}
        ></button>
      </div>
      <DatePickerModal
        date={date}
        onChangeDate={onChangeDate}
        show={showDatePickerModal}
      />
    </>
  );
};
