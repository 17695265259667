import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { useMsal } from "@azure/msal-react";
import { head } from "rambda";
import { differenceInSeconds, parseISO } from "date-fns";
import { Breadcrumbs, TopBarMenu } from "core/components";
import { useNotificationsContext } from "core/context";
import { Notification } from "core/context/NotificationContext.types";
import { frontendBuildDate } from "utils/buildDateHelper";

export interface TopBarProps {}

const useMiniPanel = (notifications: Notification[]) => {
  const [showMiniPanel, setShowMiniPanel] = useState(false);

  useEffect(() => {
    const n = head(notifications);
    if (n) {
      const dateCreated = parseISO(n.Created);
      const delta = differenceInSeconds(Date.now(), dateCreated);
      if (delta < 5) {
        setShowMiniPanel(true);
      }
    }
  }, [notifications]);

  const onCloseMiniPanel = useCallback(() => {
    setShowMiniPanel(false);
  }, []);

  return { showMiniPanel, onCloseMiniPanel };
};

export const TopBar: React.FC<TopBarProps> = (props) => {
  const logoClass = cx("logo", {
    logo__bylor: process.env.REACT_APP_INSTANCE_NAME === "bylor",
  });

  // OP2-62 - Frontend Version
  const logoTitle = "Frontend Version: " + frontendBuildDate;

  const pca = useMsal();

  const username = useMemo(() => {
    return pca?.accounts[0]?.name || "";
  }, [pca]);
  const { notifications, clearNotifications } = useNotificationsContext();
  const { showMiniPanel, onCloseMiniPanel } = useMiniPanel(notifications);

  return (
    <header>
      <Link to="/" className={logoClass} title={logoTitle}></Link>
      <Breadcrumbs />
      <TopBarMenu
        username={username}
        showMiniPanel={showMiniPanel}
        notifications={notifications}
        clearNotifications={clearNotifications}
        onCloseNotificationsModal={onCloseMiniPanel}
      />
    </header>
  );
};
