export enum NotificationType {
  None = 0,
  Information,
  Success,
  Warning,
  Error,
}

export interface Notification {
  Type: NotificationType;
  Text: string;
  Key: string;
  Created: string;
  ClientKey: string | null;
}