import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useDocumentTitle } from '@mantine/hooks';
import { clone } from 'rambda';
import { useDebounce } from 'use-debounce';
import {
  postTaskAssignmentTasksSearchProjectTasksProjectIdGangId,
  postTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList,
  useGetTaskAssignmentProjectsGetProjectsAndGangs,
  usePostTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList
} from 'api/opcs';
import type {
  ProjectTaskHierarchyModel,
  ProjectTaskHierarchyModelArrayEnvelope,
  ProjectTaskRequestModel
} from 'api/model';
import { useUpdateBreadcrumbs } from 'core/hooks';
import { ProjectTasks } from './components/ProjectTasks';
import { TasksContext } from 'core/context';
import { getUTCDateISOString } from 'utils/date';
import { SettingsContext } from 'core/context/SettingsContext';
import { convertOldGangIdToNewGangId } from 'utils/convertOldGangIdToNewGangId';

type TasksManageProps = {};

// const useGetTasks = (
//   projectId: number,
//   gangId: number,
//   projectTaskRequestModel: ProjectTaskRequestModel
// ) => {
//   return useQuery(
//     ["tasks", "manage", { projectId, gangId, projectTaskRequestModel }],
//     () =>
//       postTaskassignmentTasksSearchprojecttasksProjectIdGangId(
//         projectId,
//         gangId,
//         projectTaskRequestModel
//       ),
//     {
//       select: (data) => data?.Data ?? [],
//     }
//   );
// };

const useGetProjectsAndGangs = () =>
  useGetTaskAssignmentProjectsGetProjectsAndGangs({
    query: {
      select: (data) => data?.Data ?? []
    }
  });

const useSetBreadcrumbs = (userId: string, projectId: string) => {
  const queryProjectsAndGangs = useGetProjectsAndGangs();
  const getProjectsBreadcrumb = useCallback(() => {
    return queryProjectsAndGangs.status === 'success'
      ? queryProjectsAndGangs.data.map((project: any) => {
          // OP2-58: Map the current gangID into the project's gangID
          let newGangId: string | undefined = convertOldGangIdToNewGangId(
            queryProjectsAndGangs.data,
            projectId,
            userId,
            project.Gangs
          );
          return {
            name: project.Name,
            path: `/tasks/${project.Id}/${newGangId}/operatives`,
            selected: project.Id === +projectId!
          };
        })
      : [
          {
            name: 'NO PROJECTS ASSIGNED',
            path: '#',
            selected: true
          }
        ];
  }, [
    queryProjectsAndGangs.status,
    queryProjectsAndGangs.data,
    projectId,
    userId
  ]);

  const getProjectUsersBreadcrumb = useCallback(() => {
    if (queryProjectsAndGangs.status === 'success') {
      // OP2-58: Don't use Selected attribute, use the projectID
      const selectedProject = queryProjectsAndGangs.data.find(
        (project: any) => project.Id === +projectId!
      );
      // const selectedProject = queryProjectsAndGangs.data.find((project: any) => project.Selected);
      return [
        {
          name: 'All Gangs',
          path: `/tasks/${projectId}/all/operatives`,
          selected: userId === 'all'
        },

        ...selectedProject?.Gangs.map((gang: any) => ({
          name: gang.Name,
          path: `/tasks/${projectId}/${gang.Id}/tasks`,
          selected: gang.Id === +userId!,
          deactivated: gang.Deactivated
        }))
      ];
    }
    return [
      {
        name: 'NO AVAILABLE PROJECTS',
        path: '#',
        selected: true
      }
    ];
  }, [
    queryProjectsAndGangs.status,
    queryProjectsAndGangs.data,
    projectId,
    userId
  ]);

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments'
            },
            {
              name: 'Project settings',
              path: '/project-settings'
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership'
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: true
            }
          ]
        },
        {
          withSearch: false,
          items: getProjectsBreadcrumb()
        },
        {
          withSearch: true,
          items: getProjectUsersBreadcrumb()
        },
        {
          withSearch: false,
          items: [
            {
              name: 'Tasks',
              path: '#',
              selected: true
            },
            {
              name: 'Operatives',
              path: `/tasks/${projectId}/${userId}/operatives`
            },
            {
              name: 'Ownership',
              path: `/tasks/${projectId}/${userId}/ownership`
            }
          ]
        }
      ],
      [userId, projectId, getProjectsBreadcrumb, getProjectUsersBreadcrumb]
    )
  );
};

const useIncludeProjectTaskInTaskList = (
  projectId: number,
  gangId: number,
  projectTaskRequestModel: ProjectTaskRequestModel
) => {
  const [projectTaskId, setProjectTaskId] = useState<number>();

  const updateIsIncludedInTaskList = useCallback(
    (
      hierarchies: ProjectTaskHierarchyModel[],
      projectTaskId: number,
      isIncludedInTaskList: boolean
    ): ProjectTaskHierarchyModel[] => {
      for (const hierarchy of hierarchies) {
        const projectTask = hierarchy.ProjectTasks?.find(
          (p) => p.Id === projectTaskId
        );

        if (projectTask) {
          projectTask.IsIncludedInTaskList = isIncludedInTaskList;
          break;
        }

        if (hierarchy.ChildHierarchies) {
          updateIsIncludedInTaskList(
            hierarchy.ChildHierarchies,
            projectTaskId,
            isIncludedInTaskList
          );
        }
      }

      return hierarchies;
    },
    []
  );

  const queryClient = useQueryClient();
  const { isLoading, mutate } =
    usePostTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList(
      {
        mutation: {
          onMutate: ({ projectTaskId }) => {
            setProjectTaskId(projectTaskId);
          },
          onSuccess: (
            response,
            { projectId, gangId, includeInTaskList, projectTaskId }
          ) => {
            queryClient.setQueryData(
              [
                'tasks',
                'manage',
                { projectId, gangId, projectTaskRequestModel }
              ],
              (old?: ProjectTaskHierarchyModelArrayEnvelope) => {
                if (!old || !old.Data) {
                  return {};
                }
                const hierarchies = old.Data;

                return {
                  HasErrors: false,
                  Errors: [],
                  Data: updateIsIncludedInTaskList(
                    clone(hierarchies),
                    projectTaskId,
                    includeInTaskList
                  )
                };
              }
            );
            queryClient.invalidateQueries(['tasks', 'manage']);
          },
          onSettled: () => {
            setProjectTaskId(undefined);
          }
        }
      }
    );

  const handleIncludeProjectTaskInTaskList = useCallback(
    (projectTaskId: number, includeInTaskList: boolean) => {
      mutate({
        projectId,
        gangId,
        projectTaskId,
        includeInTaskList
      });
    },
    [gangId, mutate, projectId]
  );

  return {
    handleIncludeProjectTaskInTaskList,
    isSavingProjectTaskId: isLoading ? projectTaskId : undefined
  };
};

export const TasksManage: FC<TasksManageProps> = (props) => {
  const { projectId, userId } = useParams();
  const taskCtx = useContext(TasksContext);
  const settingsCtx = useContext(SettingsContext);
  useDocumentTitle('Tasks | OPCS');
  useSetBreadcrumbs(userId!, projectId!);
  const [requestModel, setRequestModel] = useState<ProjectTaskRequestModel>({
    DateInWeek: getUTCDateISOString(new Date()),
    SearchTerm: ''
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermDebounced] = useDebounce(searchTerm, 600);
  const [taskList, setTaskList] = useState<ProjectTaskHierarchyModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.cancelQueries(['tasks', 'manage']);
    setRequestModel((old) => ({
      ...old,
      SearchTerm: searchTermDebounced
    }));
  }, [queryClient, searchTermDebounced]);

  useEffect(() => {
    const getTlist = async () => {
      setIsLoading(true);

      const x = await postTaskAssignmentTasksSearchProjectTasksProjectIdGangId(
        +projectId!,
        +userId!,
        requestModel
      );
      setIsLoading(false);
      if (!x.HasErrors && x.Data) return x.Data;
      return [];
    };
    getTlist().then((data) => {
      setTaskList(data);
    });
  }, [projectId, userId, requestModel]);

  function pinTask(projectTaskId: number, pinned: boolean) {
    setIsLoading(true);

    const x = updateIsIncludedInTaskListXXXXX(
      taskList.slice(),
      projectTaskId,
      pinned
    );

    setTaskList(x);
    // Need to update DB
    postTaskAssignmentTasksUpdateProjectTaskTaskListInclusionProjectIdGangIdProjectTaskIdIncludeInTaskList(
      +projectId!,
      +userId!,
      projectTaskId,
      pinned
    );
    setIsLoading(false);
  }
  const updateIsIncludedInTaskListXXXXX = useCallback(
    (
      hierarchies: ProjectTaskHierarchyModel[],
      projectTaskId: number,
      isIncludedInTaskList: boolean
    ): ProjectTaskHierarchyModel[] => {
      for (const hierarchy of hierarchies) {
        const projectTask = hierarchy.ProjectTasks?.find(
          (p) => p.Id === projectTaskId
        );

        if (projectTask) {
          projectTask.IsIncludedInTaskList = isIncludedInTaskList;
          break;
        }

        if (hierarchy.ChildHierarchies) {
          updateIsIncludedInTaskListXXXXX(
            hierarchy.ChildHierarchies,
            projectTaskId,
            isIncludedInTaskList
          );
        }
      }

      return hierarchies;
    },
    []
  );

  const { isSavingProjectTaskId } = useIncludeProjectTaskInTaskList(
    +projectId!,
    +userId!,
    requestModel
  );

  const handleSearchQueryChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      setSearchTerm(event.target.value);
    }, []);

  const navigate = useNavigate();
  const handleGoBack = useCallback(() => {
    navigate(`/tasks/${projectId}/${userId}/tasks`);
  }, [projectId, userId, navigate]);

  const assignTask = useCallback(
    (taskId: number) => {
      taskCtx?.updateTaskId(taskId);
      navigate(`/tasks/${projectId}/${userId}/tasks`);
    },
    [projectId, userId, taskCtx, navigate]
  );

  const hasOperativesAssignments = useMemo(() => {
    if (taskCtx?.selectedTasks) {
      return Array.from(taskCtx.selectedTasks).length > 0;
    }
    return false;
  }, [taskCtx]);

  return (
    <section
      className='full-page-panel search margin-bottom-2x'
      data-bind="fullPagePanelVisible: visible, hideSection: '.task-assignment-content'"
    >
      <header className='search-header'>
        <form id='search-term-form'>
          <button
            className='white-with-border cancel float-left'
            onClick={handleGoBack}
          >
            Back
          </button>
          <input
            className='search-textbox large'
            type='search'
            placeholder='Search tasks'
            maxLength={100}
            onChange={handleSearchQueryChange}
          />
        </form>
      </header>
      <div className='project-tasks relative-container block search-task-list'>
        <ProjectTasks
          projectId={+projectId!}
          gangId={+userId!}
          isLoading={isLoading}
          data={taskList}
          forceShowProjectTasks={searchTermDebounced.length > 0}
          forceCollapsProjectTasks={
            settingsCtx?.getSettingsById?.(+projectId!)
              ?.TaskHierarchyCollapsedByDefaultOnTaskSearchPage
          }
          isSavingProjectTaskId={isSavingProjectTaskId}
          isManageMode={true}
          hasSelectedOperatives={hasOperativesAssignments}
          //  handleIncludeProjectTaskInTaskList={hasOperativesAssignments ? assignTask : handleIncludeProjectTaskInTaskList}
          handleIncludeProjectTaskInTaskList={
            hasOperativesAssignments ? assignTask : pinTask
          }
        />
      </div>
    </section>
  );
};
