import { Button } from '../Button';

interface ModalContentProps {
  errorMessage: string;
  onModalClose?: () => void;
}

const ErrorModal = ({
  errorMessage,
  onModalClose = undefined
}: ModalContentProps) => (
  <div className='modalWrapper'>
    <h2 className='modalTitle modalTitleUppercase'>Error</h2>
    <p className='modalParagraph'>{errorMessage}</p>
    {onModalClose ? (
      <Button className='modalCloseButton' onClick={onModalClose}>
        Cancel
      </Button>
    ) : null}
  </div>
);

export default ErrorModal;
