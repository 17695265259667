import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useDocumentTitle } from '@mantine/hooks';
import { Button } from 'core/components';
import {
  getGetProjectAssignmentUserProjectAssignmentGetAssignmentsUserIdQueryKey,
  useGetProjectAssignmentProjectAssignableUsersGetUsers,
  useGetProjectAssignmentUserProjectAssignmentGetAssignmentsUserId,
  usePutProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserId
} from 'api/opcs';
import {
  UserProjectAssignmentCollectionModel,
  UserProjectAssignmentModel,
  UserProjectAssignmentType
} from 'api/model';
import { UserProjectListing } from './components/UserProjectListing';
import { useUpdateBreadcrumbs } from 'core/hooks';

export type UserProjectAssignmentExtendedModel = UserProjectAssignmentModel & {
  Assigned1?: boolean;
};

export type UserProjectAssignmentCollectionExtendedModel = {
  Assignments: UserProjectAssignmentExtendedModel[];
};

const usePutAssignments = (
  userId: number,
  assignments?: UserProjectAssignmentCollectionModel
) => {
  const [newAssignments, setNewAssignments] = useState(
    assignments?.Assignments ?? []
  );

  useEffect(() => {
    if (assignments) {
      setNewAssignments(assignments.Assignments);
    }
  }, [assignments]);

  const handleCheckboxChange = useCallback(
    (assignments: UserProjectAssignmentModel[]) => {
      setNewAssignments(assignments);
    },
    []
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: mutateAssignments, isLoading: isSaving } =
    usePutProjectAssignmentUserProjectAssignmentUpdateAssignmentsUserId({
      mutation: {
        onSettled: () => {
          queryClient.invalidateQueries(
            getGetProjectAssignmentUserProjectAssignmentGetAssignmentsUserIdQueryKey(
              userId
            )
          );
        },
        onSuccess: () => {
          navigate(-1);
        }
      }
    });

  const handleSaveClick = useCallback(() => {
    mutateAssignments({
      userId: userId,
      data: {
        Assignments: newAssignments
      }
    });
  }, [newAssignments, mutateAssignments, userId]);

  return { handleCheckboxChange, handleSaveClick, isSaving };
};

export const UserProjectAssignment: React.FC = () => {
  useDocumentTitle('Project assignments | OPCS');
  const { userId } = useParams();
  const [viewData, setViewData] = useState<
    UserProjectAssignmentExtendedModel[]
  >([]);

  const assignmentsQuery =
    useGetProjectAssignmentUserProjectAssignmentGetAssignmentsUserId(+userId!, {
      query: {
        select: (data) => data.Data!
      }
    });

  const navigate = useNavigate();
  const handleCancelClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const { handleCheckboxChange, handleSaveClick, isSaving } = usePutAssignments(
    +userId!,
    assignmentsQuery.data
  );

  const userQuery = useGetProjectAssignmentProjectAssignableUsersGetUsers({
    query: {
      select: (data) =>
        data.Data?.filter((user) => user.Id === +userId!)[0] ?? {}
    }
  });

  const userName = useMemo(() => {
    return `${userQuery.data?.FullName} (${userQuery.data?.ActiveDirectoryUsername})`;
  }, [userQuery]);

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments',
              selected: true
            },
            {
              name: 'Project settings',
              path: '/project-settings',
              selected: false
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership',
              selected: false
            },
            {
              name: 'Tasks',
              path: '/tasks',
              selected: false
            }
          ]
        },
        {
          withSearch: false,
          items: [
            {
              name: 'Assignment',
              path: '/project-assignments',
              selected: true
            },
            {
              name: 'Promotion',
              path: '/project-assignments/promotion',
              selected: false
            }
          ]
        },
        {
          withSearch: false,
          items: [
            {
              name: userName!,
              path: '#',
              selected: true
            }
          ]
        }
      ],
      [userName]
    )
  );

  const hasBothAssignmentType = useMemo(() => {
    return !!(
      assignmentsQuery?.data?.Assignments.find(
        (item) => item.AssignmentType === 0
      ) &&
      assignmentsQuery?.data?.Assignments.find(
        (item) => item.AssignmentType === 1
      )
    );
  }, [assignmentsQuery]);

  const hasSuperUser = useMemo(() => {
    return !!(
      assignmentsQuery?.data?.Assignments.find(
        (item) => item.AssignmentType === 0
      )
    );
  }, [assignmentsQuery]);

  const handleCheckboxChange2 = useCallback(
    (assignment: UserProjectAssignmentExtendedModel) => {
      setViewData((curr) => {
        const transformed = curr.map((item) => {
          if (item.ProjectId === assignment.ProjectId) {
            return assignment;
          }
          return item;
        });

        const transformedForApi = transformed.map((item) => ({
          UserId: item.UserId,
          ProjectId: item.ProjectId,
          ProjectName: item.ProjectName,
          AssignmentType: item.AssignmentType,
          Assigned: hasBothAssignmentType || hasSuperUser ? item.Assigned : item.Assigned1,
          ProjectFolio: item.ProjectFolio
        }));

        if (!hasBothAssignmentType) {
          handleCheckboxChange(transformedForApi);
        } else {
          handleCheckboxChange([
            ...transformedForApi,
            ...transformed.map((item) => ({
              UserId: item.UserId,
              ProjectId: item.ProjectId,
              ProjectName: item.ProjectName,
              AssignmentType: UserProjectAssignmentType.NUMBER_1,
              Assigned: item.Assigned1,
              ProjectFolio: item.ProjectFolio
            }))
          ]);
        }

        return transformed;
      });
    },
    [hasBothAssignmentType, handleCheckboxChange, setViewData, hasSuperUser]
  );

  useEffect(() => {
    if (!hasBothAssignmentType) {
      setViewData(assignmentsQuery.data?.Assignments.map(
        (assignment: UserProjectAssignmentModel) => {
          return { ...assignment, Assigned1: assignment.Assigned };
        }
      ) || []
     );
      return;
    }
    setViewData(
      assignmentsQuery.data?.Assignments.map(
        (assignment: UserProjectAssignmentModel) => {
          const assigned1Item = assignmentsQuery.data?.Assignments.find(
            (assigned1Item: UserProjectAssignmentModel) =>
              assigned1Item.ProjectId === assignment.ProjectId &&
              assigned1Item.AssignmentType === 1
          );
          if (assigned1Item) {
            return { ...assignment, Assigned1: assigned1Item.Assigned };
          }
          return assignment;
        }
      ).filter((item) => item.AssignmentType === 0) || []
    );
  }, [assignmentsQuery.data, hasBothAssignmentType, setViewData]);

  return (
    <div className='project-assignment-content'>
      <section className='user-project-assignment-content'>
        <h1 className='visually-hidden'>User project assignment</h1>
        <header className='assignment-header'>
          <Button
            colour='white'
            className='action-btn'
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            colour='yellow'
            large
            className='action-btn'
            onClick={handleSaveClick}
          >
            {isSaving ? 'Saving' : 'Save'}
          </Button>
        </header>
        <section>
          <h1 className='visually-hidden'>Projects listing</h1>
          <UserProjectListing
            isLoading={assignmentsQuery.isLoading}
            data={{ Assignments: viewData }}
            onCheckboxChange={(assignment) => handleCheckboxChange2(assignment)}
          />
        </section>
      </section>
    </div>
  );
};
