import { useGetProjectAssignmentProjectSettingsGetProjects } from 'api/opcs';
import { SettingsContext } from 'core/context/SettingsContext';
import { useUpdateBreadcrumbs } from 'core/hooks';
import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectListing } from './components';

interface ProjectSettingsListProps {}

const useGetProjectSettings = () => {
  return useGetProjectAssignmentProjectSettingsGetProjects({
    query: {
      select: (data) => data?.Data ?? []
    }
  });
};

export const ProjectSettingsList: React.FC<ProjectSettingsListProps> = (
  props
) => {
  const navigate = useNavigate();
  const query = useGetProjectSettings();
  const handleRowClicked = (projectId: number) => {
    navigate(`/project-settings/${projectId}`);
  };
  const settingsCtx = useContext(SettingsContext);

  useUpdateBreadcrumbs(
    useMemo(
      () => [
        {
          withSearch: false,
          items: [
            {
              name: 'Project assignments',
              path: '/project-assignments'
            },
            {
              name: 'Project settings',
              path: '/project-settings',
              selected: true
            },
            {
              name: 'Supervisor ownership',
              path: '/supervisor-ownership'
            },
            {
              name: 'Tasks',
              path: '/tasks'
            }
          ]
        }
      ],
      []
    )
  );

  const settings = useMemo(() => {
    return settingsCtx?.settings || [];
  }, [settingsCtx?.settings]);

  return (
    <>
      <div className='page-host'>
        <div className='project-settings'>
          <div className='project-listing-content'>
            <h1 className='visually-hidden'>Project listing</h1>
            <ProjectListing
              projects={query.status === 'success' ? settings : []}
              isLoading={query.status === 'loading'}
              onRowClicked={handleRowClicked}
            />
          </div>
        </div>
      </div>
    </>
  );
};
