import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { msalInstance } from 'index';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

if (!process.env.REACT_APP_AZURE_AD_API_SCOPE) {
  console.warn('REACT_APP_AZURE_AD_API_SCOPE is not set!');
}

export const customInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();

  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    scopes: [process.env.REACT_APP_AZURE_AD_API_SCOPE!],
    account: account
  });

  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
    headers: config.headers
      ? { ...config.headers, Authorization: `Bearer ${response.accessToken}` }
      : { Authorization: `Bearer ${response.accessToken}` }
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    console.log('Query cancelled');
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default customInstance;

export interface ErrorType<Error> extends AxiosError<Error> {}
