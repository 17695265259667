import cx from 'classnames';

type ButtonColour = 'yellow' | 'white';
type ButtonIcon = 'plus' | 'search';

export interface ButtonProps {
  className?: string;
  large?: boolean;
  disabled?: boolean;
  colour?: ButtonColour;
  icon?: ButtonIcon;
  iconOnly?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

export const Button = ({
  className,
  large,
  disabled,
  colour,
  icon,
  iconOnly = false,
  children,
  onClick
}: ButtonProps) => {
  const cls = cx(className, {
    large: large || Boolean(icon),
    disabled,
    yellow: colour === 'yellow',
    'white-with-border': colour === 'white',
    'with-icon': Boolean(icon),
    'with-icon--plus': icon === 'plus',
    'with-icon--search': icon === 'search',
    'disabled-icon-button': iconOnly,
    'event-button disabled-text-button': !iconOnly
  });

  return (
    <button className={cls} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};
