import { useOutsideClick } from '@chakra-ui/hooks';
import { useGetTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonth } from 'api/opcs';
import { getMonth, getYear, isEqual } from 'date-fns';
import { SettingsContext } from 'core/context/SettingsContext';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';

type DatePickerModalProps = {
  date: Date;
  show: boolean;
  onChangeDate: (date: Date) => void;
};

export const DatePickerModal: React.FC<DatePickerModalProps> = (props) => {
  const { date, show, onChangeDate } = props;
  const { projectId, userId } = useParams();
  const ref = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [month, setMonth] = useState(getMonth(date) + 1);
  const [year, setYear] = useState(getYear(date));
  const query =
    useGetTaskAssignmentOperativeDaysGetHoursWorkedByDateProjectIdGangIdYearMonth(
      +projectId!,
      +userId!,
      year,
      month
    );
  const settingsCtx = useContext(SettingsContext);
  const [startDay, setStartDay] = useState<number | undefined>(0);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    onChangeDate(date);
  }, [date, onChangeDate]);

  useOutsideClick({
    ref: ref,
    handler: () => closeModal()
  });

  useEffect(() => {
    setIsModalOpen(show);
  }, [show]);

  const setDayClassName = useCallback(
    (date: Date) => {
      if (isModalOpen && query.data?.Data) {
        for (let i = 0; i < query.data?.Data.length; i++) {
          const elementDate = query.data?.Data[i];
          if (isEqual(date, new Date(elementDate.Date!))) {
            return `date-allocation-percentage-${elementDate.Percentage}`;
          }
        }
      }

      return '';
    },
    [query.data, isModalOpen]
  );

  useEffect(() => {
    const setting = settingsCtx?.getSettingsById?.(+projectId!);
    setStartDay(setting?.WeekCommencesOn);
    // eslint-disable-next-line
  }, [settingsCtx?.getSettingsById, projectId]);

  return (
    <div style={{ position: 'absolute', left: '335px' }}>
      {isModalOpen && (
        <div ref={ref}>
          <DatePicker
            selected={date}
            onChange={onChangeDate}
            inline
            previousMonthButtonLabel=''
            nextMonthButtonLabel=''
            dayClassName={setDayClassName}
            onMonthChange={(date) => {
              setMonth(getMonth(date) + 1);
              setYear(getYear(date));
            }}
            calendarStartDay={startDay}
          />
        </div>
      )}
    </div>
  );
};
