import { ProjectModel } from 'api/model';
import { Field } from 'formik';
import React from 'react';

type CheckboxComponentProps = {
  id: string;
  name: keyof ProjectModel;
  label: string;
  threeColumns?: boolean;
  isDisabled?: boolean;
  indent?: boolean;
};

const CheckboxComponent = ({
  id,
  name,
  label,
  isDisabled = false,
  indent
}: CheckboxComponentProps) => {
  return (
    <div
      className='checkbox-wrapper'
      style={{
        ...(indent && {
          marginTop: '-2px'
        })
      }}
    >
      <div className={`checkbox-box ${indent ? 'checkbox-indent' : ''}`}>
        <Field id={id} name={name} type='checkbox' disabled={isDisabled} />
      </div>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default CheckboxComponent;
